import { Component, OnInit, Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component'
import { pairwise, startWith, map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';
import { Router } from '@angular/router';
const moment = _rollupMoment || _moment;
// datepicker configuration
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },

};

@Component({
  selector: 'app-schedule-online-class',
  templateUrl: './schedule-online-class.component.html',
  styleUrls: ['./schedule-online-class.component.css']
})
export class ScheduleOnlineClassComponent implements OnInit {
  showMainMenu: any;
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  menuNavigation = 'vertical';

  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};

  starttime = '0.00';
  endtime = '23.59';
  endpaymenttime='23.59';
  save_text = "Save";
  allCategoryArray = [{
    'value': 'catgeory1', 'label': "Category1"
  }, {
    'value': 'catgeory2', 'label': "Category2"
  }];
  allLanguageArray = [{
    'value': 'language1', 'label': "Language1"
  }, {
    'value': 'language2', 'label': "Language2"
  }];
  allProductArray = [{
    'value': 'product1', 'label': "Product1"
  }, {
    'value': 'product2', 'label': "Product1"
  }];
  constructor(
    private router: Router, private globalService: GlobalService, private _formBuilder: FormBuilder, public rootService: DataService, private snackBar: MatSnackBar
  ) {
    this.globalService.getObservable().subscribe((data) => {
      // console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }

  ngOnInit(): void {

    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',

        },
        fieldGroup: [
          {
            key: 'category',
            type: 'inno-select',

            templateOptions: {
              placeholder: "Choose Category",
              label: "Choose Category",
              fxFlex: "30%",
              fxLayout: 'column',
              fxFlexOrder: 1,
              options: this.allCategoryArray,
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },

          },
          {
            key: 'language',
            type: 'inno-select',

            templateOptions: {
              placeholder: "Choose Language",
              label: "Choose Language",
              fxFlex: "30%",
              fxLayout: 'column',
              fxFlexOrder: 2,
              options: this.allLanguageArray,
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },


          },
          {
            key: 'product',
            type: 'inno-select',

            templateOptions: {
              placeholder: "Choose Product",
              label: "Choose Product",
              fxFlex: "30%",
              fxLayout: 'column',
              fxFlexOrder: 3,
              options: this.allProductArray,
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },


          },
          {
            key: 'scheduleDate',
            type: 'inno-datepicker',

            templateOptions: {
              placeholder:"Choose Date",
              wrappertext: "Choose Date",
              label: "Choose Date",
              fxFlex: "15%",
              fxLayout: 'column',
              fxFlexOrder: 4,

              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },


          },
          {
            key: 'startTime',
            type: 'inno-timepicker',

            templateOptions: {
              placeholder: "Start Time",
              label: "Start Time",
              fxFlex: "8%",
              fxLayout: 'column',
              fxFlexOrder: 5,
              value: this.starttime,
              options: this.allCategoryArray,
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },


          },
          {
            key: 'endTime',
            type: 'inno-timepicker',

            templateOptions: {
              placeholder: "End Time",
              label: "End Time",
              fxFlex: "8%",
              fxLayout: 'column',
              fxFlexOrder: 6,
              value: this.endtime,

              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },


          },

          {
            key: 'endPaymentTime',
            type: 'inno-timepicker',

            templateOptions: {
              placeholder: "End Payment Time",
              label: "End Payment Time",
              fxFlex: "8%",
              fxLayout: 'column',
              fxFlexOrder: 6,
              value: this.endpaymenttime,

              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },


            },


          }




        ],
      },

    ];

  }
  
  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }

}
