import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../data.service';

export interface PeriodicElement {
  product_id: string;
  product_type: string;
  product_name: string;
  product_url: string;
}

@Component({
  selector: 'view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.css']
})
export class ViewDetailsComponent {
  classdata: PeriodicElement[] = [];
  dataSource: MatTableDataSource<PeriodicElement>;
  displayedColumns: string[] = ['product_id', 'product_type', 'product_name', 'product_url'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dataService: DataService,
    public dialogRef: MatDialogRef<ViewDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataSource = new MatTableDataSource<PeriodicElement>();
  }

  ngOnInit() {
    this.getviewdetails();
  }

getviewdetails() {
  const classId = this.data?.customerData?.Id;
  if (!classId) {
    console.log('Class Id is empty');
    return;
  }
  this.dataService.getClassId(classId).subscribe((response: any) => {
    this.classdata = response?.data || [];
    this.dataSource.data = this.classdata;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0); // Delay to ensure it's set after view update
  }); 
}

  

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onPageChange(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.dataSource.paginator = this.paginator;
  }
}
