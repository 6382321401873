<section class="dashboard-body">
  <div class="container-fluid">
    <div fxLayout="column" fxFlex="100%" class="">
      <div class="top-col">
        <h5 class="head-text color-green">Registration</h5>

      </div>
      <div fxLayout="row" fxFlex="100%" style="background-color: white;">
        <form [formGroup]="form" fxFlex="95%" (ngSubmit)="submit()">
          <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form><br><br>

          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">

            <button class="btn" type="reset" mat-raised-button>Cancel</button>&nbsp;&nbsp;&nbsp;
            <button type="submit" class="btn btn-success submit-button" [disabled]="!form.valid">Submit</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</section>