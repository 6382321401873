<section class="dashboard-body">
  <div class="container-fluid">
    <div fxLayout="column" fxFlex="100%" class="">
      <div class="top-col">
        <h5 class="head-text color-green">{{title}}</h5>
      </div>
      <div>
        <div fxLayout="row" fxFlex="80%">
          <form [formGroup]="form" fxFlex="95%">
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form><br><br>
          </form>
        </div>
      </div>
      <!-- <div>
        <input [(ngModel)]="myEdits" type="text" class="form-control" style="width: 75%; height: 150px;"/>
      </div> -->
      
      <div class="NgxEditor__Wrapper" style="width: 1140px; height: auto;">
        <ngx-editor-menu [editor]="editor"></ngx-editor-menu>
        <ngx-editor [editor]="editor"  [(ngModel)]="myEdits" [disabled]="false" [placeholder]="'Type here...'" ></ngx-editor>
      </div>
      
      
      <div>
        <br>  
        <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px">
          <button class="btn" type="reset" mat-raised-button (click)="cancelClk()">Cancel</button>&nbsp;&nbsp;&nbsp;
          <button type="submit" class="btn btn-success submit-button" (click)="saveClick()" [disabled]="!form.valid">Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>