import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../data.service';
import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  amount: string;
  status: string;
  creationTimeStamp: Date;
  trackId: string;

}
@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  resultsLength = 0;
  pageSize = 20;
 
  exp: any;
  var_readonly: any;
  var_value: any;
  search="";
  dataArray = [];
  dialogData: any = {}
  
 dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
 displayedColumns: string[] = ['trackId','paymentInfo','creationTimeStamp', 'status', 'amount',];

  // constructor(
  //   public dialogRef: MatDialogRef<OrderHistoryComponent>,
  //   //@Optional() is used to prevent error if no data is passed
  //   @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    
  // }

  @ViewChild(MatSort) sort: MatSort;
  constructor(public dataService: DataService,
    public dialogRef: MatDialogRef<OrderHistoryComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.dataService.getProductOrderList(this.dialogData).subscribe((response: any) => {
      
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        console.log(this.resultsLength)
      //   var tempDataArray = response['data'];
      //   for(var i=0;i<tempDataArray.length;i++)
      //   {
         
      //       this.dataArray.push(tempDataArray[i])
          
      //     // console.log(tempDataArray[i]['list']);
      //     // this.dataArray.push(tempDataArray[i]['list']);
      //   }
      // console.log(this.dataArray,'dataArray')
     
        this.bind_values(response['data']);
      }
      else
      {
        this.dataSource = new MatTableDataSource<PeriodicElement>([]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }, (error) => {
      console.log('error is ', error)
    })

  
  }

  bind_values(dataArr)
  {
    this.dataArray=[];
    for(var i=0;i<dataArr.length;i++)
    {
      var datajson = {
        'paymentInfo':dataArr[i].paymentInfo,
        'trackId':dataArr[i].trackId,
        'status':dataArr[i].status,
        'creationTimeStamp':dataArr[i].creationTimeStamp,
        'amount':dataArr[i].fulfilmentAmountTransaction.feeAmount,
      }
      this.dataArray.push(datajson);
    }
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    console.log(event);
    return event;
  }
  closeDialog() {
    this.dialogRef.close(this.form.value);
  }
}
