<section class="dashboard-body" [ngClass]="menuClassDeatails()">
  <div class="container-fluid">
      <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
          <div class="top-col">
              <h5 class="head-text color-green">Classes</h5>
             
          </div>

      <div fxLayout="row" fxFlex="100%" >
        <form fxFlex="65%" [formGroup]="form">
          <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
          </formly-form>
        </form>
        <div class="example-button-row" fxFlex="8%" >
            <button mat-stroked-button  (click)="filterClass()">Apply</button>
        </div>
        <div class="example-button-row" fxFlex="8%" >
          <button mat-stroked-button  (click)="exportTable()">Export</button>
      </div>
        <div class="example-button-row" fxFlex="8%" >
        <button type="submit" (click)="addClass()" submit-button mat-stroked-button class="cls-save-btn" color="success"  >  <i class="fa fa-plus pr-2" ></i>Add</button>
          </div>
      </div>
<div fxLayout="row">
    
    <table  id="classesTable" fxFlex="100%" mat-table [dataSource]="dataSource" matSortDisableClear matSort matSortActive="start_date_time"
   class="mat-elevation-z8" matSortDirection="desc">
      <!-- id Column -->
      <ng-container  matColumnDef="product_id">
        <th mat-header-cell *matHeaderCellDef> Product Id</th>
        <td mat-cell *matCellDef="let element"> {{element.product_id}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <!-- Language Column -->
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef> Language </th>
        <td mat-cell *matCellDef="let element"> {{element.language}} </td>
      </ng-container>
  
      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef> Location </th>
        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
      </ng-container>

   

       
        <ng-container matColumnDef="start_date_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Time</th>
            <td mat-cell *matCellDef="let element"> {{element.start_date_time  | date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="end_date_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> End Time</th>
            <td mat-cell *matCellDef="let element"> {{element.end_date_time | date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="end_payment_time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> End Payment Time</th>
            <td mat-cell *matCellDef="let element"> {{element.end_payment_time | date:'medium'}} </td>
          </ng-container>

            <!-- Actual Amount  Column -->
       <ng-container matColumnDef="actual_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actual Amount</th>
        <td mat-cell *matCellDef="let element"> {{element.actual_amount}} </td>
      </ng-container>

       <!-- Discount Amount  Column -->
       <ng-container matColumnDef="discount_amount">
        <th mat-header-cell *matHeaderCellDef > Discount Amount</th>
        <td mat-cell *matCellDef="let element"> {{element.discount_amount}} </td>
      </ng-container>

       <!-- Payable Amount Column -->
       <ng-container matColumnDef="payable_amount">
        <th mat-header-cell *matHeaderCellDef >Payable Amount</th>
        <td mat-cell *matCellDef="let element"> {{element.payable_amount}} </td>
      </ng-container>

       <!-- Total Participants Column -->
       <ng-container matColumnDef="total_participants">
        <th mat-header-cell *matHeaderCellDef>Total Participants</th>
        <td mat-cell *matCellDef="let element"> {{element.total_participants}} </td>
      </ng-container>

      <ng-container matColumnDef="meeting_id">
        <th mat-header-cell *matHeaderCellDef>Meeting ID</th>
        <td mat-cell *matCellDef="let element"> {{element.meeting_id}} </td>
      </ng-container>

     <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button class="iconbutton" (click)="editClassList(element)" >
            <i class="fa fa-edit" ></i>
          </button>
          <!-- <button mat-icon-button class="iconbutton">
            <i class="fa fa-trash" ></i>
          </button> -->
          <button mat-icon-button class="iconbutton" (click)="viewClass(element)">
            <i class="fa fa-eye"></i>
          </button>
      </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
 
</div>
  <div  fxLayout="row">
    <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,1000]"
    showFirstLastButtons 
    aria-label="Select page of periodic elements">
</mat-paginator>
    <!-- <mat-paginator  fxFlex="100%"  (page)="getPaginatorData($event)" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]" [length]="resultsLength" [pageSize]="pageSize">
    </mat-paginator> -->
</div>
</div>
</div>
</section>
