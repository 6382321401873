import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { Data } from 'src/app/providers/data.provider';
import * as _ from 'lodash';

@Component({
  selector: 'app-login-update',
  templateUrl: './login-update.component.html',
  styleUrls: ['./login-update.component.css']
})
export class LoginUpdateComponent {
  title = 'AlphaMindPower';


  selectedCountry = "+91";

  mobileNumber = "";

  countryList = [];

  submitted = false
  mobileError = "";

  mobileFlag = true;

  loginResponse = {};
  navicData = {}

  constructor(private router: Router, private dataService: DataService, private data: Data) {
    if(data.pageNavicData.length>0){
        this.navicData = _.findLast(data.pageNavicData, {pageName: "Login"});
        this.loginResponse = this.navicData['data'];
    this.dataService.getData(this.dataService.javaUrl + '/api/public/getAllCountries').subscribe((response: any[]) => {

      if (response['code'] == 200) {

        console.log(response['data'].length)
        for (var i = 0; i < response['data'].length; i++) {
          this.countryList.push(response['data'][i]['countryCode'])
        }
      }
    });
  }else{
    this.goPage('login')
  }
  }


  goPage(page) {
    this.router.navigate([page]);
  }

  
  loginSubmit() {

    var _this = this;
    this.submitted = true;

    if (!this.validation()) {
      return false;
    }

    //send otp start
    
    var postObject1 = {};
    postObject1["phoneNumber"] = this.mobileNumber.toString();
    postObject1["countryCode"] = this.selectedCountry.toString().trim();
    postObject1["otpType"] = "3";
    postObject1["mode"] = "mobile";
    postObject1["applicationName"] = "com.innoart.amp";
    var otphashstr = postObject1['phoneNumber'] + "|" + postObject1["otpType"] + "|" + "com.innoart.amp";
    postObject1["hash"] = this.dataService.generateHash(otphashstr);

    this.dataService.postData(this.dataService.javaUrl + '/api/public/sms/sendotp', postObject1).subscribe((response1: any[]) => {
      if (response1['code'] == 200 || response1['code'] == 201) {

        var obj = {
          pageName: "LoginUpdate",
          data:{
            mobileNumber: postObject1['phoneNumber'],
            countryCode :postObject1["countryCode"],
            otpType : "3",
            loginResponse: _this.loginResponse
          }
        }
        this.data.pageNavicData.push(obj);
        this.router.navigate(['otp', { type: "LoginUpdate" }]);
      }

    });
    //start otp
  }

  countrySeleted() {
    console.log(this.selectedCountry);
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }


  mradioClick(val) {
    if (val) {
      this.mobileFlag = true;
    } else {
      this.mobileFlag = false;
    }
  }

  validation() {
    if (this.submitted) {
      this.mobileError = "";

      var mobileValid = true;

        if (this.mobileNumber.toString().length != 10) {

          this.mobileError = "Please enter 10 digit mobile number.";
          mobileValid = false;
        }
      
      return mobileValid;
    }
  }


}
