<div class="card-body">
    <div><span class="repeat-header-txt">Payment History</span></div>'<br>
    <div fxLayout="column"  fxLayoutGap="15px">
        <div fxLayout="row">
            <table fxFlex="100%" mat-table [dataSource]="dataSource" matSort matSortActive="created"
                matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">
<!--     
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container> -->
                <ng-container matColumnDef="remarks">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Product </th>
                    <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
                </ng-container>
                <ng-container matColumnDef="paymentThrough">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Through </th>
                    <td mat-cell *matCellDef="let element"> {{element.paymentThrough}} </td>
                </ng-container>
    
                <ng-container matColumnDef="bankRefNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>  Payment ref  </th>
                    <td mat-cell *matCellDef="let element"> {{element.bankRefNo}} </td>
                </ng-container>
    
                <ng-container matColumnDef="txnStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.txnStatus}} </td>
                </ng-container>
    
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef> Amount</th>
                    <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
                </ng-container>
    
    
    
    
                <ng-container matColumnDef="txnDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.txnDate | date:'medium' }} </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div fxLayout="row"  fxLayoutGap="15px" fxLayout.xs="column" style="justify-content: flex-end;">
            <button type="button" (click)="closeDialog()" mat-stroked-button class="cls-custom-btn" color="danger">Cancel</button><div>  </div>
            <!-- <button type="submit"  mat-stroked-button class="cls-custom-btn" color="success">Apply</button> -->
    
        </div>
        <div fxLayout="row">
            <mat-paginator fxFlex="100%" (page)="getPaginatorData($event)" [length]="resultsLength" [pageSize]="pageSize"
                [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator>
        </div>
    </div>
  
</div>