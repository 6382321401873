import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { Data } from 'src/app/providers/data.provider';
// import { ToastrService } from 'ngx-toastr';
import $ from "jquery";
import * as _ from "lodash";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = 'AlphaMindPower';

  progressFlag = false;

  selectedCountry = "+91";

  mobileNumber = "";
  emailAddress = "";
  password = "";

  countryList = [];

  submitted = false
  mobileError = "";
  emailError = "";
  passwordError = "";

  mobileFlag = true;

  countryFalg = false;
  countryData = [];
  myFlag = false;
  selectedCountryObj: any;
  searchCode: any;
  countryCode = "+91";

  // constructor(private router: Router, private dataService: DataService, private toastr: ToastrService) { 
  constructor(private router: Router, private dataService: DataService, private data: Data) {

    this.dataService.getData(this.dataService.javaUrl + '/api/public/getAllCountries').subscribe((response: any[]) => {

      if (response['code'] == 200) {

        this.countryData = response['data'];
        this.countryList = response['data'];
      }

    });
  }

  codeSearch() {
    var _this = this;
    console.log(this.searchCode.toString().toLowerCase());
    this.countryData = [];
    this.countryData = _.filter(this.countryList, function (item) {
      var retFlag = item['countryCode'].toString().toLowerCase().indexOf(_this.searchCode.toString().toLowerCase()) != -1 || item['countryName'].toString().toLowerCase().indexOf(_this.searchCode.toString().toLowerCase()) != -1;
      return retFlag;
    });
  }
  ngOnInit(): void {
    // $('#dd').removeClass('active');
    localStorage.setItem("LoggedAsCustomer", "0");
  }

  myModel() {

    this.myFlag = false;
    var modal = document.getElementById("myModal1");
    modal.style.display = "block";
  }

  // When the user clicks on <span> (x), close the modal
  myModelClose() {
    this.myFlag = true;
    var modal = document.getElementById("myModal1");
    modal.style.display = "none";
  }

  goPage(page) {
    this.router.navigate([page]);
  }

  selectCountry(item) {
    console.log(item);
    this.selectedCountryObj = item;
    this.countryCode = item.countryCode;
    this.myModelClose();
  }

  onclickCountry() {
    if (this.countryFalg) {
      $('#dd').removeClass('active');
      this.countryFalg = false;
    } else {
      $('#dd').addClass('active');
      this.countryFalg = true;
    }

  }
  loginSubmit() {

    var _this = this;
    this.submitted = true;


    if (!this.validation()) {
      return false;
    }

    this.progressFlag = true;

    var postObject = {};

    postObject['customerPhoneNo'] = this.mobileNumber.toString();

    // postObject['countryCode'] = this.selectedCountry.toString().trim();//test
    postObject['countryCode'] = this.countryCode.toString().trim();//

    postObject['deviceid'] = "web_browser";
    if (this.mobileFlag) {
      postObject['mode'] = "mobile";
      postObject['username'] = postObject['customerPhoneNo'];
      postObject['password'] = postObject['customerPhoneNo'];
    } else {
      postObject['username'] = this.emailAddress;
      postObject['password'] = this.password;
      postObject['mode'] = "web";
    }
    var hashstr = postObject['customerPhoneNo'] + "|" + postObject['deviceid'] + "|" + postObject['mode'];
    var postHash = this.dataService.generateHash(hashstr);
    postObject['applicationName'] = "com.innoart.amp";
    postObject['hash'] = postHash;

    this.dataService.postData(this.dataService.javaUrl + '/api/public/customerLogin', postObject).subscribe((response: any[]) => {
      this.progressFlag = false;
      if (response['code'] == 302) {
        _this.dataService.getData(_this.dataService.nodeUrl + 'user/validUser?id='+_this.mobileNumber.toString()).subscribe((responseUser: any[]) => {
          if(responseUser["data"].length >0){
        var otphashstr = postObject['customerPhoneNo'] + "|" + "4" + "|" + "com.innoart.amp";
        var postObject1 = {};
        postObject1["phoneNumber"] = postObject['customerPhoneNo'];
        postObject1["countryCode"] = this.countryCode.toString().trim();
        postObject1["otpType"] = "4";
        postObject1["mode"] = "mobile";
        postObject1["applicationName"] = "com.innoart.amp";
        postObject1["hash"] = this.dataService.generateHash(otphashstr);

        this.dataService.postData(this.dataService.javaUrl + '/api/public/sms/sendotp', postObject1).subscribe((response1: any[]) => {
          if (response1['code'] == 200) {
            var obj = {
              pageName: "Login",
              data: {
                mobileNumber: postObject['customerPhoneNo'],
                countryCode: postObject1["countryCode"],
                otpType: "4"
              }
            }
            this.data.pageNavicData.push(obj);
            this.router.navigate(['otp', { type: "Login" }]);
          }
          else if (response1['code'] == 201) {
            var obj = {
              pageName: "Login",
              data: {
                mobileNumber: postObject['customerPhoneNo'],
                countryCode: postObject1["countryCode"],
                otpType: "4"
              }
            }
            this.data.pageNavicData.push(obj);
            this.router.navigate(['otp', { type: "Login" }]);
          }

        });
      }else{
        this.mobileError = "Unauthorized Access";
      }
    });

      } else if (response['code'] == 200) {
        if (this.mobileFlag) {
          _this.dataService.getData(_this.dataService.nodeUrl + 'user/validUser?id='+_this.mobileNumber.toString()).subscribe((responseUser: any[]) => {
            if(responseUser["data"].length >0){
          var otphashstr = postObject['customerPhoneNo'] + "|" + "3" + "|" + "com.innoart.amp";
          var postObject1 = {};
          postObject1["phoneNumber"] = postObject['customerPhoneNo'];
          postObject1["countryCode"] = _this.countryCode.toString().trim();
          postObject1["otpType"] = "3";
          postObject1["mode"] = "mobile";
          postObject1["applicationName"] = "com.innoart.amp";
          postObject1["hash"] = _this.dataService.generateHash(otphashstr);

          _this.dataService.postData(_this.dataService.javaUrl + '/api/public/sms/sendotp', postObject1).subscribe((response1: any[]) => {
            if (response1['code'] == 200 || response1['code'] == 201) {
              this.progressFlag = false;
              var obj = {
                pageName: "Login",
                data: {
                  mobileNumber: postObject['customerPhoneNo'],
                  countryCode: postObject1["countryCode"],
                  otpType: "3"
                }
              }
              this.data.pageNavicData.push(obj);
              this.router.navigate(['otp', { type: "Login" }]);
            }
            else if (response1['code'] == 205) {
            }
            else {
            }

          });
        }else{
          this.mobileError = "Unauthorized Access";
        }
      });
          //start otp
        } else {
          var obj = {
            pageName: "Login",
            data: response['data']
          }
          this.data.pageNavicData.push(obj);
          this.router.navigate(['login-update']);
        }
      } else if (response['code'] == 202) {
        if (this.mobileFlag) {
          this.mobileError = "This mobile number is not registered.";
        } else {

          var mobStr = "";
          var usrArry = response['data']['username'].split('');
          for (var k = 0; k < usrArry.length; k++) {
            if (usrArry.length - 4 >= k) {
              mobStr = mobStr + "*";
            } else {
              mobStr = mobStr + usrArry[k];
            }
          }

          this.emailError = "This email id is associated with this mobile number " + response['data']['countryCode'] + " " + mobStr + ". Use this mobile number to login.";
        }

      } else if (response['code'] == 204) {
        if (this.mobileFlag) {
          this.mobileError = "This mobile number is not registered.";
        } else {
          this.emailError = "Incorrect e-mail address or password";
        }

      } else {
      }
    });

  }

  countrySeleted() {
    console.log(this.selectedCountry);
  }

  emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }


  mradioClick(val) {
    if (val) {
      this.mobileFlag = true;
    } else {
      this.mobileFlag = false;
    }
  }

  validation() {
    if (this.submitted) {
      this.emailError = "";
      this.mobileError = "";
      this.passwordError = "";

      var mobileValid = true;
      var emailValid = true;
      var passworValid = true;

      if (this.mobileFlag) {
        // if(this.selectedCountry.toString().trim() == "+91"){//test
        if (this.countryCode.toString().trim() == "+91") {
          if (this.mobileNumber.toString().length != 10) {
            this.mobileError = "Please enter 10 digit mobile number.";
            mobileValid = false;
          }
        } else {
          if (this.mobileNumber.toString().length < 5) {
            this.mobileError = "Please enter valid mobile number.";
            mobileValid = false;
          }
        }
      } else {
        if (this.emailAddress.length == undefined || this.emailAddress.length < 5) {
          this.emailError = "Please enter your e-mail address.";
          emailValid = false;
        } else if (!this.emailIsValid(this.emailAddress)) {
          this.emailError = "Invalid e-mail address !";
          emailValid = false;
        }

        if (this.password.length == undefined || this.password.length < 1) {
          this.passwordError = "Please enter your password.";
          passworValid = false;
        }

      }
      return mobileValid && emailValid && passworValid;
    }
  }


}
