import { Component, ElementRef, ViewChild,AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { Data } from 'src/app/providers/data.provider';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OTPComponent implements AfterViewInit {
  title = 'AlphaMindPower';
  progressFlag = false;
  submitted = false;

  mobileNumber : string;
  countryCode : string;
  @ViewChild("codeBox1") _el1: ElementRef;
  @ViewChild("codeBox2") _el2: ElementRef;
  @ViewChild("codeBox3") _el3: ElementRef;
  @ViewChild("codeBox4") _el4: ElementRef;
  @ViewChild("codeBox5") _el5: ElementRef;
  @ViewChild("codeBox6") _el6: ElementRef;

  otp1 :any = "";
  otp2 :any = "";
  otp3 :any = "";
  otp4 :any = "";
  otp5 :any = "";
  otp6 :any = "";

  otpFlag = false;

  navicData  = {};
  otpPageType = "";
  loginResponse = {};
  registerData = {};
  otpType = "";
  resendTime = "";
  resendFlag = false;

  k = 60;

  constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private data: Data, private cdRef: ChangeDetectorRef){

    var _this= this;
    if(data.pageNavicData.length>0){
      _this.myTime();
    this.route.params.subscribe(params => {
			console.log(params);
			if (params['type']) {
        _this.otpPageType = params['type'];//Login/LoginUpdate/Registration/ProfileUpdate
        if(_this.otpPageType == 'Login'){
        _this.navicData = _.findLast(data.pageNavicData, {pageName: "Login"});
        _this.mobileNumber = _this.navicData['data']['mobileNumber'];
        _this.countryCode = _this.navicData['data']['countryCode'];
        _this.otpType = _this.navicData['data']['otpType'];
        }else if(_this.otpPageType == 'LoginUpdate'){
        _this.navicData = _.findLast(data.pageNavicData, {pageName: "LoginUpdate"});
        _this.mobileNumber = _this.navicData['data']['mobileNumber'];
        _this.countryCode = _this.navicData['data']['countryCode'];
        _this.otpType = _this.navicData['data']['otpType'];
        _this.loginResponse = _this.navicData['data']['loginResponse'];

        }else if(_this.otpPageType == 'Registration'){
          
          _this.navicData = _.findLast(data.pageNavicData, {pageName: "Registration"});
        _this.mobileNumber = _this.navicData['data']['mobileNumber'];
        _this.countryCode = _this.navicData['data']['countryCode'];
        _this.otpType = _this.navicData['data']['otpType'];
        _this.registerData = _this.navicData['data']['registerData'];

        }else if(_this.otpPageType == 'ProfileUpdate'){
          _this.navicData = _.findLast(data.pageNavicData, {pageName: "ProfileUpdate"});
        _this.mobileNumber = _this.navicData['data']['mobileNumber'];
        _this.countryCode = _this.navicData['data']['countryCode'];
        _this.otpType = _this.navicData['data']['otpType'];

        }
			}
    });
  }else{
    this.goPage('login');
  }
    // this.route.params.subscribe(params => {
		// 	console.log(params);
		// 	if (params['mobile']) {
    //     this.mobileNumber = params['mobile'];
		// 	}
    // });
    
  }

  
  myTime(){
    var _this = this;
            setTimeout(function() {   //  call a 3s setTimeout when the loop is called
              console.log('hello');   //  your code here
                             //  increment the counter
              _this.resendTime ="Resend SMS in "+ _this.k + " seconds.";
              _this.cdRef.detectChanges();
              _this.k--;     
              if(_this.k== 0)
              {
                _this.resendTime  = "";
                _this.resendFlag = true;
                
              }
              if (_this.k > 0) {           //  if the counter < 10, call the loop function
                _this.myTime();             //  ..  again which will trigger another 
              }                       //  ..  setTimeout()
              
            }, 1000);
  }

  ngAfterViewInit() {
    this._el1.nativeElement.focus();
  }

  modelChanged(event,value){

    this.submitted = false;
       
    if(event == 1){
      if(value ==null){
      }else
      if(value.toString().length > 0){
        this._el2.nativeElement.focus();
      }

    }else if(event == 2){
      if(value ==null){
        this._el1.nativeElement.focus();
      }else
      if(value.toString().length > 0){
        this._el3.nativeElement.focus();
      }
    }else if(event == 3){
      if(value ==null){
        this._el2.nativeElement.focus();
      }else
      if(value.toString().length > 0){
        this._el4.nativeElement.focus();
      }
    }else if(event == 4){
      if(value ==null){
        this._el3.nativeElement.focus();
      }else
      if(value.toString().length > 0){
        this._el5.nativeElement.focus();
      }
    }else if(event == 5){
      if(value ==null){
        this._el4.nativeElement.focus();
      }else
      if(value.toString().length > 0){
        this._el6.nativeElement.focus();
      }
    }else if(event == 6){
      if(value ==null){
        this._el5.nativeElement.focus();
      }else
      if(value.toString().length > 0){
        this.otpSubmit();
      }
    }
  
  }


  resendOTP(){
    this.resendFlag = false;
    var otphashstr = this.mobileNumber+ "|" + this.otpType + "|" + "com.innoart.amp";
    var postObject = {};
    postObject["phoneNumber"] =  this.mobileNumber;
    postObject["countryCode"] = this.countryCode;
    postObject["otpType"] = this.otpType;
    postObject["mode"] = "mobile";
    postObject["applicationName"] = "com.innoart.amp";
    postObject["hash"] = this.dataService.generateHash(otphashstr);


  this.dataService.postData(this.dataService.javaUrl+ '/api/public/sms/sendotp',postObject).subscribe((response: any[]) => {
  console.log("data2");
  console.log(response);
  
  
});
  }

  otpSubmit(){
    var _this= this;
    if(!this.submitted){
      this.submitted = true;
      if(!this.validation()){
        return false;
      }

      this.progressFlag = true;
    var postObject = {};
                postObject["phoneNumber"] = this.mobileNumber; 
                postObject["countryCode"] = this.countryCode;
                postObject["otpType"] = this.otpType;
                postObject["mode"] =  "mobile";
                postObject["otp"] = this.otp1+""+this.otp2+""+this.otp3+""+this.otp4+""+this.otp5+""+this.otp6+"";
                postObject["applicationName"] = "com.innoart.amp";
                postObject["deviceid"] = "web_browser";
this.dataService.postData(this.dataService.javaUrl+ '/api/public/sms/verifyotp',postObject).subscribe((response: any[]) => {
  if (response['code'] == 200)
  {
    this.progressFlag = false;
    if(this.otpPageType == 'LoginUpdate'){
      _this.updateMobileNumber();
    }else if(this.otpPageType == 'Registration'){
_this.customerRegister();
    
    }else{
_this.customerLogin();
  }
  }
  else
  {
    this.progressFlag = false;
    this.otpFlag = true;
  }
    });
  }
  }
  validation(){
    var otp = this.otp1+""+this.otp2+""+this.otp3+""+this.otp4+""+this.otp5+""+this.otp6+"";
    this.otpFlag = false;
    if(otp.length==6){
      this.otpFlag = false;
    }else{
      this.otpFlag = true;
    }

   
     return !this.otpFlag;
  }

  customerLogin(){
var _this = this;
    var hashstr = this.mobileNumber + "|" + "web_browser" + "|" + "mobile";
      var postHash = this.dataService.generateHash(hashstr);
      var postObject1 = {
        customerPhoneNo : this.mobileNumber,
        countryCode : this.countryCode,
        deviceid : "web_browser",
        mode : "mobile",
        applicationName : "com.innoart.amp",
        hash : postHash
      }

      _this.dataService.postData(_this.dataService.javaUrl+ '/api/public/customerLogin',postObject1).subscribe((response0: any[]) => {
      console.log("data1");
      console.log(response0);
     if(response0['code']==200){

        console.log(response0['data']['customerName'] != null ? response0['data']['customerName'] : "");
        localStorage.setItem("LoggedInUserName", response0['data']['customerName'] != null ? response0['data']['customerName'] : "");
        localStorage.setItem("LastName", response0['data']['lastName'] != null ? response0['data']['lastName'] : "");
        localStorage.setItem("LoggedInUserId", response0['data']['id'] != null ? response0['data']['id'] : "");
        localStorage.setItem("LoggedInPhotoUrl", response0['data']['photo'] != null ? response0['data']['photo'] : "noimage");
        localStorage.setItem("LoggedInMobileNumber", response0['data']['contactMobile'] != null ? response0['data']['contactMobile'] : "");
        localStorage.setItem("CountryCode", response0['data']['countryCode'] != null ? response0['data']['countryCode'] : "");
        localStorage.setItem("MerchantKey", response0['data']['merchantKey'] != null ? response0['data']['merchantKey'] : "ALPHA");
        localStorage.setItem("LoggedInEmail", response0['data']['email'] != null ? response0['data']['email'] : "");
        localStorage.setItem("Add1", response0['data']['add1'] != null ? response0['data']['add1'] : "");
        localStorage.setItem("Add2", response0['data']['add2'] != null ? response0['data']['add2'] : "");
        localStorage.setItem("Area", response0['data']['area'] != null ? response0['data']['area'] : "");
        localStorage.setItem("City", response0['data']['city'] != null ? response0['data']['city'] : "");
        localStorage.setItem("State", response0['data']['state'] != null ? response0['data']['state'] : "");
        localStorage.setItem("Country", response0['data']['country'] != null ? response0['data']['country'] : "");
        localStorage.setItem("PincodeMasterId", "");
        localStorage.setItem("Zipcode", response0['data']['pincode'] != null ? response0['data']['pincode'] : "");
        localStorage.setItem("LoggedAsCustomer", "1");
        localStorage.setItem("LoggedInStatus", "200");
        
        _this.goPage('list-classes');
      }else{
        _this.goPage('login');
      }
    });
    
  }


  customerRegister(){
    this.dataService.postData(this.dataService.javaUrl+ '/api/public/customerRegistration',this.registerData).subscribe((response: any[]) => {
    if(response['code'] == 201 || response['code'] == 200){
      this.customerLogin();
      }
    });
  }

  updateMobileNumber(){
    var _this =this;
    // "webUserId":8300,

    // "countryCode":"+91",

    // "mobileno":"9944914402",

    // "applicationName":"com.innoart.amp",

    // "merchantKey":"ALPHA",
    
    var postObject1 = {};
    postObject1['webUserId'] = parseInt(this.loginResponse['id']);
    postObject1['countryCode'] =  this.countryCode;
    postObject1['mobileno'] =  this.mobileNumber;
    postObject1['applicationName'] =  "com.innoart.amp";
    postObject1['merchantKey'] =  this.dataService.MerchantKey;
    var hashstr = postObject1['webUserId'] + "|" + postObject1['countryCode'] + "|" + postObject1['mobileno'] + "|" + postObject1['applicationName'] + "|" + postObject1['merchantKey'];
    var postHash = this.dataService.generateHash(hashstr);
    postObject1['hashValue'] =  postHash;

    this.dataService.postData(this.dataService.javaUrl+ '/api/public/checkWebuserMobileLogin',postObject1).subscribe((response0: any[]) => {
      if(response0['code']==200){
      localStorage.setItem("LoggedInUserName", response0['data']['customerName'] != null ? response0['data']['customerName'] : "");
        localStorage.setItem("LastName", response0['data']['lastName'] != null ? response0['data']['lastName'] : "");
        localStorage.setItem("LoggedInUserId", response0['data']['id'] != null ? response0['data']['id'] : "");
        localStorage.setItem("LoggedInPhotoUrl", response0['data']['photo'] != null ? response0['data']['photo'] : "noimage");
        localStorage.setItem("LoggedInMobileNumber", response0['data']['contactMobile'] != null ? response0['data']['contactMobile'] : "");
        localStorage.setItem("CountryCode", response0['data']['countryCode'] != null ? response0['data']['countryCode'] : "");
        localStorage.setItem("MerchantKey", response0['data']['merchantKey'] != null ? response0['data']['merchantKey'] : "ALPHA");
        localStorage.setItem("LoggedInEmail", response0['data']['email'] != null ? response0['data']['email'] : "");
        localStorage.setItem("Add1", response0['data']['add1'] != null ? response0['data']['add1'] : "");
        localStorage.setItem("Add2", response0['data']['add2'] != null ? response0['data']['add2'] : "");
        localStorage.setItem("Area", response0['data']['area'] != null ? response0['data']['area'] : "");
        localStorage.setItem("City", response0['data']['city'] != null ? response0['data']['city'] : "");
        localStorage.setItem("State", response0['data']['state'] != null ? response0['data']['state'] : "");
        localStorage.setItem("Country", response0['data']['country'] != null ? response0['data']['country'] : "");
        localStorage.setItem("PincodeMasterId", "");
        localStorage.setItem("Zipcode", response0['data']['pincode'] != null ? response0['data']['pincode'] : "");
        localStorage.setItem("LoggedAsCustomer", "1");
        localStorage.setItem("LoggedInStatus", "200");
        _this.goPage('list-classes');
      }else{
        _this.goPage('login');
      }
    });
  }
  goPage(page){
    this.router.navigate([page]);
    }

}
