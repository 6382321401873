<div class="card-body">
    <div><span class="repeat-header-txt">Schedule Rule Activity</span></div> 
    <div fxLayoutGap="5px">
        <form [formGroup]="form" (ngSubmit)="submit()">
            <formly-form [form]="form" [model]="model" [fields]="formFields">
                <div fxLayout="row"  fxLayoutGap="20%" fxLayout.xs="column" style="justify-content: flex-end;">
                    <button type="button" (click)="closeDialog()" mat-stroked-button class="cls-custom-btn" color="danger">Cancel</button>
                    <button type="submit"  mat-stroked-button class="cls-custom-btn" color="success">Apply</button>

                </div>
              
            </formly-form>
        </form>
    </div>
</div>

