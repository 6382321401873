<div class="card-body" >

  <div fxLayoutGap="15px" fxFlex="100%" fxLayout="column">
    <div><span class="repeat-header-txt">  Enable Meditation</span></div>
    <br>
   <div fxLayoutGap="15px" fxFlex="100%">
      <form [formGroup]="form" style="padding-left: 20px">
        <formly-form [form]="form" [model]="model" [fields]="formFields">


        </formly-form>
      </form>
    </div>
    <div fxLayout="row" fxFlex="100%" >

      <table fxFlex="100%" mat-table *ngIf="dataSource.data.length > 0" [dataSource]="dataSource" matSort matSortActive="created" matSortDisableClear
        matSortDirection="desc" (matSortChange)="resetPaging()" class="mat-elevation-z8">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
            
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- id Column -->
        <ng-container matColumnDef="product_id">
          <th mat-header-cell *matHeaderCellDef> Product Id</th>
          <td mat-cell *matCellDef="let element"> {{element.product_id}} </td>
        </ng-container>
        <!-- Type Column -->
        <ng-container matColumnDef="product_type">
          <th mat-header-cell *matHeaderCellDef> Product Type </th>
          <td mat-cell *matCellDef="let element"> {{element.product_type}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Url Column -->
        <ng-container matColumnDef="product_url">
          <th mat-header-cell *matHeaderCellDef> Product Url </th>
          <td mat-cell *matCellDef="let element"> {{element.product_url}} </td>
        </ng-container>




        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>


    </div>
    <div fxLayout="row" fxFlex="100%" *ngIf="dataSource.data.length > 0" >
     
      <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100,1000]" showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>
      <!-- <mat-paginator  fxFlex="100%"  (page)="getPaginatorData($event)" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]" [length]="resultsLength" [pageSize]="pageSize">
        </mat-paginator> -->
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" style="justify-content: flex-end;">
      <button type="button" (click)="enableMeditation()" mat-stroked-button class="cls-custom-btn"
        color="success">Enable</button>
      <button type="button" (click)="closeDialog()" mat-stroked-button class="cls-custom-btn"
        color="danger">Cancel</button><div>  </div>
    </div>
  </div>
</div>