import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-wrapper',
  template: `
  
  <div  class="">
  <ng-container #fieldComponent></ng-container>
  </div>

  `,
})

export class FormlyFieldCustomWrapper extends FieldWrapper {
  
}