import { Component, OnInit , ViewChild ,Inject,Optional} from '@angular/core';
import { DataService } from '../data.service';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import {  MatDialog } from '@angular/material/dialog';
// material component
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-guest-users',
  templateUrl: './guest-users.component.html',
  styleUrls: ['./guest-users.component.css']
})
export class GuestUsersComponent implements OnInit {
  resultsLength = 0;
  pageSize = 10;
  subscribed_user_array = [];
  dataSource = new MatTableDataSource([]);
  DisplayColumns: string[] = ['user_name','email_id','meeting_id','meeting_status','user_mobile','user_type','action',];
  columns=[{
    "label":'Name',
    "value":'user_name'
  },{
    "label":'Email ID',
    "value":'email_id'
  },{
    "label":'Meeting ID',
    "value":'meeting_id'
  },{
    "label":'Meeting Status',
    "value":'meeting_status'
  },{
    "label":'Mobile No',
    "value":'user_mobile'
  },
  {
    "label":'User Type',
    "value":'user_type'
  },
  {
    "label":'Action',
    "value":'action'
  }]
  displayedHeaderColumns: string[] = ['Name','Email ID','Mobile No','Meeting ID','Meeting Status'];

  childResultsLength = 0;
  childPageSize = 10;

  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  totalRows = 0;
  dialogData:any;
  form = new FormGroup({});
  fieldgroup = [];
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public dialogRef: MatDialogRef<GuestUsersComponent>,

    public rootService: DataService, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dialogData = data;
    }
  ngOnInit(): void {
    this.bind_formly_elements();
    this.bind_subscribed_users();
  }
  bind_formly_elements() {
    var form_elements = [
      {

        key: 'user_type',
        type: 'inno-select',
        label: 'User Type',
        childkey: '',
        hideExpression: '',
        required: false,
        hide: false,
        value: "",
        // fxFlex: 30,
        fxFlexOrder: 1,
        options:[
          {
            label:"All",value:"0"
          },{
          label:"Guest",value:"guest"
        },
        {
          label:"Paid",value:"paid"
        },{
          label:"Moderator",value:"moderator"
        }]
      },

      

    ]


    for (var i = 0; i < form_elements.length; i++) {

      var field_json =
      {
        key: form_elements[i].key,
        type: form_elements[i].type,

        class: "element-width",
        // defaultValue:form_elements[i].defaultValue,
        templateOptions: {
          label: form_elements[i].label,
          placeholder: form_elements[i].label,
          description: form_elements[i].label,
          type: form_elements[i].childkey,
          fxFlexOrder: form_elements[i].fxFlexOrder,

          // fxFlex: form_elements[i].fxFlex,
          required: form_elements[i].required,
          options: form_elements[i].options,

          valueChange: (field, value) => {
            console.log(value, field, 'valuechanges')

            //  this.bind_values(field)
          },

        },


      };

      this.fieldgroup.push(field_json);
    }
 
    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',

        },
        fieldGroup: this.fieldgroup
      }]
    console.log(this.fieldgroup, 'fieldgroup')

  }
  apply()
  {
    var filteredArray = [];
    if(this.model.user_type != "0" && this.model.user_type !=undefined)
      filteredArray = this.subscribed_user_array.filter(e=>(e.user_type == this.model.user_type) || (!e.user_type && this.model.user_type.toLowerCase()=="paid"));
    else
      filteredArray = this.subscribed_user_array;
    this.dataSource = new MatTableDataSource(filteredArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  closeDialog() {
    this.dialogRef.close(this.form.value);
  }
  bind_subscribed_users()
  {
    var postObject2 = {};
    // alert(this.dialogData['class_id'])
    postObject2["classId"] = this.dialogData['class_id'];
    this.rootService.getSubscribedUsers(postObject2).subscribe((response: any) => {
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        this.subscribed_user_array = response['data'];
        this.dataSource = new MatTableDataSource(this.subscribed_user_array);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    
      


    }, (error) => {
      console.log('error is ', error)
    })

    
  }

  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  public getPaginatorData(event: PageEvent): PageEvent {

    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
     console.log(event);
    return event;
  }

}
