import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  private fooSubject = new Subject<any>();

  private allUsers$ = new Subject<any>();

  publishSomeData(data: any): void {
    this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }


  publishAllUsers(data: any): void {
    console.log(data)
    this.allUsers$.next(data);
  }

  getAllUsersObservable(): Observable<any> {
    return this.allUsers$.asObservable();
  }

}
