<div class="container-fluid">
    <div fxLayout="column" fxFlex="100%" class="">
        <div class="top-col">
            <h5 class="head-text color-green">Customer Number</h5>
        </div>
        <form [formGroup]="searchForm" (ngSubmit)="fetchOtp()">
            <div fxLayout="row" fxFlex="100%">
                <div fxLayout="row">
                   
                      
                    <mat-form-field class="country-code-input">
                        <mat-label>Country Code</mat-label>
                        <mat-select formControlName="countryCode" (selectionChange)="selectCountry($event)">
                          <mat-option>
                            <input matInput placeholder="Search" (input)="applyFilter($event.target.value)" (click)="$event.stopPropagation()">
                          </mat-option>
                          <mat-option *ngFor="let country of filteredCountries" [value]="country.countryCode">
                            <img width="25" [src]="country.countryImageUrl">
                            {{ country.countryCode }} - {{ country.countryName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      
                      
                      
                  
                    <div fxLayout="row" class="phone-number-input">
                        <mat-form-field>
                            <input matInput placeholder="Phone Number" formControlName="phoneNumber">
                        </mat-form-field>
                    </div>
                    <div class="search-button">
                        <button mat-raised-button color="primary" type="submit">Get OTP</button>
                    </div>
                </div>
            </div>
        </form>
       
        <div *ngIf="otp" style="display: flex;">
            <p class="otp">OTP: {{ otp }}</p>
            <p class="timestamp" >Expiry Time: {{ formattedDate }} {{ formattedTime }}</p>
          </div>
          
    </div>
</div>
