import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatSort } from '@angular/material/sort';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../global.service';

import {MatTableDataSource} from '@angular/material/table';
import { EnableMeditationComponent } from '../enable-meditation/enable-meditation.component';
import { TableUtil } from '../tableutil';
import { Router } from '@angular/router';

export interface PeriodicElement {
  fulfilment_header_transaction_id:string
  category: string;
  cust_name_pt_txn: string;
  cust_phone_pt_txn: string;
  cust_email_pt_txn: string;
  // date: string;
  payment_info: string;
  txn_date_pt_txn: Date;
  payment_through_pt_txn: string;
  amount_pt_txn: string;
  bank_ref_num_pt_txn: string;
  txn_status_pt_txn:string
}
@Component({
  selector: 'app-list-payment',
  templateUrl: './list-payment.component.html',
  styleUrls: ['./list-payment.component.css']
})
export class ListPaymentComponent implements OnInit {
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
 
    showMainMenu: any;
    colorMode = 'dark';
    currentColor = 'bgcolor-black';
    menuNavigation = 'vertical';
    //  dataSource = [];
    search = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Output() editdeleterow = new EventEmitter<any>();
    resultsLength = 0;
    pageSize = 10;
    data: Observable<any[]>;
    dataArray= [];
    productDetails= [];
    productValues= [];
    datepipe = new DatePipe('en-US');
    dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
  displayedColumns: string[] = [ 'category', 'cust_name_pt_txn','cust_phone_pt_txn','cust_email_pt_txn','payment_info','txn_date_pt_txn','payment_through_pt_txn','amount_pt_txn','txn_status_pt_txn','action'];


  classesObj = {};
  allCategoryArray = [
    { value: '0', label: 'All' },
    { value: 'Classes', label: 'Classes' },
    { value: 'Online Class', label: 'Online Class' },
    { value: 'Meditation', label: 'Meditation' },
    { value: 'E-Store', label: 'E-Store' },
  
  ];
  allPaymentArray = [
    { value: '0', label: 'All' },{
    'value': 'FAILURE', 'label': "FAILURE"
  }, {
    'value': 'SUCCESS', 'label': "SUCCESS"
  },
  {
    'value': 'INITIATED', 'label': "INITIATED"
  }];
  allProductArray = [{
    'value': 'product1', 'label': "Product1"
  }, {
    'value': 'product2', 'label': "Product1"
  }];

 
  constructor(
    private dialog: MatDialog, private router: Router, private globalService: GlobalService,  public dataService: DataService, 
  ) {
    this.globalService.getObservable().subscribe((data) => {
      // console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',
        },
        fieldGroup: [
        {
          key: 'category',
          type: 'inno-select',
          templateOptions: {
            label: "Category",
            fxFlex: "22%",
            fxLayout: 'column',
            fxFlexOrder: 1,
            options:this.allCategoryArray,
            valueChange: (field, value) => {
              console.log(value, field, 'valuechanges')
  
              //  this.bind_values(field)
            },
          },

        },
        {
          key: 'product',
          type: 'inno-select',
          templateOptions: {
            label: "Products",
            fxFlex: "22%",
            fxLayout: 'column',
            fxFlexOrder: 2,
            options:this.productValues,
            valueChange: (field, value) => {
              console.log(value, field, 'valuechanges')
  
              //  this.bind_values(field)
            },
          },
          hooks: {


            onInit: (field) => {
  
    
         
                // field.templateOptions.options = [];
                field.form
                  .get('category')
                  .valueChanges.pipe(
                    // startWith(catValues),
                    map(category => this.bindClasses(category,'category')), tap(() => {
                      field.formControl.setValue([])
                    }),
  
                  ).subscribe(res => {
  
                    console.log(res,'res');
                    field.templateOptions.options = this.productValues;
                  });
  
            
              
  
  
         
            
           
  
  
  
            }
  
          }
        },
        {
          key: 'paymentStatus',
          type: 'inno-select',
          templateOptions: {
            label: "Payment Status",
            fxFlex: "22%",
            fxLayout: 'column',
            fxFlexOrder: 3,
            options:this.allPaymentArray,
            valueChange: (field, value) => {
              console.log(value, field, 'valuechanges')
  
              //  this.bind_values(field)
            },
          },
     
      },
      {
        key: 'payment_list_date',
        type: 'inno-daterangepicker',
        templateOptions: {
          label: "Date Picker",
          fxFlex: "22%",
          placeholder: "Date Picker",
          fxLayout: 'column',
          fxFlexOrder: 4,
        },
      },
    ],
  },
    ]
    this.bindPayments('','','','');
  }
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }
  // edit  list
  editRuleList() {}
  // Delete List
  confirmdelete() {
  
  }
  exportTable() {
    TableUtil.exportTableToExcel("paymentTable","PaymentList",["J1"]);
  }

  generateHash(hash_value)
  {
  var hashedValue =   this.sha512(hash_value).then(x =>{
    return x;
   });
   return hashedValue;
    // crypto.createHash('sha512').update('my string for hashing').digest('hex');
    // return crypto.subtle.digest("SHA-512", new TextEncoder().encode(hash_value)).then(buf => {
    //   return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    // });
  }
   sha512(str) {
    return crypto.subtle.digest("SHA-512", new TextEncoder().encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    });
  }
  async bindClasses(category,changetype)
  {
    this.productValues = [];
    var lan = '';
    var cat = '';
    if(changetype == 'language')
    {
      lan = category;
    }
    else{
      lan = this.model.language;
    }
    if(changetype == 'category')
    {
      cat = category;
    }
    else{
      cat = this.model.category;
    }
    var postObject1 = {};
    postObject1["category"] = cat;
    postObject1["type"] = 'master';
     postObject1["hash_value"] = await this.generateHash("ALPHA"+"|"+postObject1["category"]+"|"+postObject1["type"]);
  
    // postObject1["hash_value"] = '9d08b287ceed3b48133342aaec4dec97668be08e05855a3b708210a2e2f6fc2a0301ae2c9a42ca0e99822a7b1279559ee9aa68962e818247d090a17a9145a656'
    var productArray = [];
  
     this.dataService.getClasses(postObject1).subscribe((response: any) => {
     
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        var tempDataArray = response['data'][0]['list'];
        this.productValues.push( { value: '0', label: 'All' });
        for(var i=0;i<tempDataArray.length;i++)
        {
          var language_wise_classes = tempDataArray[i]['list'];
          for(var j=0;j<language_wise_classes.length;j++)
          {
           
          
            this.productDetails.push(language_wise_classes[j]);
            var productJson = {label:language_wise_classes[j].name,value:language_wise_classes[j].name};
            this.productValues.push(productJson)
            

                
          }
          // console.log(tempDataArray[i]['list']);
          // this.dataArray.push(tempDataArray[i]['list']);
        }
    
      //  this.bind_values(this.dataArray);
      }
     
    }, (error) => {
      console.log('error is ', error)
    })
  
   
  }

  filterPayment()
  {
    console.log(this.model.payment_list_date);
    
    this.bindPayments(this.model.category,this.model.paymentStatus,this.model.product,
      this.model.payment_list_date)
  
   
   
  }
  async bindPayments(category,status,product,date1)
  {
    //  category='Classes'
    //  status="SUCCESS"
    //  product='AISHWARYAM'
    //  date1='2020-08-10'
    //  var date2=''
    // {category: 'Classes', status: 'SUCCESS', product: 'AISHWARYAM', date1: '2021-08-08', date2: '2021-08-08'}
    this.dataArray = [];
    var postObject1 = {};
    if(category == "" || category == undefined ||  category == null || category == '0')
    {
      postObject1["category"] ='';
    }
    else{
      postObject1["category"] =category;
    }
    if(status == "" || status == undefined ||  status == null)
    {
      postObject1["status"] ='';
    }
    else{
      postObject1["status"] =status;
    }
    if(product == "" || product == undefined ||  product == null)
    {
      postObject1["product"] ='';
    }
    else{
      postObject1["product"] =product;
    }
    if(date1 == "" || date1 == undefined ||  date1 == null)
    {
      postObject1["date1"] ='';
      postObject1["date2"] ='';
    }
    else{
       postObject1["date1"] =this.datepipe.transform(date1[0], 'yyyy-MM-dd');
       postObject1["date2"] =this.datepipe.transform(date1[1], 'yyyy-MM-dd');
      // postObject1["date2"] =date1
      // postObject1["date1"] =date1
    }


    
    console.log(postObject1)
  
  
  

    
    this.dataService.getPayment(postObject1).subscribe((response: any) => {
      
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        var tempDataArray = response['data'];
        for(var i=0;i<tempDataArray.length;i++)
        {
         
            this.dataArray.push(tempDataArray[i])
          
          // console.log(tempDataArray[i]['list']);
          // this.dataArray.push(tempDataArray[i]['list']);
        }
      console.log(this.dataArray,'dataArray')
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //  this.bind_values(this.dataArray);
      }
      else
      {
        this.dataSource = new MatTableDataSource<PeriodicElement>([]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }, (error) => {
      console.log('error is ', error)
    })

    // var tempArray = [{
    //   "fulfilment_header_transaction_id":"string",
    //   "category": "string",
    //   "cust_name_pt_txn": "string",
    //   "cust_phone_pt_txn": "string",
    //   "cust_email_pt_txn": "string",
    //   // date: string;
    //   "payment_info": "string",
    //   "txn_date_pt_txn": new Date(),
    //   "payment_through_pt_txn": "string",
    //   "amount_pt_txn": "string",
    //   "bank_ref_num_pt_txn": "string",
    //   "txn_status_pt_txn":"string"
      
    // }]
    // this.dataSource = new MatTableDataSource<PeriodicElement>(tempArray);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

   
  }

 
       // edit  list
       editClassList(row) {
        this.router.navigateByUrl('add-onlineclass', {state: { onlineclass_id: row.product_id, pageflag: "Edit" }});
      }

  public getPaginatorData(event: PageEvent): PageEvent {
    return event;
  }
  viewClass(){
    this.router.navigateByUrl('/view-classes',{state:{}});
  }

  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openMeditationDialog(element){
    var meditationData;
    if (this.model.paymenthistory)
    meditationData = this.model.meditationdata;
    else
    meditationData = {};
   
   var customerData = {
     "id":element.fulfilment_header_transaction_id,
     "category":element.category,
     "sub_category":element.sub_category,
     "product_id":element.component_id,
     "source":"payment"
   }
 
    this.dialog
      .open(EnableMeditationComponent,
        {
          width: '800px ',
          height:"500px",
          data: customerData,
        })
      .afterClosed()
      .subscribe(result => {
      
      });
  }

}
