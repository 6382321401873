import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../data.service';
import { debounceTime } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';


@Component({
  selector: 'app-transfer-dialog',
  templateUrl: './transfer-dialog.component.html', 
  styleUrls: ['./transfer-dialog.component.css']
})
export class TransferDialogComponent {
  countryCode: string;
  phoneNumber: string;
  userDetails: any; 
  filteredCountryData: any[] = [];
  detailsFetched: boolean = false;
  countryData: any[] = [];
  filteredCountries: any[];
  
  searchForm: FormGroup;
  searchControl = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<TransferDialogComponent>,
    private dataService: DataService ,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    console.log(data)
    this.searchForm = new FormGroup({
      countryCode: new FormControl(),
      phoneNumber: new FormControl()
    });
    // this.filteredCountryData = this.countryData;
    // this.searchControl.valueChanges
    //   .pipe(debounceTime(300))
    //   .subscribe(value => this.filterCountries(value));
   }



  
  ngOnInit(): void {
    this.dataService.getData(this.dataService.javaUrl + '/api/public/getAllCountries').subscribe(
        (response: any) => {
          if (response.code === 200) {
            this.countryData = response.data;
            this.filteredCountries = this.countryData;
          } else {
            console.error('Failed to fetch country data');
          }
        },
        (error) => {
          console.error('Error fetching country data:', error);
        }
      );
  }
  selectCountry(event: MatSelectChange): void {
    console.log(event.value)
    this.countryCode = event.value;
    // Your logic here
  }
  applyFilter(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredCountries = this.countryData.filter(country =>
      country.countryCode.toLowerCase().includes(filterValue) || country.countryName.toLowerCase().includes(filterValue)
    );
  }
  transferClassDetails(): void {
    if (!this.countryCode || !this.phoneNumber) {
      console.log(this.countryCode,this.phoneNumber)
      console.error('Country code or phone number is empty');
      return;
    }

    this.dataService.getUserDetails(this.countryCode, this.phoneNumber).subscribe(
      (response: any) => {
        console.log('User details retrieved successfully:', response);
        this.userDetails = response.data; 
        this.detailsFetched = true;
        
      },
      (error: any) => {
        console.error('Error retrieving user details:', error);
   
      }
    );
  }
  transferClassData() {
    var currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
    console.log(this.data.element)
    var className = this.data.element.Id;
    var customName=this.userDetails.first_name;
    var  newClientID = this.userDetails.id;
    console.log("Line 146",this.userDetails,customName)
    if (!this.userDetails || !this.userDetails.id) {
        console.error('User details are missing or invalid');
        return;
    }



    console.log("Line 152 ",className, "Customer Name", customName);

    this.dataService.replaceNewCustomer(className,customName, newClientID,currentDateTime).subscribe(
        (response: any) => {
            console.log('Replaced successfully:', response);
            this.cancel();
        },
        (error: any) => {
            console.error('Replacing failed', error);
        }
    );
}


  cancel(): void {
    this.dialogRef.close();
  }
}