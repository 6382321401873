import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../data.service';
import { MatSort } from '@angular/material/sort';
import { TransferDialogComponent } from '../transfer-dialog/transfer-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { EnableMeditationComponent } from '../enable-meditation/enable-meditation.component';
import { DisableDialogComponent } from '../disable-dialog/disable-dialog.component';
import { ViewDetailsComponent } from '../view-details/view-details.component';
import { MatSelectChange } from '@angular/material/select';

export interface PeriodicElement {
  fulfilment_header_transaction_id: any;
  ClassName: any;
  CustomerName: any;
  Year: any;
  DateOfPurchase: any;
  ClassId:any;
}

@Component({
  selector: 'app-user-contents',
  templateUrl: './user-contents.component.html',
  styleUrls: ['./user-contents.component.css']
})
export class UserContentsComponent implements OnInit {
  countryCode: string = '';
  phoneNumber: string = '';
  showClasses: boolean = false;
  classdata: any = [];
  dataSource = new MatTableDataSource<PeriodicElement>(this.classdata);
  selectedTab: string = 'Classes'; 
  filteredCountryData: any[] = [];
  searchControl = new FormControl('');
  searchClicked: boolean = false;
  countryData: any[] = [];
  filteredCountries: any[];


  onSearchClicked() {
    console.log("Search button clicked");
    this.searchClicked = true;
    this.fetchClassesByPhoneNumber();
  }
  
  ngOnInit(): void {
    this.dataService.getData(this.dataService.javaUrl + '/api/public/getAllCountries').subscribe(
        (response: any) => {
          if (response.code === 200) {
            this.countryData = response.data;
            this.filteredCountries = this.countryData;
          } else {
            console.error('Failed to fetch country data');
          }
        },
        (error) => {
          console.error('Error fetching country data:', error);
        }
      );
    this.fetchClassesByPhoneNumber();
  }
  selectCountry(event: MatSelectChange): void {
    const countryCode = event.value;
    // Your logic here
  }

  searchForm: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['CustomerName', 'ClassName', 'DateOfPurchase', 'Year','ClassId'];

  constructor(public dataService: DataService, private dialog: MatDialog, private http: HttpClient) {
    this.searchForm = new FormGroup({
      countryCode: new FormControl(),
      phoneNumber: new FormControl()
    });
    // this.filteredCountryData = this.countryData;
    // this.searchControl.valueChanges
    //   .pipe(debounceTime(300))
    //   .subscribe(value => this.filterCountries(value));
  }

//   filterCountries(value: string) {
//     this.filteredCountryData = this.countryData.filter(item =>
//       item.countryCode.toLowerCase().includes(value.toLowerCase()) ||
//       item.countryName.toLowerCase().includes(value.toLowerCase())
//     );
//   }

  toggleClasses() {
    this.showClasses = !this.showClasses;
  }

  applyFilter(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredCountries = this.countryData.filter(country =>
      country.countryCode.toLowerCase().includes(filterValue) || country.countryName.toLowerCase().includes(filterValue)
    );
  }

  transferDetails(element: any): void {
    console.log(element);
   
    const dialogRef = this.dialog.open(TransferDialogComponent, {
      width: '300px', 
      height:'400px',
      data: { element }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  disable(element: any) {
    console.log("Line 160", element);
    const dialogRef = this.dialog.open(DisableDialogComponent, {
      width: '350px',
      data: {
        message: `Are you sure you want to disable "${element.ClassName}" record?`, 
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.performDeletion(element.Id);
      }
    });
  }
  

  performDeletion(fulfilment_header_transaction_id: number) {
    console.log("Line 176",fulfilment_header_transaction_id)
    this.dataService.disableRecords(fulfilment_header_transaction_id).subscribe(
      (response: any) => {
        console.log('Record deleted successfully');
      },
      (error) => {
        console.error('Error deleting record:', error);
      }
    );
  }
  fetchClasses(){
    this.onSearchClicked(); 
    this.fetchClassesByPhoneNumber();
  }
  tabChanged(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel;
    console.log('Tab changed:', event.tab.textLabel);
    this.classdata = [];
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.classdata);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.fetchClassesByPhoneNumber();
  }

  openMeditationDialog(element) {
    console.log(element)
    if (!element) {
      console.error("Invalid element, cannot open dialog");
      return;
    }

    this.dialog.open(ViewDetailsComponent, {
      width: 'auto',
      maxHeight: '80vh',
      data: {
        customerData: element,
        a: this.selectedTab,
        showOptions: false
      }
    }).afterClosed().subscribe(result => {
    
    });
  }  
  
  
  

  fetchClassesByPhoneNumber() {
    const formData = this.searchForm.value;
    console.log(formData.countryCode, formData.phoneNumber);
    const countryCode = formData.countryCode;
    const phoneNumber = formData.phoneNumber;
    const selectedTab = this.selectedTab; 

    
    if (!countryCode || !phoneNumber || !selectedTab) {
        console.error('Country code or phone number is empty');
        return;
      }
    console.log(selectedTab)
    this.dataService.getphonelist(countryCode, phoneNumber, selectedTab).subscribe((response: any) => {
      console.log(response["data"]);

      this.classdata = response["data"];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.classdata);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
