import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'flex-layout-formly',
  template: `
    <div
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutGap="15px"
     
    >
      <formly-field [fxFlexOrder]="f.templateOptions?.fxFlexOrder" [field]="f" [fxFlex]="f.templateOptions?.fxFlex" *ngFor="let f of field.fieldGroup">
      </formly-field>
    </div>
  `,
})
export class FlexLayoutFormlyType extends FieldType  {

}
