<section class="dashboard-body" [ngClass]="menuClassDeatails()">
  <div class="container-fluid">
    <div fxLayout="column" fxFlex="100%" class="">
      <div class="top-col">
        <h5 class="head-text color-green">Testimonials</h5>
      </div>
      <div fxLayout="row" fxFlex="100%">
        <div fxFlex="50%">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </mat-form-field>
        </div>
        <div fxFlex="50%" fxLayoutalign="end">
          <div class="cls-btn-parent">
            <button type="submit" (click)="addClick()" submit-button mat-stroked-button color="success">Add</button>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <table fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort
          matSortActive="created" matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">
          <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
            <td mat-cell *matCellDef="let element"> {{element.language}} </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
            <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
          </ng-container>
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
            <td mat-cell *matCellDef="let element"> {{element.createdOn | date: 'dd/MM/yyyy hh:mm a'}} </td>
          </ng-container>
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
            <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
          </ng-container>
          <ng-container matColumnDef="modifiedOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified On </th>
            <td mat-cell *matCellDef="let element"> {{element.modifiedOn | date: 'dd/MM/yyyy hh:mm a'}} </td>
          </ng-container>
          <ng-container matColumnDef="modifiedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified By </th>
            <td mat-cell *matCellDef="let element"> {{element.modifiedBy}} </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button class="iconbutton" (click)="editClick(element)">
                <i class="fa fa-edit"></i>
              </button>
              <!-- <button mat-icon-button class="iconbutton">
                <i class="fas fa-eye"></i>
              </button> -->
              <mat-slide-toggle [checked]="element.enable" (change)="somethingChanged(element)">
                
              </mat-slide-toggle>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div fxLayout="row">
        <mat-paginator fxFlex="100%" (page)="getPaginatorData($event)" [length]="resultsLength" [pageSize]="pageSize"
          [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator>
      </div>
    </div>
  </div>
</section>