import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';

//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-onlineclass',
  templateUrl: './add-onlineclass.component.html',
  styleUrls: ['./add-onlineclass.component.css']
})
export class AddOnlineclassComponent implements OnInit {

  showMainMenu: any;
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  menuNavigation = 'vertical';

  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};

  fieldgroup = [];

  save_text = "Save"
  header_text = "Add"
  // for snackbar
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  config = new MatSnackBarConfig();


  constructor(
    private router: Router, private globalService: GlobalService, private _formBuilder: FormBuilder, public rootService: DataService, private snackBar: MatSnackBar
  ) {
    this.globalService.getObservable().subscribe((data) => {
      // console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }

  ngOnInit(): void {
    this.config.verticalPosition = this.verticalPosition;
    this.config.horizontalPosition = this.horizontalPosition;
    this.config.duration = this.setAutoHide ? this.autoHide : 0;

    this.bind_formly_elements();
    this.editLoadData();
  }
  //Bind form elements
  bind_formly_elements() {
    var form_elements = [
      {

        key: 'product_id',
        type: 'inno-input',
        label: 'Product Id',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: "",
        fxFlex: 30,
        fxFlexOrder: 1
      },

      {

        key: 'name',
        type: 'inno-input',
        label: 'Name',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: "",
        fxFlex: 30,
        fxFlexOrder: 1
      },
      {

        key: 'description',
        type: 'inno-input',
        label: 'Description',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: "",
        fxFlex: 30,
        fxFlexOrder: 2
      },

      {

        key: 'refLanguage',
        type: 'inno-select',
        label: 'Language',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 3,
        options: [{
          'value': "English", "label": "English"
        },
        {
          'value': "Tamil", "label": "Tamil"
        }]

      },

      {

        key: 'refLocation',
        type: 'inno-input',
        label: 'Location',
        childkey: '',
        hideExpression: '',
        required: false,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 4

      },

      {

        key: 'actualAmount',
        type: 'inno-input',
        label: 'Actual Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 5
      },
      {

        key: 'discountAmount',
        type: 'inno-input',
        label: 'Discount Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 6
      },
      {
        key: 'payableAmount',
        type: 'inno-input',
        label: 'Payable Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 7
      },
      {
        key: 'appstoreProductId',
        type: 'inno-input',
        label: 'Appstore Product Id',
        childkey: "",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 8
      },
      {

        key: 'appstoreActualAmount',
        type: 'inno-input',
        label: 'Appstore Actual Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 9
      },
      {
        key: 'appstorePayableAmount',
        type: 'inno-input',
        label: 'Appstore Payable Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 11
      },
      {

        key: 'appstorediscountAmount',
        type: 'inno-input',
        label: 'Appstore Discount Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 10
      },
      //CR2402030 We need to implement functionality to allow individual thumbails for each recorded classes are the current uses  the same thumbail for all classes
      {
        key: 'bg_image_url',
        type: 'inno-input',
        label: 'Background Image Url',
        childkey: '',
        hideExpression: '',
        required: false,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 6

      },
      {
        key: 'note',
        type: 'inno-input',
        label: 'Note',
        childkey: '',
        hideExpression: '',
        required: false,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 6

      }


    ]


    for (var i = 0; i < form_elements.length; i++) {

      var field_json =
      {
        key: form_elements[i].key,
        type: form_elements[i].type,

        class: "element-width",
        // defaultValue:form_elements[i].defaultValue,
        templateOptions: {
          label: form_elements[i].label,
          placeholder: form_elements[i].label,
          description: form_elements[i].label,
          type: form_elements[i].childkey,
          fxFlexOrder: form_elements[i].fxFlexOrder,

          fxFlex: form_elements[i].fxFlex,
          required: form_elements[i].required,
          options: form_elements[i].options,

          valueChange: (field, value) => {
            console.log(value, field, 'valuechanges')

            //  this.bind_values(field)
          },

        },


      };

      this.fieldgroup.push(field_json);



    }
    this.fieldgroup.push({

      key: 'productType',

      type: 'repeat',
 
      wrappers: ['inno-wrapper'],

      templateOptions: {

        addText: "Add Product Type",

        label: "Product type",

        fxFlex: "100%",

        fxLayout: 'column',

        fxFlexOrder: 15,
 
      },

      fieldArray: {

        fieldGroup: [

          {

            key: 'product_type',

            type: 'inno-select',

            defaultValue: "image",

            templateOptions: {

              label: 'Product Type',

              placeholder: 'Product Type',

              required: true,

              fxFlex: "30%",

              fxFlexOrder: 3,

              options: [{ value: "image", label: "Image" },

              { value: "article", label: "PDF" }, { value: "audio", label: "Audio" },

              { value: "video", label: "Video" },],

              valueChange: () => { }

            },

          },

          {

            key: 'productName',

            type: 'inno-input',
 
            templateOptions: {

              label: 'Name',

              placeholder: 'Name',

              description: 'Name',

              required: true,

              // class:'custom-width',

              fxFlex: "25%",

              fxFlexOrder: 2,
 
              valueChange: () => { }

            },

          },

          {

            key: 'productUrl',

            type: 'inno-input',

            templateOptions: {

              label: 'Url',

              placeholder: 'Enter Url',

              class: 'custom-width',

              required: true,

              fxFlex: "45%",

              fxFlexOrder: 3,
 
              valueChange: (field, $event) => { }

            },
 
          },
 
        ]

      },
 
    })
    
    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',

        },
        fieldGroup: this.fieldgroup
      }]
    console.log(this.fieldgroup, 'fieldgroup')
  }
  cancel() {
    this.router.navigateByUrl('/list-onlineclass',{state:{}});
  }
  async editLoadData() {
    if (window.history.state.onlineclass_id != undefined && window.history.state.onlineclass_id != "") {
      this.save_text = "Update";
      this.header_text = "Update";

      var postObject1 = {};
      postObject1["category"] = 'Online Class';
      postObject1["type"] = 'master';
      postObject1['product_id'] = window.history.state.onlineclass_id;
      postObject1["hash_value"] = await this.rootService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);


      this.rootService.getClasses(postObject1).subscribe((response: any) => {

        var resultsLength = response['data'].length;

        if (response['data'][0]['list'].length > 0) {
          var productList = response['data'][0]['list'];
          var productArray = [];
          productList.forEach(element => {
            var productJson = {
              "productName": element.name,
              "productUrl": element.product_url,
              "product_type": element.product_type,
            }
            productArray.push(productJson);
          });
        }
        if (resultsLength > 0) {
          this.model = {
            "product_id": response['data'][0]['product_id'],
            "name": response['data'][0]['name'],
            "description": response['data'][0]['description'],
            "refLanguage": response['data'][0]['language'],
            "refLocation": response['data'][0]['location'],
            "actualAmount": response['data'][0]['actual_amount'],
            "discountAmount": response['data'][0]['discount_amount'],
            "payableAmount": response['data'][0]['payable_amount'],
            "appstoreProductId": response['data'][0]['appstore_product_id'],
            "appstoreActualAmount": response['data'][0]['appstore_actual_amount'],
            "appstoreDiscountAmount": response['data'][0]['appstore_discount_amount'],
            "appstorePayableAmount": response['data'][0]['appstore_payable_amount'],
            "bg_image_url": response['data'][0]['bg_image_url'] ? response['data'][0]['bg_image_url'] : "classone",
            "note":response['data'][0]['note'],
            "productType": productArray
          }
        }
      }, (error) => {
        console.log('error is ', error)
      })


    }
  }

  async saveOnlineClass() {

    var product_type_array = [];

    if (this.model.productType.length > 0) {
      var productTypes = this.model.productType;

      productTypes.forEach(element => {
        var product_type_json = {
          "product_id": this.model.product_id,
          "name": element.productName,
          "description": "",
          "language": this.model.language,
          "location": this.model.location,
          "product_url": element.productUrl,
          "product_type": element.product_type,
          "bg_image_url":"",
          "fg_image_url": "",
          "start_date_time": '',
          "end_date_time": '',
          "note":this.model.note,
          "sub_name":"",
          "list":[]
        }
        product_type_array.push(product_type_json);
      });
    }


    // expected output: Tue Aug 19 1975 20:15:30 GMT+0200 (CEST)
    // (note: your timezone may vary)

    // event.setHours(20, 21, 22);
    // console.log(event);

    var mainbj = {};
    var obj = {};
    var langugaeObj = {};
    var classObj = {};
    classObj = [{
      "category": "Online Class",
      "product_id": this.model.product_id,
      "name": this.model.name,
      "description": this.model.description,
      "language": this.model.refLanguage,
      "location": this.model.refLocation,
      "product_url": "",
      "note":this.model.note,
      "actual_amount": this.model.actualAmount,
      "discount_amount": this.model.discountAmount,
      "payable_amount": this.model.payableAmount,
      "start_date_time": '',
      "end_date_time": '',
      "fg_image_url": "",
      "bg_image_url":this.model.bg_image_url ? this.model.bg_image_url : "classone",
      "appstore_product_id": this.model.appstoreProductId,
      "appstore_actual_amount": this.model.appstoreActualAmount,
      "appstore_discount_amount": this.model.appstorediscountAmount,
      "appstore_payable_amount": this.model.appstorePayableAmount,
      "list": product_type_array
    }]
    langugaeObj = [{
      "product_id": "OnClsEng001",
      "language": this.model.refLanguage,
      "name": this.model.name,
      "list": classObj
    }]
    obj['active_status'] = true;
    obj['type'] = 'master';
    obj['category'] = "Online Class",
      obj['list'] = langugaeObj;
    mainbj['type'] = "product";
    mainbj['loop'] = 1;
    mainbj['obj'] = obj;
    if (window.history.state.onlineclass_id != undefined && window.history.state.onlineclass_id != "") {
      mainbj['product_id'] = window.history.state.onlineclass_id;
    }

    // Get classes object
    var postObject1 = {};
    postObject1["category"] = "Online Class",
      postObject1["type"] = 'master';
    postObject1["hash_value"] = await this.rootService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);


    // For checking already exists  
    this.rootService.getClasses(postObject1).subscribe((response: any) => {

      var resultsLength = response['data'].length;
      if (resultsLength > 0) {
        var id = response['data'][0]['_id'];
        mainbj['_id'] = id;
        this.rootService.updateClasses(mainbj).subscribe((response: any) => {
          this.router.navigateByUrl('/list-onlineclass',{state:{}});
          this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);
          console.log(response, 'response')
        }, (error) => {
          console.log('error is ', error)
        })
      }
      else {
        this.rootService.saveClasses(mainbj).subscribe((response: any) => {
          this.router.navigateByUrl('/list-onlineclass',{state:{}});
          this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);
          console.log(response, 'response')
        }, (error) => {
          console.log('error is ', error)
        })

      }
    }, (error) => {
      console.log('error is ', error)
    })


    console.log(mainbj)
  }
  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }

}
