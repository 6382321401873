<div>
<div class="main_login">
  <div class="main_login_bx" style="background-color: white;">
    <div class="login_logo">
      <img src="../../assets/images/login-logo.png" style="cursor: pointer;" (click)="goPage('login')">
      <h3 style="font-size: 24px;">Update Mobile Number</h3>
    </div>
    <div class="main_login_form">
      <ul>
        <li>
          <div>
          <select style="width: 35%;
          margin: auto;
          border: 1px solid #a74dd9;
          border-radius: 6px;
          font-size: 22px;
          padding: 20px 12px;
          margin-bottom: 0px;"  name="selectedQuantity" class="form-control text-color" [(ngModel)]="selectedCountry">
            <option *ngFor="let option of countryList" [value]="option" >{{option}}</option>
        </select>

          <input maxlength="10" class="text-color" style="width: 54%;margin-left: 3px;margin-bottom: 0px;font-size: 22px;" type="text" name="mobileNumber" [(ngModel)]="mobileNumber" (ngModelChange)="validation();">
          <h5 style="margin-bottom: 25px;text-align: start;margin-left: 3px;color: red;margin-top: 3px;">
            {{mobileError}}
          </h5>
        </div>
        </li>
        <li><h4 style="margin-bottom: 10px;">Use this mobile number to login next time.</h4></li>
        <li>
          <input type="submit" value="UPDATE" (click)="loginSubmit()" style="cursor: pointer;">
        </li>
        
        <li>
          
          <table style="cursor: pointer;
          margin-left: 105px;
          margin-top: 30px;
      " (click)="goPage('login')">
            <tr>
              <td><img src="../../../assets/images/home.png" title="Home" style="width: 30px;margin-right: 5px;"></td>
              <td>Go to Home</td>
            </tr>
          </table>
        </li>
      </ul>	
    </div>	
  </div>
  <div style="height: 250px;">
  </div>
</div>
</div>