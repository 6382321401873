import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';

//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-estore',
  templateUrl: './add-estore.component.html',
  styleUrls: ['./add-estore.component.css']
})
export class AddEstoreComponent implements OnInit {
  showMainMenu: any;
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  menuNavigation = 'vertical';

  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};

  fieldgroup = [];

  save_text = "Save"
  header_text = "Add"
  // for snackbar
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  config = new MatSnackBarConfig();



  constructor(
    private router: Router, private globalService: GlobalService, private _formBuilder: FormBuilder, public rootService: DataService, private snackBar: MatSnackBar
  ) {
    this.globalService.getObservable().subscribe((data) => {
      // console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }

  ngOnInit(): void {

    this.config.verticalPosition = this.verticalPosition;
    this.config.horizontalPosition = this.horizontalPosition;
    this.config.duration = this.setAutoHide ? this.autoHide : 0;

    this.bind_formly_elements();
    this.editLoadData();

  }
  async editLoadData() {
    if (window.history.state.estore_id != undefined && window.history.state.estore_id != "") {
      this.save_text = "Update";
      this.header_text = "Update";

      var postObject1 = {};
      postObject1["category"] = 'E-Store';
      postObject1["type"] = 'master';
      postObject1['product_id'] = window.history.state.estore_id;
      postObject1["hash_value"] = await this.rootService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);


      this.rootService.getClasses(postObject1).subscribe((response: any) => {

        var resultsLength = response['data'].length;


        if (resultsLength > 0) {
          this.model = {
            "product_id": response['data'][0]['product_id'],
            "name": response['data'][0]['name'],
            "description": response['data'][0]['description'],
            "refLanguage": response['data'][0]['language'],
            "refLocation": response['data'][0]['location'],
            "actualAmount": response['data'][0]['actual_amount'],
            "discountAmount": response['data'][0]['discount_amount'],
            "payableAmount": response['data'][0]['payable_amount'],
            "fgImageUrl": response['data'][0]['fg_image_url'],


          }
        }
      }, (error) => {
        console.log('error is ', error)
      })


    }
  }

  async saveEstore() {

    var product_type_array = [];
    // if(this.model.productType.length>0)
    // {
    //   var productTypes = this.model.productType;
    //   productTypes.forEach(element => {
    //    var product_type_json =   {"product_id": this.model.product_id,
    //    "name":element.productName,
    //    "description": "",
    //    "language": this.model.language,
    //    "location": this.model.location,
    //    "product_url":element.productUrl,
    //    "bg_image_url": "",
    //    "fg_image_url": this.model.fgImageUrl,
    //    "start_date_time": '',
    //    "end_date_time": '',
    //    "note": ""}
    //    product_type_array.push(product_type_json);
    //   });
    // }


    // expected output: Tue Aug 19 1975 20:15:30 GMT+0200 (CEST)
    // (note: your timezone may vary)

    // event.setHours(20, 21, 22);
    // console.log(event);

    var mainbj = {};
    var obj = {};
    var langugaeObj = {};
    var classObj = {};
    classObj = [{
      "category": "E-Store",
      "product_id": this.model.product_id,
      "name": this.model.name,
      "description": this.model.description,
      "language": this.model.refLanguage,
      "location": this.model.refLocation,
      "product_url": "",
      "sub_name":"",
      "subscription_id":"",
      "note": "",
      "actual_amount": this.model.actualAmount,
      "discount_amount": this.model.discountAmount,
      "payable_amount": this.model.payableAmount,
      "start_date_time": '',
      "end_date_time": '',
      "fg_image_url": this.model.fgImageUrl,
      "bg_image_url": "",
      "appstore_product_id": '',
      "appstore_actual_amount": '',
      "appstore_discount_amount": '',
      "appstore_payable_amount": '',
      "list": [{"bg_image_url": "",
      "description": "",
      "fg_image_url": "",
      "language": this.model.refLanguage,
      "list": [],
      "location": "",
      "name": this.model.name,
      "product_id": this.model.product_id,
      "product_type": "",
      "product_url": "",
      "sub_name": ""}]
    }]
    langugaeObj = [{
      "product_id": "OnClsEng001",
      "language": this.model.refLanguage,
      "name": this.model.name,
      "list": classObj
    }]
    obj['active_status'] = true;
    obj['type'] = 'master';
    obj['category'] = "E-Store",
      obj['list'] = langugaeObj;
    mainbj['type'] = "product";
    mainbj['loop'] = 1;
    mainbj['obj'] = obj;
    if (window.history.state.estore_id != undefined && window.history.state.estore_id != "") {
      mainbj['product_id'] = window.history.state.estore_id;
    }

    // Get classes object
    var postObject1 = {};
    postObject1["category"] = "E-Store",
      postObject1["type"] = 'master';
    postObject1["hash_value"] = await this.rootService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);


    // For checking already exists  
    this.rootService.getClasses(postObject1).subscribe((response: any) => {

      var resultsLength = response['data'].length;
      if (resultsLength > 0) {
        var id = response['data'][0]['_id'];
        mainbj['_id'] = id;
        this.rootService.updateClasses(mainbj).subscribe((response: any) => {
          this.router.navigateByUrl('/list-estore',{state:{}});
          this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);
          console.log(response, 'response')
        }, (error) => {
          console.log('error is ', error)
        })
      }
      else {
        this.rootService.saveClasses(mainbj).subscribe((response: any) => {
          this.router.navigateByUrl('/list-estore',{state:{}});
          this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);
          console.log(response, 'response')
        }, (error) => {
          console.log('error is ', error)
        })

      }
    }, (error) => {
      console.log('error is ', error)
    })





    console.log(mainbj)
  }
  //Bind form elements
  bind_formly_elements() {
    var form_elements = [
      {

        key: 'product_id',
        type: 'inno-input',
        label: 'Product Id',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: "",
        fxFlex: 30,
        fxFlexOrder: 1
      },

      {

        key: 'name',
        type: 'inno-input',
        label: 'Name',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: "",
        fxFlex: 30,
        fxFlexOrder: 1
      },
      {

        key: 'description',
        type: 'inno-input',
        label: 'Description',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: "",
        fxFlex: 30,
        fxFlexOrder: 2
      },

      {

        key: 'refLanguage',
        type: 'inno-select',
        label: 'Language',
        childkey: '',
        hideExpression: '',
        required: true,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 3,
        options: [{
          'value': "English", "label": "English"
        },
        {
          'value': "Tamil", "label": "Tamil"
        }]


      },

      {

        key: 'refLocation',
        type: 'inno-input',
        label: 'Location',
        childkey: '',
        hideExpression: '',
        required: false,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 4

      },
      {
        key: 'fgImageUrl',
        type: 'inno-input',
        label: 'Fg Image Url',
        childkey: '',
        hideExpression: '',
        required: false,
        hide: false,
        value: [],
        fxFlex: 30,
        fxFlexOrder: 5

      },
      {

        key: 'actualAmount',
        type: 'inno-input',
        label: 'Actual Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 6
      },
      {

        key: 'discountAmount',
        type: 'inno-input',
        label: 'Discount Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 7
      },
      {

        key: 'payableAmount',
        type: 'inno-input',
        label: 'Payable Amount',
        childkey: "number",
        hideExpression: '',
        required: false,
        hide: false,
        value: '',
        fxFlex: 30,
        fxFlexOrder: 8
      },

    ]


    for (var i = 0; i < form_elements.length; i++) {

      var field_json =
      {
        key: form_elements[i].key,
        type: form_elements[i].type,

        class: "element-width",
        // defaultValue:form_elements[i].defaultValue,
        templateOptions: {
          label: form_elements[i].label,
          placeholder: form_elements[i].label,
          description: form_elements[i].label,
          type: form_elements[i].childkey,
          fxFlexOrder: form_elements[i].fxFlexOrder,

          fxFlex: form_elements[i].fxFlex,
          required: form_elements[i].required,
          options: form_elements[i].options,

          valueChange: (field, value) => {
            console.log(value, field, 'valuechanges')

            //  this.bind_values(field)
          },

        },


      };

      this.fieldgroup.push(field_json);
      this.formFields = [
        {
          type: 'flex-layout-formly',
          templateOptions: {
            fxLayout: 'column',

          },
          fieldGroup: this.fieldgroup
        }]
      console.log(this.fieldgroup, 'fieldgroup')


    }

  }
  cancel() {
    this.router.navigateByUrl('/list-estore',{state:{}});
  }
  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }

}
