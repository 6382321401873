<div class="card-body main-card">
    <div fxLayoutGap="5px">
        <form [formGroup]="form">
            <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
            </formly-form>
            <div class="cls-btn-parent" fxLayout="row"  fxLayoutGap="20%" fxLayout.xs="column" style="justify-content: flex-end;">
                <button type="button" mat-stroked-button class="cls-custom-btn" color="danger">Cancel</button>
                    <button type="submit"  mat-stroked-button class="cls-custom-btn" color="success">{{save_text}}</button>


            </div>
        </form>
    </div>
</div>
