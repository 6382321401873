import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-checkbox',
  template: `
  <mat-checkbox [formControl]="formControl" (id)="to.value" (change)="onChange($event)"  [value]="to.value" class="example-margin"
  >
{{to.label}}
</mat-checkbox>
  `,
})
export class FormlyFieldCustomCheckbox extends FieldType {
  onChange($event) {
    if (this.to.onClick) {
      this.to.onChange($event);
    }
  }
}