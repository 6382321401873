import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";


// environment setup for api
import { environment } from '../environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { throwError ,BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as sha512 from 'js-sha512';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})

export class DataService {
// Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/geojson'
    })
  }

  // declare url 
  
  // production
  public nodeUrl = "https://stagenodeapi.alphamindpower.net/api/";
  public javaUrl = "https://stageapi.alphamindpower.net/AMP";


  // development
  // public nodeUrl = "http://localhost:8100/api/";
  // public javaUrl = "https://localhost:9000/AMP";


  public MerchantKey = "ALPHA";
  public AMP_ACCID = "5e33d73b4eaa5415a4745266";

  public allUsers = [];
  constructor(private http: HttpClient, private toastr: ToastrService , private globalService: GlobalService) { }


  public getData(url: string){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get(url,{headers}).pipe(retry(2),catchError(this.handleError));
  }

  public postData(url: string, postObj: any){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(url,postObj,{headers}).pipe(retry(2),catchError(this.handleError));
  }
  showSuccess(title,msg) {
    this.toastr.success(title, msg);
  }
  showError(title,msg) {
    this.toastr.error(title, msg);
  }
  showWarning(title,msg) {
    this.toastr.warning(title, msg);
  }
  // generateHash(hash_value)
  // {
  // var hashedValue =   this.sha512(hash_value).then(x =>{
  //   return x;
  //  });
  //  return hashedValue;
  // }
  //  sha512(str) {
  //   return crypto.subtle.digest("SHA-512", new TextEncoder().encode(str)).then(buf => {
  //     return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
  //   });
  // }
  public generateHash(str: string){
    return sha512.sha512(str);
  }
  getClasses(classesObj) {
    return this.http.post(this.nodeUrl + 'product/getProducts', classesObj)
  }
  saveClasses(classesObj) {
    return this.http.post(this.nodeUrl + 'product/saveProducts', classesObj)
  }
  getSubscription(classesObj) {
    return this.http.post(this.nodeUrl + 'subscription/getSubscription', classesObj)
  }
  paymentCaptured(headerId) {
    var responseData = this.http.post(this.nodeUrl + 'payment/paymentCaptured', headerId);
    return responseData;
  }

  getOtp(countryCode: string, phoneNumber: string) {
    const url = this.nodeUrl + 'user/getOTPByMobileNumber';
    const body = { countryCode: countryCode, phoneNumber: phoneNumber };
    return this.http.post(url, body);
  }
  
  // https://stageapi.alphamindpower.net/AMP
  getProductOrderList(paymentObj) {
    return this.http.post(this.javaUrl + '/api/public/transaction/getProductOrderList', paymentObj)
  }
  getTransactionDetails(paymentObj) {
    return this.http.post(this.javaUrl + '/api/public/getTransactionDetails', paymentObj)
  }
  updateClasses(classesObj) {
    return this.http.post(this.nodeUrl + 'product/updateProducts', classesObj)
  }
  getPayment(paymentObj) {
    return this.http.post(this.nodeUrl + 'payment/getPayments', paymentObj)
  }
  public userListSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  getUser(paymentObj) {
    var responseData = this.http.post(this.nodeUrl + 'user/getUsers', paymentObj);
    return responseData;
  }
  disableRecords(fulfilment_header_transaction_id: number) {
    console.log("Inside delete records Line 107",fulfilment_header_transaction_id)
    return this.http.post(this.nodeUrl +'user/disableRecords' , { fulfilment_header_transaction_id });
  }
  replaceNewCustomer(className,customName, newClientID,currentDateTime){
    var data = {
      "className":className,
      "customName":customName,
      "newClientID":newClientID,
      "currentDateTime":currentDateTime}
      console.log("Data service 123",data)
    var responseData = this.http.post(this.nodeUrl + 'user/replaceNewCustomer/',data);
    console.log(responseData)
    return responseData;
  }
  getphonelist(cc,ph,selectedTab: string){
    var numberObj = {
      "country_code":cc,
      "mobile_number":ph,
      "selected_tab": selectedTab}
      console.log(numberObj)
    var responseData = this.http.post(this.nodeUrl + 'user/getuserByPhoneNumber/',numberObj);
    console.log(responseData)
    return responseData;
  }
  getUserDetails(countryCode: string, phoneNumber: string) {
    const userDetails = {
      "country_code":countryCode,
      "mobile_number":phoneNumber
    };
    return this.http.post(this.nodeUrl+'user/getUserDetails/', userDetails);
  }
  
  getClassId(classId){
    var body = {
      "classnumber":classId}
    return this.http.post(this.nodeUrl + 'user/getclassId/', body);
  }
  getTransferData(countryCode: string, phoneNumber: string): Observable<any> {
    const transferData = {
      countryCode,
      phoneNumber
    };
    return this.http.post(this.nodeUrl + 'user/getTransferData/',transferData);
  }

  getContent(type) {
    return this.http.get(this.nodeUrl + 'content?enableFlag=true&type=' + type)
  }
  saveContent(postObj) {
    return this.http.post(this.nodeUrl + 'content', postObj)
  }



  enablePaymentProduct(Obj){
    return this.http.post(this.nodeUrl + 'payment/enablePaymentProduct', Obj)
  }
  enableCustomerProduct(Obj){
    return this.http.post(this.nodeUrl + 'payment/enableCustomerProduct', Obj)
  }
  enableProduct(Obj){
    return this.http.post(this.nodeUrl + 'payment/enableProduct', Obj)
  }
  enableMeditation(Obj){
    return this.http.post(this.nodeUrl + 'payment/enableMeditation', Obj)
  }
  getPaymentsForClass(Obj){
    return this.http.post(this.nodeUrl + 'payment/getPaymentsForClass', Obj)
  }

  RegisterZoomMeet(postObject) {
    return this.http.post(this.nodeUrl + 'subscription/registerZoomUser', postObject)
  }
  RegisterAllZoomMeet(postObject) {
    return this.http.post(this.nodeUrl + 'subscription/registerAllZoomUser', postObject)
  }
  registerZoomarticipant(postObject) {
    return this.http.post(this.nodeUrl + 'subscription/registerZoomarticipant', postObject)
  }
  getSubscribedUsers(postObj) {
    return this.http.post(this.nodeUrl + 'subscription/getSubscribedUsers', postObj)
  }
  getParticipants(postObj) {
    return this.http.post(this.nodeUrl + 'payment/getParticipants', postObj)
  }

  enableParticipants(Obj){
    return this.http.post(this.nodeUrl + 'payment/enableParticipants', Obj)
  }
  
  patchParticipants(Obj){
    return this.http.patch(this.nodeUrl + 'subscription/patchParticipants', Obj)
  }
  getParticipantsHistory(Obj){
    return this.http.post(this.nodeUrl + 'subscription/getParticipantsHistroyById', Obj)
  }

  getRequiredJson(){
    return this.http.get(this.nodeUrl + 'subscription/getJson?name=requiredMoreInfo')
  }

  handleError(error:HttpErrorResponse)
  {
    var errorMessage="Unknown Error";
    return throwError(errorMessage);
  }
}