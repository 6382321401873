<div class="dialog-content">
<h2>Transfer Dialog</h2>
<div class="d-content">
  <mat-form-field class="country-code-input">
    <mat-label>Country Code</mat-label>
    <div [formGroup]="searchForm">
    <mat-select formControlName="countryCode" (selectionChange)="selectCountry($event)">
      <mat-option>
        <input matInput placeholder="Search" (input)="applyFilter($event.target.value)" (click)="$event.stopPropagation()">
      </mat-option>
      <mat-option *ngFor="let country of filteredCountries" [value]="country.countryCode">
        <img width="25" [src]="country.countryImageUrl">
        {{ country.countryCode }} - {{ country.countryName }}
      </mat-option>
    </mat-select></div>
  </mat-form-field>

<mat-form-field>
  <input matInput placeholder="Phone Number"  [(ngModel)]="phoneNumber" >
</mat-form-field>

<div *ngIf="!detailsFetched">
  <button type="button" (click)="transferClassDetails()" mat-stroked-button class="cls-custom-btn"
  color="success">Fetch</button>
  <button type="button" (click)="cancel()" mat-stroked-button class="cls-custom-btn"
  color="danger" style="margin-left: 20px;">Cancel</button>
</div>

<div *ngIf="userDetails && detailsFetched">
  <p>Name: {{ userDetails.first_name }} {{ userDetails.last_name }}</p>
  <p>Email: {{ userDetails.email_id }}</p>
  <button type="button" (click)="transferClassData()" mat-stroked-button class="cls-custom-btn"
  color="success">Transfer</button> 
  <button type="button" (click)="cancel()" mat-stroked-button class="cls-custom-btn"
  color="danger" style="margin-left: 20px;">Cancel</button>
</div>
</div>
</div>

<!-- <ngcomponent *ngIf="userDetails && userDetails.length > 0"> <button mat-button (click)="transferClassData($event.customer_id)">Transfer</button> </ngcomponent> -->
 