import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-label',
  template: `
  <div fxFlex="100%" [class]="to.class">
  <h3>{{ to.label }}</h3>
  </div>

  `,
})

export class FormlyFieldCustomLabel extends FieldType {
  //  [fxFlex]="to.fxFlex"
}