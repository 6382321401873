
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { DataService } from '../data.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationComponent implements OnInit {
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  config = new MatSnackBarConfig();
  constructor(public dataService: DataService, private snackBar: MatSnackBar) {

  }
  form = new FormGroup({});
  resultsLength = 0;
  productValues = [];
  productDetails = [];
  model: any = {};
  obj: any = [];
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          className: 'flex-1',
          type: 'inno-select',
          key: 'tselect',
          templateOptions: {
            label: 'Type',
            description: '',
            required: true,
            options: [
              { value: 'guest', label: 'Guest' },
              { value: 'moderator', label: 'Moderator' },
              { value: 'co-ordinator', label: 'Co-ordinator' }
            ],
            valueChange: (field, value) => {
              console.log(value, field, 'valuechanges')
              //this.form.get('meeting id').setValue('84829795344');
              //  this.bind_values(field)
            },
          },
          validation: {
            messages: {
              required: 'You need to select a type! '
            }
          }
        },
        {
          className: 'flex-1',
          type: 'inno-select',
          key: 'cselect',
          templateOptions: {
            label: 'Class',
            description: '',
            required: true,
            customSelect: true,
            options: this.productValues,
            valueChange: (field, value) => {
              console.log(value, field, 'valuechanges')
              this.fetchMeetingId(value);
              //this.form.get('meeting id').setValue('84829795344');
              //  this.bind_values(field)
            },
          },
          validation: {
            messages: {
              required: 'You need to select the class! '
            }
          },
        },
        {
          className: 'flex-1',
          type: 'inno-input',
          key: 'meeting_id',
          templateOptions: {
            label: 'Meeting Id',
            disabled: true,
          },
          validation: {
            messages: {
              required: 'You need to provide a meeting id! '
            }
          }
        },

      ],
    },

    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [

        {
          className: 'flex-1',
          type: 'inno-input',
          key: 'firstName',
          templateOptions: {
            label: 'First Name',
            required: true,
          },
          validation: {
            messages: {
              required: 'You need to provide a first name! '
            }
          }
        },
        {
          className: 'flex-1',
          type: 'inno-input',
          key: 'lastName',
          templateOptions: {
            label: 'Last Name',
            required: true,
          },
          validation: {
            messages: {
              required: 'You need to provide a last name! '
            }
          },
          // expressionProperties: {
          //   'templateOptions.disabled': '!model.firstName',
          // },
        },
        {
          className: 'flex-2',
          type: 'inno-input',
          key: 'countrycode',
          defaultValue: "+91",
          templateOptions: {
            type: 'string',
            maxLength: 6,
            label: 'Country Code',
            placeholder: '',
            required: true,
          },
          validation: {
            messages: {
              required: 'You need to provide a country code! '
            }
          }
        },
        {
          className: 'flex-1',
          type: 'inno-input',
          key: 'pnumber',
          templateOptions: {
            type: 'tel',
            maxLength: 20,
            label: 'Phone Number',
            placeholder: 'Phone Number',
            required: true,
          },
          validation: {
            messages: {
              required: 'You need to provide a phone number! '
            }
          }
        },
        {
          className: 'flex-6',
          type: 'inno-input',
          key: 'email',
          templateOptions: {
            type: 'text',
            label: 'Email',
            placeholder: 'Email',
            required: true,
            pattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
          },
          validation: {
            messages: {
              pattern: (error, field: FormlyFieldConfig) => ` Please type valid Email Address`,
            },
          },
        }
      ],
    },


  ];
  fetchMeetingId(value): void {
    //console.log('hi');

    var postObject1 = {};
    postObject1["category"] = 'Classes';
    postObject1["type"] = 'master';
    postObject1["hash_value"] = this.dataService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);
    // postObject1["hash_value"] = '9d08b287ceed3b48133342aaec4dec97668be08e05855a3b708210a2e2f6fc2a0301ae2c9a42ca0e99822a7b1279559ee9aa68962e818247d090a17a9145a656';

    this.dataService.getClasses(postObject1).subscribe((response: any) => {

      this.resultsLength = response['data'].length;
      if (this.resultsLength > 0) {
        var tempDataArray = response['data'][0]['list'];
        // this.productValues.push( { value: '0', label: 'All' });
        for (var i = 0; i < tempDataArray.length; i++) {

          var language_wise_classes = tempDataArray[i]['list'];
          for (var j = 0; j < language_wise_classes.length; j++) {

            var pruoductId = language_wise_classes[j].product_id;
            if (value == pruoductId) {
              console.log(language_wise_classes[j].meeting_id);
              this.form.get('meeting_id').setValue(language_wise_classes[j].meeting_id as never);
            }


          }
          // console.log(this.productValues); 
          //console.log(this.productDetails); 
          // console.log(tempDataArray[i]['list']);
          // this.dataArray.push(tempDataArray[i]['list']);
        }

        //  this.bind_values(this.dataArray);
      }
    });



  }
  submit() {
    //alert(JSON.stringify(this.model));
    console.log(this.form.valid);
    this.obj = this.model;
    var myCountryCode = this.obj.countrycode ? this.obj.countrycode : "+91";
    if (this.obj.countrycode.charAt(0) != "+") {
      myCountryCode = "+" + this.obj.countrycode;
    }


    if (!isNaN(myCountryCode.charAt(1))) {

      var postObject = {
        "email_id": this.obj.email,
        "country_code": myCountryCode,
        "mobile_number": this.obj.pnumber,
        "customer_name": this.obj.firstName + ' ' + this.obj.lastName,
        "meeting_id": this.obj.meeting_id,
        "class_id": this.obj.cselect,
        "user_type": this.obj.tselect

      };
      console.log(postObject)
      this.model = {};
      this.form.clearValidators()

      this.dataService.RegisterZoomMeet(postObject).subscribe((response: any) => {
        this.snackBar.open("Registered Successfully", this.action ? this.actionButtonLabel : undefined, this.config);
        this.model = {}
        this.form.reset()
        Object.keys(this.form.controls).forEach(key => {
          this.form.controls[key].setErrors(null)
        });
      }, (error) => {
        this.snackBar.open("Something went wrong", this.action ? this.actionButtonLabel : undefined, this.config);
        this.model = {};
      })
    } else {

      this.snackBar.open("Something went wrong", this.action ? this.actionButtonLabel : undefined, this.config);
    }
  }
  ngOnInit(): void {

    this.config.verticalPosition = this.verticalPosition;
    this.config.horizontalPosition = this.horizontalPosition;
    this.config.duration = this.setAutoHide ? this.autoHide : 0;

    var postObject1 = {};
    postObject1["category"] = 'Classes';
    postObject1["type"] = 'master';
    //postObject1["hash_value"] = this.dataService.generateHash("ALPHA"+"|"+postObject1["category"]+"|"+postObject1["type"]);
    //postObject1["hash_value"] = '2627506703da31db00fa395a8b862a26f49644a44c2c2087cae49799b47419d6697cf6b40d032b0743d5e2f14120930f884f92ce39ae4d75e32099f479242aae';
    postObject1["hash_value"] = '9d08b287ceed3b48133342aaec4dec97668be08e05855a3b708210a2e2f6fc2a0301ae2c9a42ca0e99822a7b1279559ee9aa68962e818247d090a17a9145a656';


    this.dataService.getClasses(postObject1).subscribe((response: any) => {

      this.resultsLength = response['data'].length;
      // if(response['data'].length > 0)
      // {
      //   console.log(response['data'])
      // }
      // this.productValues.push( { value: '0', label: 'All' });
      if (this.resultsLength > 0) {
        var tempDataArray = response['data'][0]['list'];

        for (var i = 0; i < tempDataArray.length; i++) {

          var language_wise_classes = tempDataArray[i]['list'];
          for (var j = 0; j < language_wise_classes.length; j++) {
            //   var startDate = language_wise_classes[j].start_date_time;
            var date = new Date();
            //var startDate = "2021-01-09T09:30:00.000Z"
            var cdate = new Date(language_wise_classes[j].start_date_time);
            if (date < cdate) {
              var pruoductId = language_wise_classes[j].product_id;
              //console.log(language_wise_classes[j].meeting_id);

              this.productDetails.push(language_wise_classes[j]);
              var productJson = { label: language_wise_classes[j].name, value: pruoductId };
              this.productValues.push(productJson)


            }


          }
          //  console.log(this.productValues); 
          //console.log(this.productDetails); 
          // console.log(tempDataArray[i]['list']);
          // this.dataArray.push(tempDataArray[i]['list']);
        }

        //  this.bind_values(this.dataArray);
      }
    });
  }



}
