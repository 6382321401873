import { Component } from '@angular/core';
import { GlobalService } from './global.service';
import { HeaderComponent } from './header/header.component'
import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Innocart';
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  currentTheme = 'theme-1';
  currentFont = 'font-roboto';
  
  constructor(
    private globalService: GlobalService,
    private dataService: DataService,
  ) {
    this.globalService.getObservable().subscribe((data) => {
       console.log('globalService Data received: ', data);
      if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.currentTheme) {
        this.currentTheme = data.currentTheme;
        console.log('this.currentTheme: ', this.currentTheme);
      } else if (data.currentFont) {
        this.currentFont = data.currentFont;
        console.log('this.currentFont: ', this.currentFont);
        this.colorDetails();
      }
    });

    this.dataService.getUser({}).subscribe((response: any) => {
      this.dataService.allUsers= response["data"];
      this.dataService.userListSubject.next(true);
    })
  }

  colorDetails(): string {
    if (this.colorMode === 'dark') {
      return 'app-dark-theme ' + this.currentColor + ' ' + this.currentTheme + ' ' + this.currentFont;
    } else if (this.colorMode === 'light') {
      return 'app-light-theme ' + this.currentColor + ' ' + this.currentTheme + ' ' + this.currentFont;
    }
  }

}
