import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-autocomplete',
  template: `
<mat-form-field appearance="outline" fxFlex="100" class="margin-space">
<mat-label>Number</mat-label>
<input type="text"
       placeholder="Pick one"
       aria-label="Number"
       matInput
       [matAutocomplete]="auto">
<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let option of autoOptions" [value]="option">
    {{option}}
  </mat-option>
</mat-autocomplete>
</mat-form-field>
  `,
})
export class FormlyFieldCustomAutoComplete extends FieldType {
  autoOptions= ['One', 'Two', 'Three'];
}