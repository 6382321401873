import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component'
import { pairwise, startWith, map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';
import { OrderHistoryComponent } from '../order-history/order-history.component';
import { PaymentHistoryComponent } from '../payment-history/payment-history.component';
import { EnableMeditationComponent } from '../enable-meditation/enable-meditation.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

export interface PeriodicElement {
  id:string,
  user_name: string;
  email_id: string;
  mobile_number: string;
  adress_line_1: string;
  // date: string;
  city: string;
  state: string;
  country: string;
  creation_timestamp: Date;
  action: any;
 
  
}
@Component({
  selector: 'app-class-contents',
  templateUrl: './class-contents.component.html'
})
export class ClassContentsComponent implements OnInit {
  showMainMenu: any;
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  menuNavigation = 'vertical'

  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
      - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
      ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  public lastEnd: Date = this.today;
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  public yearEnd: Date = this.today;

  fieldgroup = [];
  daysfieldgroup = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  pageSize=10;
  resultsLength=0;
  dataArray=[];
  var_readonly: any;
  var_value: any;
  search="";
  orderData = {};
  paymentData = {};
  meditationValue = {};
  dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
  productValues=[];
  productDetails=[];
  @ViewChild(MatSort) sort: MatSort;
  // 'content',
  displayedColumns: string[] = [  'title','language','createdOn','createdBy','modifiedOn','modifiedBy','action'];
constructor(public dataService: DataService,  private router: Router,
  private dialog: MatDialog,private _router: Router, private globalService: GlobalService, private _formBuilder: FormBuilder, public rootService: DataService, private snackBar: MatSnackBar
  ) {
    this.globalService.getObservable().subscribe((data) => {
      // console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }


  ngOnInit(): void {

  
    this.formFields = [];

    this.bindCustomers();
  }
  async bindCustomers()
  {

    
   
    // var category="online class"
    // var status="failure"
    // var product="alpha meditation"
    // var date1="2018-02-14"
    // var date2="2018-09-06"

    
    this.dataService.getContent("class-contents").subscribe((response: any) => {
      console.log('Line 128 response is ', response)
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        var tempDataArray = response['data'];
        for(var i=0;i<tempDataArray.length;i++)
        {
         
            this.dataArray.push(tempDataArray[i])
        }
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      }
    }, (error) => {
      console.log('error is ', error)
    })

   
  }

  somethingChanged(element){
    console.log(element)
    if(element.enable){
      element.enable = false;
    }else{
      element.enable = true;
    }

    var _this = this;
    element["id"] = element._id;
    _this.dataService.saveContent(element).subscribe((response: any) => {
      
    }, (error) => {
     
    })
  }
 
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    console.log(event);
    return event;
  }
  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addClick(){
    this._router.navigateByUrl('editor', {state:{ "page":"class-contents","data":{}}});
  }

  editClick(row){
    console.log(row)
    var myObject = row;
    myObject["language"] = row.language;
    console.log(myObject)
    this._router.navigateByUrl('editor', {state:{ "page":"class-contents","data":myObject}});
  }
}
