import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
  <mat-form-field appearance="outline" fxFlex="100%" class="margin-space" [class]="field.templateOptions?.class">
  <mat-label>{{to.label}}</mat-label>
  <input [type]="to.type?to.type:'text'" matInput [formControl]="formControl" [formlyAttributes]="field"
         [placeholder]="to.placeholder">
         <div class="error_msg"  *ngIf="showError"><formly-validation-message [field]="field"></formly-validation-message></div>
</mat-form-field>

  `,
})

export class FormlyFieldCustomInput extends FieldType {
  //  [fxFlex]="to.fxFlex"
}