
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input-select',
  template: `
<mat-form-field appearance="outline" fxFlex="100" class="margin-space">
  <mat-label>{{to.label}}</mat-label>
  <mat-select [formControl]="formControl" [formlyAttributes]="field" (valueChange)="to.valueChange(field,$event)">
     
      <ng-container  *ngFor="let item of to.options">
      <mat-option [value]="item.value">{{
        item.label
      }}</mat-option>
    </ng-container>
    </mat-select>
    <div class="error_msg_select"  *ngIf="showError"><formly-validation-message [field]="field"></formly-validation-message></div>
</mat-form-field>
  `,
})
export class FormlyFieldInputSelect extends FieldType {
  
}