import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-radio',
  template: `
  <div class="custom-radio" fxFlex="100%" fxLayout="row">
  <mat-radio-group name="to.key" fxFlex="100"  [formControl]="formControl" 
  aria-labelledby="example-radio-group-label" class="margin-space">
  <mat-radio-button  *ngFor="let season of to.options" [value]="season.value">
    {{season.label}}
  </mat-radio-button>
</mat-radio-group>
</div>
  `,
})
export class FormlyFieldCustomRadio extends FieldType {

}