<div class="loader">
  <!-- <img src="../../../../assets/images/loader.gif" /> -->
  <div class="loadingio-spinner-dual-ball-r6b2kwzylr"><div class="ldio-fv8gjnm13lb">
    <div></div><div></div><div></div>
    </div></div>
    <style type="text/css">
    @keyframes ldio-fv8gjnm13lb-o {
        0%    { opacity: 1; transform: translate(0 0) }
       49.99% { opacity: 1; transform: translate(40px,0) }
       50%    { opacity: 0; transform: translate(40px,0) }
      100%    { opacity: 0; transform: translate(0,0) }
    }
    @keyframes ldio-fv8gjnm13lb {
        0% { transform: translate(0,0) }
       50% { transform: translate(40px,0) }
      100% { transform: translate(0,0) }
    }
    .ldio-fv8gjnm13lb div {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      top: 30px;
      left: 10px;
    }
    .ldio-fv8gjnm13lb div:nth-child(1) {
      background: #1d3f72;
      animation: ldio-fv8gjnm13lb 1s linear infinite;
      animation-delay: -0.5s;
    }
    .ldio-fv8gjnm13lb div:nth-child(2) {
      background: #5699d2;
      animation: ldio-fv8gjnm13lb 1s linear infinite;
      animation-delay: 0s;
    }
    .ldio-fv8gjnm13lb div:nth-child(3) {
      background: #1d3f72;
      animation: ldio-fv8gjnm13lb-o 1s linear infinite;
      animation-delay: -0.5s;
    }
    .loadingio-spinner-dual-ball-r6b2kwzylr {
      width: 94px;
      height: 94px;
      display: inline-block;
      overflow: hidden;
    }
    .ldio-fv8gjnm13lb {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(0.94);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-fv8gjnm13lb div { box-sizing: content-box; }
    /* generated by https://loading.io/ */
    </style>
</div>