import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { MatSort } from '@angular/material/sort';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../global.service';
import { TableUtil } from '../tableutil';

import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
export interface PeriodicElement {
  product_id: string;
  name: string;
  language: string;
  location: string;
  //  date: Date;
  start_date_time:Date;
  end_date_time: Date;
  end_payment_time:Date;
  actual_amount: number;
  discount_amount: number;
  payable_amount: number;
  total_participants:string
  action:any

  
 
}
@Component({
  selector: 'app-list-classes',
  templateUrl: './list-classes.component.html',
  styleUrls: ['./list-classes.component.css']
})

export class ListClassesComponent implements OnInit {
    options: FormlyFormOptions = {};
    form = new FormGroup({});
    formModel: any = {}
    formFields: Array<FormlyFieldConfig>
    model: any = {};
 
    showMainMenu: any;
    colorMode = 'dark';
    currentColor = 'bgcolor-black';
    menuNavigation = 'vertical';
    //  dataSource = [];
    search = '';
   
    @Output() editdeleterow = new EventEmitter<any>();
    resultsLength = 0;
    pageSize = 10;
    data: Observable<any[]>;
    dataArray= [];
    datepipe = new DatePipe('en-US');
    dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['product_id', 'name', 'language','location', 'start_date_time','end_date_time','actual_amount','discount_amount','payable_amount','meeting_id','action'];
  // dataSource = [{product_id: 1, name: 'name1', language: 'language1', location: 'location1',date: '26-8-2020',start_time:'1.00 PM', end_time: '2.00 PM', actual_amount: 80, discount_amount: 100,payable_amount:56,product_type:'Video',total_participants:1},
  // {product_id: 2, name: 'name2', language: 'language2', location: 'location2',date: '26-8-2020',start_time:'1.00 PM', end_time: '2.00 PM', actual_amount: 50, discount_amount: 1070,payable_amount:67,product_type:'Audio',total_participants:2},
  // {product_id: 3, name: 'name3', language: 'language3', location: 'location3',date: '26-8-2020',start_time:'1.00 PM', end_time: '2.00 PM', actual_amount: 23, discount_amount: 800,payable_amount:88,product_type:'Image',total_participants:5},
  // {product_id: 4, name: 'name4', language: 'language4', location: 'location4',date: '26-8-2020',start_time:'1.00 PM', end_time: '2.00 PM', actual_amount: 17, discount_amount: 100,payable_amount:10,product_type:'Audio',total_participants:2}];

  classesObj = {};
 
  constructor(
    private router: Router, private globalService: GlobalService,  public dataService: DataService, 
  ) {
    this.globalService.getObservable().subscribe((data) => {
      console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',
        },
        fieldGroup: [
          {
            key: 'language',
            type: 'inno-select',
            defaultValue:"0",
            templateOptions: {
              label: "Language",
              fxFlex: "22%",
              placeholder: "Language",
              fxLayout: 'column',
              fxFlexOrder: 1,
              options:[{
                label:'All',value:'0'},{
                label:'English',value:'English'},
               { label:'Tamil',value:'Tamil'}
              ],
              valueChange: (field, value) => {
                console.log(value, field, 'valuechanges')
    
                //  this.bind_values(field)
              },
            },
          },
   
   
      // {
      //   key: 'class_date',
      //   type: 'inno-daterangepicker',
      //   templateOptions: {
      //     label: "Date Picker",
      //     fxFlex: "22%",
      //     placeholder: "Date Picker",
      //     fxLayout: 'column',
      //     fxFlexOrder: 4,
      //   },
      // },
    ],
  },
    ]
    this.bindClasses();
  }
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }
  
  saveClass()
  {
    console.log(this.model);
  }
  // edit  list
  editRuleList() {}
  // Delete List
  confirmdelete() {
  
  }
  filterClass()
  {
  
    var filters ={};
    if(this.model.language !== '0')
    {
    filters['language']=this.model.language;
    }
 
       
        const filterKeys = Object.keys(filters);
        var filteredArray =  this.dataArray.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            if(filters[key] === item[key])
              return true
        });
      });
      this.dataSource = new MatTableDataSource<PeriodicElement>(filteredArray);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
   
  }
     // edit  list
     editClassList(row) {
      //this._router.navigate('add-class', { class_id: row.product_id,meeting_id: row.meeting_id, pageflag: "Edit" });
      this.router.navigateByUrl('/add-class', { state: { class_id: row.product_id,meeting_id: row.meeting_id, pageflag: "Edit" } });
    }
  async bindClasses()
  {

    
    var postObject1 = {};
    postObject1["category"] = 'Classes';
    postObject1["type"] = 'master';
    postObject1["hash_value"] = await this.dataService.generateHash("ALPHA"+"|"+postObject1["category"]+"|"+postObject1["type"]);

    
     this.dataService.getClasses(postObject1).subscribe((response: any) => {
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        var tempDataArray = response['data'][0]['list'];
        for(var i=0;i<tempDataArray.length;i++)
        {
          var language_wise_classes = tempDataArray[i]['list'];
          for(var j=0;j<language_wise_classes.length;j++)
          {
            this.dataArray.push(language_wise_classes[j])
          }
          // console.log(tempDataArray[i]['list']);
          // this.dataArray.push(tempDataArray[i]['list']);
        }
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      //  this.bind_values(this.dataArray);
      }

    }, (error) => {
      console.log('error is ', error)
    })

  
  

    // var countURL = this.dataService.apiUrl + "/entities?type=RULE&options=count";
    // this.dataService.getObservable(countURL).subscribe((dataCount) => {
    //   this.resultsLength = dataCount['data'];
    // })
    // ,this.search.valueChanges
    // this.data = merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       // this.isLoadingResults = true;
    //       var offset = this.paginator.pageIndex * this.paginator.pageSize;
    //       var orderby = this.sort.direction == 'desc' ? "!" + this.sort.active : this.sort.active;

         
    //         var reqURL = `${this.dataService.apiUrl}/entities?type=RULE&orderBy=${orderby}&limit=${this.paginator.pageSize}&offset=${offset}&q=name==${this.search}`;
    //       // reqURL+=attrs;
    //       return this.dataService!.getObservable(reqURL);
    //     }),
    //     map(data => {
    //       // this.isLoadingResults = false;
    //       //  this.dataArray = data.data;
    //       return this.bind_values(data['data'])
    //       // if (data.length == 0) { this.dataNotFound = true; } else { this.dataNotFound = false; }
    //       //return data['data'];

    //     }),
    //     catchError(() => {
    //       // this.isLoadingResults = false;
    //       //        this.dataNotFound = true;

    //       return observableOf([]);
    //     })
    //   )
    // console.log(this.data, 'thisdata')
  }

    // Customize values and push into array
    bind_values(classesDataArray) {
      var dataArr = [];
      for (var i = 0; i < classesDataArray.length; i++) {
      
      
  
        var dataObj = {};
        dataObj['product_id'] = classesDataArray[i].product_id;
        dataObj['name'] = classesDataArray[i].name;
        dataObj['language'] = classesDataArray[i].language;
        dataObj['location'] = classesDataArray[i].location;
        // dataObj['date'] =  new Date(`2018-12-${Math.round(Math.random() * 30)}`);
        dataObj['start_date_time'] = classesDataArray[i].start_date_time;
        dataObj['end_date_time'] = classesDataArray[i].end_date_time;
        dataObj['actual_amount'] = classesDataArray[i].actual_amount;
        dataObj['discount_amount'] = classesDataArray[i].discount_amount;
        dataObj['payable_amount'] = classesDataArray[i].payable_amount;
        dataObj['total_participants'] = classesDataArray[i].payable_amount;
        dataObj['meeting_id'] = classesDataArray[i].meeting_id;
        
        dataArr.push(dataObj);
       
      }
      this.dataArray = dataArr;
      console.log(this.dataArray, 'dataarray')
      
    }
  addClass()

  {
      this.router.navigateByUrl('/add-class',{state:{}});
  }
  public getPaginatorData(event: PageEvent): PageEvent {
    return event;
  }
  viewClass(row){
    console.log(row)
    this.router.navigateByUrl('/view-classes', { state:{class_id: row.product_id,meeting_id: row.meeting_id, pageflag: "View" }});
  }
  exportTable() {
    TableUtil.exportTableToExcel("classesTable","ClassesList",["L1"]);
  }
  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }

}
