import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-textarea',
  template: `
  <mat-form-field appearance="outline" fxFlex="100" class="margin-space">
  <mat-label>{{to.label}}</mat-label>
   <textarea matInput  [name]="key" [cols]="to.cols" [formControl]="formControl" [formlyAttributes]="field"
[rows]="to.rows" [disabled]="to.disabled">
</textarea>
</mat-form-field>
  `,
})
export class FormlyFieldCustomTextArea extends FieldType {
  get type() {
    return this.to.type || 'text';
  }
}