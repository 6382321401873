import { Component } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-repeat',
  template: `
  <div class="repeat-component" >
<div><span>{{field.templateOptions.label}}</span></div>
 
<div fxFlex="100%" fxLayout="row wrap" 
  fxLayout.xs="column" 
  fxLayoutAlign="space-between" *ngFor="let field of field.fieldGroup; let i = index;">
    <div fxFlex="100%" fxLayout="row wrap" class="wrap-div">
      <div fxLayout="row"  fxFlex="100%" class="div-remove-btn d-flex align-items-end">
      <button class="btn btn-danger remove-btn" type="button" (click)="remove(i)">X</button>
      </div>
      <formly-group   [fxFlexOrder]="field.templateOptions?.fxFlexOrder"  fxLayout="row wrap" fxLayoutGap="15px"
        [model]="model[i]"
        [field]="field"
        [options]="options"
        [form]="formControl">

      </formly-group>
  </div>
 

</div>

<div fxLayout="row"  fxFlex="100%" style="margin-top:10px">
<button class="btn btn-success add-btn" type="button" (click)="add()">+ {{field.templateOptions.addText}}</button>
</div>
</div>

  `,
})

export class FormlyFieldCustomRepeat extends FieldArrayType {
  constructor(builder: FormlyFormBuilder) {
    super();                                                 //change
  }
}