import { Component, OnInit, Inject,Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.css']
})
export class ScheduleDialogComponent implements OnInit {
  fieldgroup = [];
  daysfieldgroup = [];
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
  starttime = '0.00';
  endtime = '23.59';
  exp: any;
  var_readonly: any;
  var_value: any;
//   constructor(private dialogRef: MatDialogRef<ScheduleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ScheduleDialogComponent, ) {
//   this.model =  {
//     "name": "repeatingoccurrence",
//     "from_date": "2021-05-13T18:30:00.000Z",
//     "from_time": "2021-05-13T18:34:00.000Z",
//     "to_date": "2021-05-17T18:30:00.000Z",
//     "to_time": "2021-05-13T18:35:00.000Z",
//     "wed": true,
//     "never": false,
//     "repetition_to_date": "2021-05-13T18:30:00.000Z"
// }

//    }

   constructor(
    public dialogRef: MatDialogRef<ScheduleDialogComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    
    this.model = {...data};
    // this.action = this.local_data.action;
  }

  ngOnInit(): void {

    this.model =this.data;
    console.log(this.data,'importedscheduledata');
    var form_elements = [
      {

        key: 'name',
        type: 'inno-select',
        label: 'Schedule',
        childkey: '',
        hideExpression: '',
        fxLayout: 'row',
        fxFlex: '100%',
        value: [
          { value: 'always', label: 'Always active' },
          { value: 'occurrence', label: 'Plan an occurrence' },
          { value: 'repeatingoccurrence', label: 'Plan a repeating occurrence' },


        ]

      },
      {

        key: 'label1',
        type: 'inno-label',
        label: 'Period',
        childkey: '',
        placeholder: 'From',
        hideExpression: '',
        fxFlex: '100%',
        class: "cls-label1",
        wrappertext: "Period",

      },

      {

        key: 'from_date',
        type: 'inno-datepicker',
        label: 'From',
        childkey: '',
        placeholder: 'From',
        hideExpression: '',
        fxFlex: '25%',
        wrappertext: "Period",

      },
      {

        key: 'from_time',
        type: 'inno-timepicker',
        label: 'From',
        placeholder: 'From',
        childkey: '',
        hideExpression: '',
        fxFlex: '15%',
        readonly: false,
        value: this.starttime,

      },
      {

        key: 'to_date',
        type: 'inno-datepicker',
        label: 'To',
        placeholder: 'To',
        childkey: '',
        hideExpression: '',
        fxFlex: '25%',


      },
      {

        key: 'to_time',
        type: 'inno-timepicker',
        label: 'to',
        placeholder: 'To',
        childkey: '',
        hideExpression: '',
        fxFlex: '15%',
        readonly: false,
        value: this.endtime,
      },
      {

        key: 'allday',
        type: 'inno-checkbox',
        label: 'All day',
        value: "allday",
        hideExpression: '',
        fxFlex: '100%',

      },
      {

        key: 'label2',
        type: 'inno-label',
        label: 'Repeat occurrence every',
        childkey: '',
        placeholder: 'From',
        hideExpression: '',
        fxFlex: '100%',
        class: "cls-label2",
        wrappertext: "Repeat occurrence every",

      },
      {

        key: 'mon',
        type: 'inno-checkbox',
        label: 'MON',
        value: 'MO',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },
      {

        key: 'tue',
        type: 'inno-checkbox',
        label: 'TUE',
        value: 'TUE',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },
      {

        key: 'wed',
        type: 'inno-checkbox',
        label: 'WED',
        value: 'WED',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },
      {

        key: 'thu',
        type: 'inno-checkbox',
        label: 'THU',
        value: 'THU',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },
      {

        key: 'fri',
        type: 'inno-checkbox',
        label: 'FRI',
        value: 'FRI',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },
      {

        key: 'sat',
        type: 'inno-checkbox',
        label: 'SAT',
        value: 'SAT',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },
      {

        key: 'sun',
        type: 'inno-checkbox',
        label: 'SUN',
        value: 'SUN',
        class: 'mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn',
        hideExpression: '',
        fxFlex: '12%',

      },

      {

        key: 'label3',
        type: 'inno-label',
        label: 'Repetition ends',
        childkey: '',
        placeholder: 'From',
        hideExpression: '',
        fxFlex: '100%',
        class: "cls-label3",
        wrappertext: "Repetition ends",

      },

      {

        key: 'never',
        type: 'inno-checkbox',
        label: 'Never',
        wrappertext: "Repetition ends",
        hideExpression: '',
        fxFlex: '100%',

      },
      {

        key: 'repetition_to_date',
        type: 'inno-datepicker',
        label: 'To',
        childkey: '',
        placeholder: 'To',
        hideExpression: '',
        fxFlex: '25%',
        readonly: false,

      },









    ]
    this.fieldgroup = [];

    for (var i = 0; i < form_elements.length; i++) {
      this.exp = this.bind_elements(form_elements[i].key, form_elements[i].value)
      // this.var_readonly=  this.bind_elements(form_elements[i].key,form_elements[i].value)
      // this.var_value=  this.bind_elements(form_elements[i].key,form_elements[i].value)
      // this.var_readonly = this.change_values(form_elements[i].key,form_elements[i].value);

      var field_json =
      {
        key: form_elements[i].key,
        type: form_elements[i].type,
        hideExpression: this.exp,
        class: "element-width",

        // wrappers: ['inno-modal'],
        templateOptions: {
          label: form_elements[i].label,
          wrappertext: form_elements[i].wrappertext,
          placeholder: form_elements[i].placeholder,
          description: form_elements[i].label,
          childkey: form_elements[i].childkey,
          fxFlex: form_elements[i].fxFlex,
          fxLayout: 'row',
          required: true,
          class: form_elements[i].class,
          options: form_elements[i].value,
          readonly: form_elements[i].readonly,
          value: form_elements[i].value,
          valueChange: (field, $event) => {
            //  this.change_values(field, $event)
          },
          onClick: (field, $event) => {

            this.dialogRef.close(this.form.value);
          },
          onChange: (field, value) => {

            this.change_values(field, value)
          }
        },
      };

      this.fieldgroup.push(field_json);



    }
    // for (var i = 0; i < this.days.length; i++) {
    //   this.exp=  this.bind_elements(this.days[i],this.days[i])
    //   var days_field_json=
    //   {
    //     key: this.days[i],
    //     type: 'inno-checkbox',
    //     hideExpression: this.exp,

    //     templateOptions: {
    //       label: this.days[i],
    //       fxFlex:'10%',
    //       class:"mat-focus-indicator mat-stroked-button mat-button-base mat-success cls-custom-btn cls-day-btn",
    //       onClick: $event => {

    //      }
    //     },
    //   };

    //   this.fieldgroup.push(days_field_json);
    // }

    console.log(this.fieldgroup)
    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',

        },
        fieldGroup: this.fieldgroup
      },

    ];
  }
  // submit() {
  //   this.dialogRef.close(this.form.value);
  // } 

  bind_elements(key, values) {
    var occurence_arr = ['from_date', 'to_date', 'from_time', 'to_time', 'allday', 'label1'];
    var repetition_occurence_arr = ['repetition_to_date', 'never', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'label2', 'label3'];

    var enable_occurence_arr = ['from_time', 'to_time'];
    var enable_repetition_occurence_arr = ['repetition_to_date']

    if (occurence_arr.some((item) => item === key)) {

      return '!model.name || model.name === "always"';
    }
    if (repetition_occurence_arr.some((item) => item === key)) {

      return '!model.name || model.name === "occurrence" || model.name === "always"';
    }




  }
  change_values(key, values) {

    console.log(key, values)
    var occurence_arr = ['from_time', 'to_time'];
    var repetition_occurence_arr = ['repetition_to_date']
    var temp_arr = [];
    if (key.source.value === 'allday')
      temp_arr = occurence_arr;
    else
      temp_arr = repetition_occurence_arr;
    if (key.checked === true) {


      this.fieldgroup.map(function (childfield) {
        if (temp_arr.some((item) => item === childfield.key)) {
          childfield.templateOptions.readonly = true;
        }
      });

    }
    else {
      this.fieldgroup.map(function (childfield) {
        if (temp_arr.some((item) => item === childfield.key)) {
          childfield.templateOptions.readonly = false;
        }
      });
    }
    console.log(this.fieldgroup)
  }
  closeDialog() {
    this.dialogRef.close(this.form.value);
  }
  submit() {
    // if (this.form.valid) {
      this.dialogRef.close({ event: 'close', data: this.model });
      console.log(JSON.stringify(this.model),'schedulemodel');
    // }


  }



}
