import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit {

  editor: Editor;
  html = '';

  

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  title: any = "";
  myEdits: any;
  form = new FormGroup({});
  model: any = {};
  obj: any = [];
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];

  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  config = new MatSnackBarConfig();

  id: any;

  constructor(public dataService: DataService, private snackBar: MatSnackBar, private router: Router) {

    var _this = this;
    console.log(window.history.state)
    console.log(localStorage.getItem("LoggedInUserName"))
    if (window.history.state["page"]) {
      if (window.history.state["page"] == "testimonials") {
        this.title = "";
        this.fields = [
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                className: 'flex-1',
                type: 'inno-input',
                key: 'title',
                templateOptions: {
                  label: 'Title',
                },
                validation: {
                  messages: {
                    required: 'You need to provide a title '
                  }
                }
              },
              {
                className: 'flex-1',
                type: 'inno-input',
                key: 'customerName',
                templateOptions: {
                  label: 'Customer Name',
                },
                validation: {
                  messages: {
                    required: 'You need to provide a Customer Name '
                  }
                }
              },
            ]
          }
        ]
      } else {
        this.title = "Class Contents";
        this.fields = [
          {
            fieldGroupClassName: 'display-flex',
            fieldGroup: [
              {
                className: 'flex-1',
                type: 'inno-input',
                key: 'title',
                templateOptions: {
                  label: 'Title',
                },
                validation: {
                  messages: {
                    required: 'You need to provide a title '
                  }
                }
              },
              {
                className: 'flex-1',
                type: 'inno-select',
                key: 'language',
                templateOptions: {
                  label: 'Type',
                  description: '',
                  required: true,
                  options: [
                    { value: 'English', label: 'English' },
                    { value: 'Tamil', label: 'Tamil' }
                  ],
                  valueChange: (field, value) => {
                    console.log(value, field, 'valuechanges')
                  },
                },
                validation: {
                  messages: {
                    required: 'You need to select a language! '
                  }
                }
              }
            ]
          }
        ]
      }
      if (window.history.state["data"]) {
        this.myEdits = window.history.state["data"]["content"];
        this.id = window.history.state["data"]["_id"];
        setTimeout(() => {
          _this.model = window.history.state["data"];
        }, 1000);
      }
    } else {
      this.router.navigateByUrl("list-classes", { state: {} });
    }
  }

  ngOnInit(): void {
    this.config.verticalPosition = this.verticalPosition;
    this.config.horizontalPosition = this.horizontalPosition;
    this.config.duration = this.setAutoHide ? this.autoHide : 0;
    this.editor = new Editor();
  }

  saveClick() {
    console.log(this.myEdits)
    console.log(this.model)
    var _this = this;
    var postObject = {
      "language": this.model.language ? this.model.language : "Both",
      "type": window.history.state["page"],
      "title": this.model.title,
      "content": this.myEdits,
      "customerName": this.model.customerName,
      "createdBy": localStorage.getItem("LoggedInUserName"),
      "enable": true
    };
    postObject["id"] = this.id ? this.id : "";
    console.log(postObject)
    this.model = {};
    this.form.clearValidators()

    this.dataService.saveContent(postObject).subscribe((response: any) => {
      this.snackBar.open("Saved Successfully", this.action ? this.actionButtonLabel : undefined, this.config);
      _this.cancelClk();
    }, (error) => {
      this.snackBar.open("Something went wrong", this.action ? this.actionButtonLabel : undefined, this.config);
      this.model = {};
      _this.cancelClk();
    })
  }

  cancelClk() {
    if (window.history.state["page"]) {
      this.router.navigateByUrl(window.history.state["page"], {});
    } else {
      this.router.navigateByUrl("list-classes", { state: {} });
    }
  }
}


