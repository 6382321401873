import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaterialModule } from './material-module';
import { NgChartsModule } from 'ng2-charts';
import { DndModule } from 'ngx-drag-drop';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';  
//import { MaterialModule } from './material-module';
import { AngularSplitModule } from 'angular-split';
import { FileUploadModule } from 'ng2-file-upload';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import { ma } from '@syncfusion/ej2-angular-calendars';
//import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";

import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog'
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgApexchartsModule } from "ng-apexcharts";
import {MatMenuModule} from '@angular/material/menu';
// datatable plugin

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { fetchotpComponent } from './fetch-otp/fetch-otp.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

//import { FlexLayoutModule } from '@angular/flex-layout';

import { FormlyFieldCustomTextArea } from './formly/formly-field-custom-textarea';
import { FormlyFieldCustomInput } from './formly/formly-field-custom-input';
import { FormlyFieldCustomFile } from './formly/formly-field-custom-file';
import { FlexLayoutFormlyType } from './formly/flex-layout-formly.component';
import { FormlyFieldCustomSelect } from './formly/formly-field-custom-select';
import { FormlyFieldInputSelect} from './formly/formly-field-input-select'
import { FormlyFieldCustomMultiSelect } from './formly/formly-field-custom-multiselect';
import { FormlyFieldCustomRadio } from './formly/formly-field-custom-radio';
import { FormlyFieldCustomDatePicker } from './formly/formly-field-custom-datepicker';
import { FormlyFieldCustomAutoComplete } from './formly/formly-field-custom-autocomplete';
import { FormlyFieldCustomCheckbox } from './formly/formly-field-custom-checkbox';
import { FormlyFieldCustomRepeat } from './formly/formly-field-custom-repeat';
import { FormlyFieldCustomButton  } from './formly/formly-field-custom-button';
import { FormlyFieldCustomToggle  } from './formly/formly-field-custom-toggle';
import { FormlyFieldCustomWrapper  } from './formly/formly-field-custom-wrapper';
import { ScheduleDialogComponent } from './schedule-dialog/schedule-dialog.component';
import { FormlyFieldCustomTimePicker } from './formly/formly-field-custom-timepicker';
import { FormlyFieldCustomLabel } from './formly/formly-field-custom-label';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MasterSlaComponent } from './master-sla/master-sla.component';
import { ScheduleOnlineClassComponent } from './schedule-online-class/schedule-online-class.component';
import { AddProductComponent } from './add-product/add-product.component';
import { ListCustomerComponent } from './list-customer/list-customer.component';
import {ListPaymentComponent} from './list-payment/list-payment.component';
import { FormlyFieldCustomDateRangePicker } from './formly/formly-field-custom-daterangepicker';
import { AddClassComponent } from './add-class/add-class.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { EnableMeditationComponent } from './enable-meditation/enable-meditation.component';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { ListOnlineclassComponent } from './list-onlineclass/list-onlineclass.component';
import {ListClassesComponent} from './list-classes/list-classes.component'
import {ListMeditationsComponent} from './list-meditations/list-meditations.component';
import { AddEstoreComponent } from './add-estore/add-estore.component';
import { AddMeditationComponent } from './add-meditation/add-meditation.component';
import { AddOnlineclassComponent } from './add-onlineclass/add-onlineclass.component';
import { ListEstoreComponent } from './list-estore/list-estore.component';
import { HeaderComponent } from './header/header.component';
// import { ViewClassComponent } from './view-class/view-class.component';
import { ToastrModule } from 'ngx-toastr';
import { Data } from './providers/data.provider';
import { LoginComponent } from './auth/login/login.component';
import { LoginUpdateComponent } from './auth/login-update/login-update.component';
import { OTPComponent } from './auth/otp/otp.component';
import { ProgressComponent } from './progress/progress.component';
import { RegistrationComponent } from './registration/registration.component';
import { GuestUsersComponent } from './guest-users/guest-users.component';
import { ViewClassTestComponent } from './view-class-test/view-class-test.component';
import { ListParticipantsComponent } from './list-participants/list-participants.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { EditorComponent } from './editor/editor.component';
import { TestimonialsComponent } from './list-testimonials/testimonials.component';
import { ClassContentsComponent } from './class-contents/class-contents.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule, Routes } from '@angular/router';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxEditorModule } from 'ngx-editor';
import { UserContentsComponent } from './user-contents/user-contents.component';
import { TransferDialogComponent } from './transfer-dialog/transfer-dialog.component';
import { DisableDialogComponent } from './disable-dialog/disable-dialog.component';

//import { FormlyAttributes } from '@ngx-formly/core/lib/templates/formly.attributes';

@NgModule({
  exports:[FlexLayoutModule,MaterialModule,RouterModule,],
  declarations: [
    fetchotpComponent,
    FormlyFieldCustomRepeat,
    FormlyFieldCustomTextArea,
    FormlyFieldCustomFile,
    FormlyFieldCustomInput,
    FlexLayoutFormlyType,
    FormlyFieldCustomSelect,
    FormlyFieldInputSelect,
    FormlyFieldCustomMultiSelect,
    FormlyFieldCustomRadio,
    FormlyFieldCustomDatePicker,
    FormlyFieldCustomDateRangePicker,
    FormlyFieldCustomTimePicker,
    FormlyFieldCustomAutoComplete,
    FormlyFieldCustomCheckbox,
    FormlyFieldCustomLabel,
    FormlyFieldCustomToggle,
    AppComponent,
    FormlyFieldCustomButton ,
    FormlyFieldCustomWrapper,
    ScheduleDialogComponent,
    ConfirmDialogComponent,
    MasterSlaComponent,
    ScheduleOnlineClassComponent,
    AddProductComponent,
    ListCustomerComponent,
    ListPaymentComponent,
    AddClassComponent,
    OrderHistoryComponent,
    PaymentHistoryComponent,
    EnableMeditationComponent,
    ViewDetailsComponent,
    ListOnlineclassComponent,
    ListClassesComponent,
    ListMeditationsComponent,
    AddEstoreComponent,
    AddMeditationComponent,
    AddOnlineclassComponent,
    ListEstoreComponent,
    HeaderComponent,
    // ViewClassComponent,
    ProgressComponent,
    LoginComponent,
    LoginUpdateComponent,
    OTPComponent,
    RegistrationComponent,
    GuestUsersComponent,
    ViewClassTestComponent,
    ListParticipantsComponent,
    EditorComponent,
    TestimonialsComponent,
    ClassContentsComponent,
    UserContentsComponent,
    TransferDialogComponent,
    DisableDialogComponent,
  ],
  // entryComponents: [ScheduleDialogComponent,ConfirmDialogComponent,OrderHistoryComponent,
  // PaymentHistoryComponent,EnableMeditationComponent,GuestUsersComponent],

  imports: [
    FlexLayoutModule,
    NgxEditorModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    
    FormlyModule.forRoot({ 
      extras: { lazyRender: true },
     
      types: [
      { name: 'flex-layout-formly', component: FlexLayoutFormlyType },
      { name: 'inno-textarea', component: FormlyFieldCustomTextArea},
      { name: 'inno-input', component: FormlyFieldCustomInput},
      { name: 'inno-select', component: FormlyFieldCustomSelect},
      { name: 'input-select', component: FormlyFieldInputSelect},
      { name: 'inno-multiselect', component: FormlyFieldCustomMultiSelect},
      { name: 'inno-radio', component: FormlyFieldCustomRadio},
      { name: 'inno-datepicker', component: FormlyFieldCustomDatePicker},
      { name: 'inno-daterangepicker', component: FormlyFieldCustomDateRangePicker},
      { name: 'inno-timepicker', component: FormlyFieldCustomTimePicker},
      { name: 'inno-autocomplete', component: FormlyFieldCustomAutoComplete},
      { name: 'inno-checkbox', component: FormlyFieldCustomCheckbox},
      { name: 'inno-button', component: FormlyFieldCustomButton},
      { name: 'inno-label', component: FormlyFieldCustomLabel},
      { name: 'inno-toggle', component: FormlyFieldCustomToggle},
      { name:'repeat',component:FormlyFieldCustomRepeat},
      { name: 'file', component: FormlyFieldCustomFile, wrappers: ['form-field',]},
      
 
    ],
      wrappers: [
        { name: 'inno-wrapper', component: FormlyFieldCustomWrapper },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ], 
    }),
    NgxMatTimepickerModule,
    MatDatepickerModule,
    RouterModule,
    FormlyMaterialModule,
    HttpClientModule,
    BrowserModule,
    FileUploadModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    DndModule,
    MatToolbarModule,
    DragDropModule,
    NgxGalleryModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    AngularSplitModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgApexchartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatMenuModule,
    CKEditorModule
  ],
  providers: [Data,DatePipe,{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
  bootstrap: [AppComponent]
})
export class AppModule { }
