import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'formly-field-custom-date-range-picker',
  template: `
  <div class="slct-hrs">
  <!--  
  <ejs-daterangepicker
  [formControl]="formControl"
  [allowEdit]='false'
  placeholder='Select Date'>
      <e-presets>
          <e-preset label="This Week" [start]='weekStart' [end]='weekEnd'></e-preset>
          <e-preset label="This Month" [start]='monthStart' [end]='monthEnd'></e-preset>
          <e-preset label="Last Month" [start]='lastStart' [end]='lastEnd'></e-preset>
          <e-preset label="Last Year" [start]='yearStart' [end]='yearEnd'></e-preset>
      </e-presets>
  </ejs-daterangepicker>

--> 


<mat-form-field appearance="outline">

  <input matInput formControlName="releasedAt" [matDatepicker]="releasedAtPicker">

  <mat-datepicker-toggle matPrefix [for]="releasedAtPicker">
    <mat-icon matDatepickerToggleIcon>
      calendar_month
    </mat-icon>
  </mat-datepicker-toggle>

  <mat-datepicker #releasedAtPicker>

  </mat-datepicker>

</mat-form-field>

</div>
  `,
})
export class FormlyFieldCustomDateRangePicker extends FieldType {
    public today: Date = new Date(new Date().toDateString());
    public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
    public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
        - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
        ;
    public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
    public monthEnd: Date = this.today;
    public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
    public lastEnd: Date = this.today;
    public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
    public yearEnd: Date = this.today;
}



