import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
 
@Component({
  selector: "formly-field-custom-button",
  template: `  <div [class]="to.parentclass"> <button type="button" (click)="onClick($event)" mat-stroked-button [class]="to.class" color="success">{{to.label}}</button></div>`


})
export class FormlyFieldCustomButton  extends FieldType {
  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}