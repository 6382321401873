<section class="dashboard-body" [ngClass]="menuClassDeatails()">
  <div class="container-fluid">
      <div fxLayout="column" fxFlex="100%" class="">
          <div class="top-col">
              <h5 class="head-text color-green">Customers View</h5>
             
          </div>
    <div fxLayout="row" fxFlex="100%">

        <div fxFlex="50%">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </mat-form-field>
        </div>
        <!-- <div fxFlex="50%" fxLayoutalign="end">
          <form [formGroup]="form">
            <formly-form [form]="form" [model]="model" [fields]="formFields">
            </formly-form>
        </form>
        <div class="cls-btn-parent" fxFlex="30%">
            <button type="submit" submit-button mat-stroked-button 
                color="success">Export</button>
        </div>
        </div> -->
    </div>
    <div fxLayout="row">
        <table  fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
        (matSortChange)="resetPaging()">
      
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        
         
         
          <!-- Name Column -->
          <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
          </ng-container>
           
          <ng-container matColumnDef="mobile_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Phone </th>
            <td mat-cell *matCellDef="let element"> {{element.mobile_number}} </td>
          </ng-container>
        
          <ng-container matColumnDef="email_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email_id}}   </td>
          </ng-container>
      
          <ng-container matColumnDef="adress_line_1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.adress_line_1}} </td>
          </ng-container>
      
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>
        
        
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
            <td mat-cell *matCellDef="let element"> {{element.state}} </td>
          </ng-container>

          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
            <td mat-cell *matCellDef="let element"> {{element.country}} </td>
          </ng-container>
          <ng-container matColumnDef="creation_timestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Registration Date </th>
            <td mat-cell *matCellDef="let element"> {{element.creation_timestamp  | date:'medium'}} </td>
          </ng-container>
      
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button class="iconbutton">
                <i class="fa fa-edit" ></i>
              </button>
              <button mat-icon-button class="iconbutton">
                <i class="fas fa-eye"></i>
              </button>
              <button mat-icon-button class="iconbutton" (click)="openOrderDialog(element)">
                <i class="fa fa-history"></i>
              </button>
              <button mat-icon-button class="iconbutton" (click)="openPaymentDialog(element)">
                <i class="fab fa-paypal"></i>
              </button>
              <button mat-icon-button class="iconbutton" (click)="openMeditationDialog(element)">
                <i class="fas fa-eye" ></i>
              </button>
          </td>
          </ng-container>
      
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        </div>
        <div  fxLayout="row" >
        <mat-paginator fxFlex="100%"  (page)="getPaginatorData($event)" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator>
        </div>
</div>
</div>
</section>