<section class="dashboard-body" [ngClass]="menuClassDeatails()">
    <div class="container-fluid">
        <div fxLayout="column" fxFlex="100%" fxLayoutGap="15px">
            <div class="top-col">
                <h5 class="head-text color-green">E-Store</h5>
               
            </div>
            <div fxLayout="row" fxFlex="100%" >
                <form fxFlex="65%" [formGroup]="form">
                  <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
                  </formly-form>
                </form>
                <div class="example-button-row" fxFlex="8%" >
                    <button mat-stroked-button  (click)="filterEstore()">Apply</button>
                </div>
                <div class="example-button-row" fxFlex="8%" >
                <button type="submit" (click)="addEstore()" submit-button mat-stroked-button class="cls-save-btn" color="success">  <i class="fa fa-plus pr-2" ></i>Add</button>
                  </div>
              </div>
  <div fxLayout="row">
      
      <table  fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
      (matSortChange)="resetPaging()">
        <!-- id Column -->
        <ng-container  matColumnDef="product_id">
          <th mat-header-cell *matHeaderCellDef> Product Id</th>
          <td mat-cell *matCellDef="let element"> {{element.product_id}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
    
        <!-- Language Column -->
        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef> Language </th>
          <td mat-cell *matCellDef="let element"> {{element.language}} </td>
        </ng-container>
    
        <!-- Location Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef> Location </th>
          <td mat-cell *matCellDef="let element"> {{element.location}} </td>
        </ng-container>
  
         <!-- Start_date_time Column -->
         <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>  Date </th>
          <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>
  
         
      
  
         
  
       <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="iconbutton" (click)="editClassList(element)" >
              <i class="fa fa-edit" ></i>
            </button>
            <!-- <button mat-icon-button class="iconbutton">
              <i class="fa fa-trash" ></i>
            </button> -->
            <!-- <button mat-icon-button class="iconbutton">
              <i class="fa fa-eye" (click)="viewClass()"></i>
            </button> -->
        </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
   
  </div>
    <div  fxLayout="row">
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons 
      aria-label="Select page of periodic elements">
  </mat-paginator>
      <!-- <mat-paginator  fxFlex="100%"  (page)="getPaginatorData($event)" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]" [length]="resultsLength" [pageSize]="pageSize">
      </mat-paginator> -->
  </div>
  </div>
  </div>
  </section>
  