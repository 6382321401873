<div class="card-body" >
<!-- <div class="card-body" style="height: 500px; overflow-y: auto;"> -->
  <div fxLayoutGap="15px" fxFlex="100%" fxLayout="column">
    <div><span class="repeat-header-txt">  View Details</span></div>
    <br>

  
  <div >
    <table fxFlex="100%" mat-table *ngIf="dataSource?.data.length > 0" [dataSource]="dataSource" matSort matSortActive="created" matSortDisableClear
      matSortDirection="desc" class="mat-elevation-z8" (matSortChange)="resetPaging()">

      <ng-container matColumnDef="product_id">
        <th mat-header-cell *matHeaderCellDef> Product Id</th>
        <td mat-cell *matCellDef="let element"> {{element.product_id}} </td>
      </ng-container>
      <ng-container matColumnDef="product_type">
        <th mat-header-cell *matHeaderCellDef> Product Type </th>
        <td mat-cell *matCellDef="let element"> {{element.product_type}} </td>
      </ng-container>

      <ng-container matColumnDef="product_name">
        <th mat-header-cell *matHeaderCellDef>Product Name </th>
        <td mat-cell *matCellDef="let element"> {{element.product_name}} </td>
      </ng-container>

      <ng-container matColumnDef="product_url">
        <th mat-header-cell *matHeaderCellDef> Product Url </th>
        <td mat-cell *matCellDef="let element"> {{element.product_url}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="dataSource?.data.length == 0">
      <p>Details for this product are not available</p>
    </div>
    
  </div>
  
  <!-- <div fxLayout="row" fxFlex="100%" *ngIf="dataSource?.data.length > 0">
    <mat-paginator [length]="classdata.length"
               [pageSize]="pageSize"
               [pageSizeOptions]="[5, 10, 25, 100]"
               aria-label="Select page"
               (page)="onPageChange($event)">
</mat-paginator>

    <br>
    
  </div> -->
  <div fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column" style="justify-content: flex-end;"> 
    <mat-paginator *ngIf="dataSource?.data.length > 0"
      [length]="classdata.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
      (page)="onPageChange($event)">
</mat-paginator>
    <button type="button" (click)="onCancelClick()" mat-stroked-button class="cls-custom-btn" color="danger">Cancel</button>
  </div>
</div>
</div>

<!-- <div class="space"> 
  <button type="button" (click)="onCancelClick()" mat-stroked-button class="cls-custom-btn"
  color="danger">Cancel</button> </div> -->

