import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleOnlineClassComponent } from './schedule-online-class/schedule-online-class.component';
import { AddProductComponent } from './add-product/add-product.component';
import { ListCustomerComponent } from './list-customer/list-customer.component';
import {ListPaymentComponent} from './list-payment/list-payment.component';
import { AddClassComponent } from './add-class/add-class.component';
import { ListOnlineclassComponent } from './list-onlineclass/list-onlineclass.component';
import {ListClassesComponent} from './list-classes/list-classes.component'
import {ListMeditationsComponent} from './list-meditations/list-meditations.component';
import { AddOnlineclassComponent } from './add-onlineclass/add-onlineclass.component';
import { AddMeditationComponent } from './add-meditation/add-meditation.component';
import { AddEstoreComponent } from './add-estore/add-estore.component';
import { ListEstoreComponent } from './list-estore/list-estore.component';
import { HeaderComponent } from './header/header.component'
// import { ViewClassComponent } from './view-class/view-class.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginUpdateComponent } from './auth/login-update/login-update.component';
import { OTPComponent } from './auth/otp/otp.component';
import { RegistrationComponent } from './registration/registration.component';
import { GuestUsersComponent } from './guest-users/guest-users.component';
import { ViewClassTestComponent } from './view-class-test/view-class-test.component';
import { EditorComponent } from './editor/editor.component';
import { TestimonialsComponent } from './list-testimonials/testimonials.component';
import { ClassContentsComponent } from './class-contents/class-contents.component';
import { UserContentsComponent } from './user-contents/user-contents.component';
import { fetchotpComponent } from './fetch-otp/fetch-otp.component';
import { ViewDetailsComponent } from './view-details/view-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: HeaderComponent,
    children: [
      // { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'schedule-class',
        component: ScheduleOnlineClassComponent,
      },
      {
        path: 'view-details',
        component: ViewDetailsComponent,
      },
      {
        path: 'add-product',
        component: AddProductComponent,
      },
      {
        path: 'list-customers',
        component: ListCustomerComponent,
      },
      {
        path: 'list-payment',
        component: ListPaymentComponent,
      },
      {
        path: 'add-class',
        component: AddClassComponent,
      },
      {
        path: 'list-onlineclass',
        component: ListOnlineclassComponent,
      },
      {
        path: 'list-classes',
        component: ListClassesComponent,
      },
      {
        path: 'list-meditations',
        component: ListMeditationsComponent,
      },
      {
        path: 'add-onlineclass',
        component: AddOnlineclassComponent,
      },
      {
        path: 'add-meditation',
        component: AddMeditationComponent,
      },
      {
        path: 'add-estore',
        component: AddEstoreComponent,
      },
      {
        path: 'list-estore',
        component: ListEstoreComponent,
      },
      {
        path: 'view-classes',
        component: ViewClassTestComponent,
      },
{
        path: 'guest-users',
        component: GuestUsersComponent,
      },
      {
        path: 'registration',
        component: RegistrationComponent
      },
      {
        path:'editor',
        component:EditorComponent
      },
      {
        path:'testimonials',
        component:TestimonialsComponent
      },
      {
        path:'class-contents',
        component:ClassContentsComponent
      },
      {
        path:'user-contents',
        component:UserContentsComponent
      },
      {
        path:'fetch-otp',
        component:fetchotpComponent
      }
      
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'login-update', component: LoginUpdateComponent },
  { path: 'otp', component: OTPComponent },
  // {
  //   path:"**",
  //   component: NotFound404Component
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
