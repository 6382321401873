import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { MatSort } from '@angular/material/sort';

import {MatTableDataSource} from '@angular/material/table';
import { DataService } from '../data.service';
export interface PeriodicElement {
  
  paymentThrough: string;
  bankRefNo: string;
  txnStatus: string;
  amount: string;
  // date: string;
  txnDate: string;

 
}
@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  resultsLength = 0;
  pageSize = 20;
  displayedColumns: string[] = [ 'remarks','paymentThrough', 'bankRefNo', 'txnStatus', 'amount', 'txnDate'];
  dataArray = [];
  exp: any;
  var_readonly: any;
  var_value: any;
  search = "";
  dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
  dialogData = {};
  @ViewChild(MatSort) sort: MatSort;
  constructor(public dataService: DataService,
    public dialogRef: MatDialogRef<PaymentHistoryComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.dataService.getTransactionDetails(this.dialogData).subscribe((response: any) => {
      console.log(response['data'])
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        console.log(this.resultsLength)
        this.bind_values(response['data']);
      }
      else
      {
        this.dataSource = new MatTableDataSource<PeriodicElement>([]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }, (error) => {
      console.log('error is ', error)
    })
  }
  bind_values(dataArr)
  {
    console.log(dataArr,'dataArr')
    this.dataArray=[];
    for(var i=0;i<dataArr.length;i++)
    {
      var datajson = {
        "remarks":dataArr[i].remarks,
        'paymentThrough':dataArr[i].paymentThrough,
        'txnDate':dataArr[i].txnDate,
        'txnStatus':dataArr[i].txnStatus,
        'amount':dataArr[i].amount,
        'bankRefNo':dataArr[i].bankRefNo,
      }
      this.dataArray.push(datajson);
    }
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    console.log(event);
    return event;
  }
  closeDialog() {
    this.dialogRef.close(this.form.value);
  }

}
