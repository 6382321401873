import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'formly-field-custom-datepicker',
  template: `<div class="slct-hrs">
  <!--
  <ejs-datepicker  [formControl]="formControl" [readonly]='to.readonly' [allowEdit]='false'
  [placeholder]="to.placeholder"></ejs-datepicker></div>
  -->
  <mat-form-field appearance="outline" fxFlex="100%" class="margin-space" [class]="field.templateOptions?.class">
  <input matInput [matDatepicker]="picker" placeholder="Select" [formControl]="formControl" [readonly]='to.readonly' >
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
  `,
})
export class FormlyFieldCustomDatePicker extends FieldType {
}