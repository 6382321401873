import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'formly-field-custom-timepicker',
  template: `<div class="slct-hrs">
  <!--  
  <ejs-timepicker  [formControl]="formControl" [readonly]='to.readonly' [value]='to.value' [step]='interval' [format]='customFormat' [allowEdit]='false'
  [placeholder]="to.placeholder" ></ejs-timepicker> </div>
-->
<mat-form-field appearance="outline" fxFlex="100%" class="margin-space" [class]="field.templateOptions?.class">
    <input matInput
           name="selected_time_A"
           [ngxMatTimepicker]="pickerA"
           [formControl]="formControl" [readonly]='to.readonly' [value]='to.value'
           [placeholder]="to.placeholder"
            />
    <mat-icon matSuffix
              (click)="pickerA.open()">
        watch_later
    </mat-icon>
</mat-form-field>
<ngx-mat-timepicker #pickerA></ngx-mat-timepicker>
  `,
})

export class FormlyFieldCustomTimePicker extends FieldType {
  public value: Date = new Date();
  public interval: number = 1;
  public customFormat : string = 'YYYY-MM-DD HH:mm:ss';

}