import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
 
@Component({
  selector: "formly-field-custom-toggle",
  template: ` <span (click)="tg.toggle()"
  [class.mat-disabled]="tg.disabled"
  class="mat-slide-toggle-left mat-slide-toggle"
>
{{to.label}}
</span>

<mat-slide-toggle  [formControl]="formControl"  (change)="to.change($event)" #tg [disabled]="isDisabled"></mat-slide-toggle>`


})
export class FormlyFieldCustomToggle  extends FieldType {
  isDisabled: boolean;
}