import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../global.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  showMainMenu = true;
  notificationcol = false;
  settingcol = false;
  resSidebar = true;
  colorMode = 'dark';
  menuNavigation = 'vertical';
  currentTime = moment().format('h:mm:s A');
  currentDate = moment().format('ddd, D MMM YY');
  timer: any;

  constructor(private globalService: GlobalService, private router: Router) { 
    console.log(localStorage.getItem('LoggedAsCustomer'))
    if(localStorage.getItem('LoggedAsCustomer') != "1"){
      this.router.navigate(["login"]);
    }
  }

  ngOnInit(): void {
    this.timer = setInterval(() => {
      this.currentTime = moment().format('h:mm:ss A');
      this.currentDate = moment().format('ddd, D MMM YY');
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  opensetting() {
    this.notificationcol = false;
    if (this.settingcol) {
      this.settingcol = false;
    } else {
      this.settingcol = true;
    }
  }

  closesetting() {
    this.settingcol = false;
  }

  opennotification() {
    this.settingcol = false;
    if (this.notificationcol) {
      this.notificationcol = false;
    } else {
      this.notificationcol = true;
    }
  }

  clearnotification() {
    this.notificationcol = false;
  }

  showHideMenu(): void {
    if (this.showMainMenu) {
      this.showMainMenu = false;
      this.resSidebar = false;
    } else {
      this.showMainMenu = true;
      this.resSidebar = true;
    }
    this.globalService.publishSomeData({
      showMainMenu: this.showMainMenu,
    });
    // console.log('showHideMenu: ', this.showMainMenu);
  }

  changeColorMode(colorMode): void {
    // console.log('colorMode: ', colorMode);
    this.colorMode = colorMode;

    this.globalService.publishSomeData({
      colorMode: this.colorMode,
    });
  }

  changeNavigation(navigation): void {
    // console.log('navigation: ', navigation);
    this.menuNavigation = navigation;

    this.globalService.publishSomeData({
      menuNavigation: this.menuNavigation,
    });
  }

  changeColor(color): void {
    // console.log('color: ', color);
    this.globalService.publishSomeData({
      currentColor: color,
    });
  }
  palettecolor1:any = "#161a21";
  palettecolor2:any = "#111419";
  palettecolor3:any = "#13ECA4";
  palettecolor4:any = "#f3f6f9";
  palettecolor5:any = "#63667B";
  r = document.querySelector(':root');
  codeSearch(key,value){
    // Get the root element
      this.r['style'].setProperty(key, value);
  }

  changeTheme(theme) {
    this.globalService.publishSomeData({
      currentTheme: theme,
    });
  }

  changeFont(event) {
    const font = event.target.value;
    // console.log('font: ', font);
    this.globalService.publishSomeData({
      currentFont: font,
    });
  }

}
