<div>
  <div id="myModal1" class="modal-pop">

    <!-- Modal content -->
    <div class="modal-content-pop" style="height: 100%;">
      <div class="outer">
        <div class="middle">
          <div class="inner" style="margin-top: 390px;margin-bottom: 100px;">
            <div style="background-color: #520f77 ;border-radius: 20px;margin-top: 25px;">
              <input type="text" placeholder="Search.." [(ngModel)]="searchCode" (ngModelChange)="codeSearch()" style="width: 400px;
              margin: 10px;
              border: 1px solid #a74dd9;
              border-radius: 25px;
              font-size: 22px;
              padding: 5px 5px;
              margin-bottom: 0px;">
              <ul class="dropdown" style="height: 175px;overflow: auto;">
                <li (click)="selectCountry(item)" class="cCodeHover" style="overflow: auto;margin: 10px;cursor: pointer;margin: 10px;" *ngFor="let item of countryData; let i=index">
                <table>
                  <tr>
                    <td><img width="25" src="{{item.countryImageUrl}}" style="margin: 5px;"></td>
                    <td style="width: 80px;"><span style="color: white;"> {{item.countryCode}} </span></td>
                    <td><span style="color: white;"> {{item.countryName}}</span></td>
                  </tr>
                </table>
                 </li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <app-progress *ngIf="progressFlag"></app-progress>

  
<div class="main_login">
  <div class="main_login_bx" style="background-color: white;">
    <div class="login_logo">
      <img src="../../assets/images/login-logo.png" style="cursor: pointer;" (click)="goPage('login')">
      <h3>LOGIN</h3>
    </div>
    <div class="radio">
      <table style="width: 100%;height: 50px;" align="center">
        <tr align="center">
          <td>
            
              <img *ngIf="mobileFlag" (click)="mradioClick(false)" style="width: 20px;float: left;cursor: pointer;margin-left: 55px;" src="../../../assets/images/checked.png">
              <img *ngIf="!mobileFlag" (click)="mradioClick(true)" style="width: 20px;float: left;cursor: pointer;margin-left: 55px;" src="../../../assets/images/unchecked.png">
              <span style="font-size: 18px;;float: left;color: #886be4;margin-left: 2px;">
              Mobile
            </span>
          </td>
          <td>
              <img *ngIf="!mobileFlag" (click)="mradioClick(true)" style="width: 20px;float: left;cursor: pointer;" src="../../../assets/images/checked.png">
              <img *ngIf="mobileFlag" (click)="mradioClick(false)" style="width: 20px;float: left;cursor: pointer;" src="../../../assets/images/unchecked.png">
              <span style="font-size: 18px;float: left;color: #886be4;margin-left: 2px;">
              Email
            </span>
          </td>
      </tr>
      </table>
      </div>
      <br>
      <br>
    <div class="main_login_form">
      
      <ul>
        
        <li *ngIf="mobileFlag">
          <div>
       <input style="width: 25%;
          margin: auto;
          border: 1px solid #a74dd9;
          border-radius: 6px;
          font-size: 22px;
          padding: 20px 12px;
          margin-bottom: 0px;" placeholder="+" autocomplete="off" (click)="myModel()"  maxlength="15" type="text" [(ngModel)]="countryCode" name="code" >
      
          <input placeholder="Mobile Number" maxlength="15" class="text-color" style="width: 70%;margin-left: 3px;margin-bottom: 0px;font-size: 22px;" type="text" name="mobileNumber" [(ngModel)]="mobileNumber" (ngModelChange)="validation();">
          <h5 style="margin-bottom: 25px;text-align: start;margin-left: 3px;color: red;margin-top: 3px;">
            {{mobileError}}
          </h5>
        </div>
        </li>
       
        <li *ngIf="!mobileFlag">
          <span>E-Mail Address*</span>
          <input maxlength="40" class="text-color" style="margin-bottom: 0px;font-size: 22px;" type="email" name="emailAddress" [(ngModel)]="emailAddress"  (ngModelChange)="validation()">
          <h5 style="margin-bottom: 25px;text-align: start;margin-left: 3px;color: red;margin-top: 3px;">
            {{emailError}}
          </h5>
        </li>
        <li *ngIf="!mobileFlag">
          <span>Password*</span>
          <input maxlength="40" class="text-color" style="margin-bottom: 0px;font-size: 22px;" type="password" name="password" [(ngModel)]="password"  (ngModelChange)="validation()"> 
          <h5 style="margin-bottom: 25px;text-align: start;margin-left: 3px;color: red;margin-top: 3px;">
            {{passwordError}}
          </h5>
        </li>
        <li>
          <input type="submit" value="LOGIN" (click)="loginSubmit()" style="cursor: pointer;">
        </li>
      </ul>	
    </div>	
  </div>
  <div style="height: 250px;">
  </div>
</div>

</div>