import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
//CR2402001	Updated Datas, in Product Type column is not getting reflected properly in the Front screen
@Component({
  selector: 'formly-field-custom-select',
  template: `
    <mat-form-field [class]="field.templateOptions?.class" appearance="outline" fxFlex="100" class="margin-space">
  <mat-label>{{ to.label }}</mat-label>
  <mat-select [formControl]="formControl" [disabled]="to.disabled" [placeholder]="to.placeholder" (selectionChange)="to.valueChange(field, $event.value)">
    <mat-option *ngFor="let option of to.options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
  <div class="error_msg" *ngIf="showError">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
</mat-form-field>

  `,
})
export class FormlyFieldCustomSelect extends FieldType {
}
