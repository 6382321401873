<div class="container-fluid">
    <div fxLayout="column" fxFlex="100%" class="">
      <div class="top-col">
        <h5 class="head-text color-green">User Details</h5>
      </div>


      <form [formGroup]="searchForm" (ngSubmit)="onSearchClicked()">
        <div fxLayout="row" fxFlex="100%">
            <div fxLayout="row">
                <div fxLayout="row" class="country-code-input">
                  <mat-form-field class="country-code-input">
                    <mat-label>Country Code</mat-label>
                    <mat-select formControlName="countryCode" (selectionChange)="selectCountry($event)">
                      <mat-option>
                        <input matInput placeholder="Search" (input)="applyFilter($event.target.value)" (click)="$event.stopPropagation()">
                      </mat-option>
                      <mat-option *ngFor="let country of filteredCountries" [value]="country.countryCode">
                        <img width="25" [src]="country.countryImageUrl">
                        {{ country.countryCode }} - {{ country.countryName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="row" class="phone-number-input">
                    <mat-form-field>
                        <input matInput placeholder="Phone Number" formControlName="phoneNumber">
                    </mat-form-field>
                </div>
                <div class="search-button">
                    <button mat-raised-button color="primary" type="submit">Search</button>
                </div>
            </div>
        </div>
    </form>

    </div>
  
    <mat-tab-group (selectedTabChange)="tabChanged($event)"  *ngIf="searchClicked">
      <mat-tab label="Classes">
        <div fxLayout="row" *ngIf="searchClicked">
          <table fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created"
            matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">
            <ng-container matColumnDef="CustomerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
              <td mat-cell *matCellDef="let element">{{ element.CustomerName }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Track Id </th>
              <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Name </th>
              <td mat-cell *matCellDef="let element">{{ element.ClassName }}</td>
            </ng-container>
            <ng-container matColumnDef="Year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
              <td mat-cell *matCellDef="let element">{{ element.Year }}</td>
            </ng-container>
            <ng-container matColumnDef="DateOfPurchase">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Purchase </th>
              <td mat-cell *matCellDef="let element">{{ element.DateOfPurchase ? (element.DateOfPurchase | date: 'dd/MM/yyyy hh:mm a') : 'Not Available' }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-cell">
                <div class="action-buttons">
                  <button mat-icon-button matTooltip="Transfer" (click)="transferDetails(element)">
                    <mat-icon>compare_arrows</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Disable" (click)="disable(element)">
                    <mat-icon>disabled_by_default</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="View" (click)="openMeditationDialog(element)">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="[ 'CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
          </table>
  
          <div *ngIf="dataSource.length === 0">No data available.</div>
        </div>
      </mat-tab>
      <mat-tab label="Meditation">
        <div fxLayout="row" *ngIf="searchClicked">
          <table fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created"
            matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">
            <ng-container matColumnDef="CustomerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
              <td mat-cell *matCellDef="let element">{{ element.CustomerName }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Track Id </th>
              <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Name </th>
              <td mat-cell *matCellDef="let element">{{ element.ClassName }}</td>
            </ng-container>
            <ng-container matColumnDef="Year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
              <td mat-cell *matCellDef="let element">{{ element.Year }}</td>
            </ng-container>
            <ng-container matColumnDef="DateOfPurchase">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Purchase </th>
              <td mat-cell *matCellDef="let element">{{ element.DateOfPurchase ? (element.DateOfPurchase | date: 'dd/MM/yyyy hh:mm a') : 'Not Available' }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-cell">
                <div class="action-buttons">
                  <button mat-icon-button matTooltip="Transfer" (click)="transferDetails(element)">
                    <mat-icon>compare_arrows</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Disable" (click)="disable(element)">
                    <mat-icon>disabled_by_default</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="View" (click)="openMeditationDialog(element)">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="[ 'CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
          </table>
  
          <div *ngIf="dataSource.length === 0">No data available.</div>
        </div>
      </mat-tab>
      <mat-tab label="E-store">
        <div fxLayout="row"*ngIf="searchClicked">
          <table fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created"
            matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">
            <ng-container matColumnDef="CustomerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
              <td mat-cell *matCellDef="let element">{{ element.CustomerName }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Track Id </th>
              <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Name </th>
              <td mat-cell *matCellDef="let element">{{ element.ClassName }}</td>
            </ng-container>
            <ng-container matColumnDef="Year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
              <td mat-cell *matCellDef="let element">{{ element.Year }}</td>
            </ng-container>
            <ng-container matColumnDef="DateOfPurchase">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Purchase </th>
              <td mat-cell *matCellDef="let element">{{ element.DateOfPurchase ? (element.DateOfPurchase | date: 'dd/MM/yyyy hh:mm a') : 'Not Available' }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-cell">
                <div class="action-buttons">
                  <button mat-icon-button matTooltip="Transfer" (click)="transferDetails(element)">
                    <mat-icon>compare_arrows</mat-icon>
                  </button>
                  <!-- <button mat-icon-button matTooltip="Disable" (click)="disable(element)">
                    <mat-icon>disabled_by_default</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="View" (click)="openMeditationDialog(element)">
                    <mat-icon>visibility</mat-icon>
                  </button> -->
                </div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="[ 'CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
          </table>
  
          <div *ngIf="dataSource.length === 0">No data available.</div>
        </div>
      </mat-tab>
      <mat-tab label="Counseling">
        <div fxLayout="row" *ngIf="searchClicked">
          <table fxFlex="100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created"
            matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">
            <ng-container matColumnDef="CustomerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
              <td mat-cell *matCellDef="let element">{{ element.CustomerName }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Track Id </th>
              <td mat-cell *matCellDef="let element">{{ element.Id }}</td>
            </ng-container>
            <ng-container matColumnDef="ClassName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Class Name </th>
              <td mat-cell *matCellDef="let element">{{ element.ClassName }}</td>
            </ng-container>
            <ng-container matColumnDef="Year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Language </th>
              <td mat-cell *matCellDef="let element">{{ element.Year }}</td>
            </ng-container>
            <ng-container matColumnDef="DateOfPurchase">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Purchase </th>
              <td mat-cell *matCellDef="let element">{{ element.DateOfPurchase ? (element.DateOfPurchase | date: 'dd/MM/yyyy hh:mm a') : 'Not Available' }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element" class="action-cell">
                <div class="action-buttons">
                  <!-- <button mat-icon-button matTooltip="Transfer" (click)="transferDetails(element)">
                    <mat-icon>compare_arrows</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Disable" (click)="disable(element)">
                    <mat-icon>disabled_by_default</mat-icon>
                  </button> -->
                  <!-- <button mat-icon-button matTooltip="View" (click)="openMeditationDialog(element)">
                    <mat-icon>visibility</mat-icon>
                  </button> -->
                </div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="[ 'CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['CustomerName', 'ClassId', 'ClassName', 'DateOfPurchase', 'Year', 'action']"></tr>
          </table>
  
          <div *ngIf="dataSource.length === 0">No data available.</div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  
