import { Component, OnInit, Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component'
import { pairwise, startWith, map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Router } from '@angular/router';
const moment = _rollupMoment || _moment;
// datepicker configuration
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },

};


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};

  starttime = '0.00';
  endtime = '23.59';
  save_text = "Save";
  allCategoryArray = [{
    'value': 'catgeory1', 'label': "Category1"
  }, {
    'value': 'catgeory2', 'label': "Category2"
  }];
  allLanguageArray = [{
    'value': 'language1', 'label': "Language1"
  }, {
    'value': 'language2', 'label': "Language2"
  }];
  allProductArray = [{
    'value': 'product1', 'label': "Product1"
  }, {
    'value': 'product2', 'label': "Product1"
  }];
  constructor(private router: Router, public rootService: DataService, private snackBar: MatSnackBar, private formBuilder: FormBuilder, private dialog: MatDialog,) {

  }

  ngOnInit(): void {

    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',
        },
        fieldGroup: [
          {
            key: 'category',
            type: 'inno-select',
            templateOptions: {
              placeholder: "Choose Category",
              label: "Choose Category",
              fxFlex: "32%",
              fxLayout: 'column',
              fxFlexOrder: 1,
              options: this.allCategoryArray,
              valueChange: (field, value) => {

              },
            },
          },
          {
            key: 'language',
            type: 'inno-select',

            templateOptions: {
              placeholder: "Choose Language",
              label: "Choose Language",
              fxFlex: "32%",
              fxLayout: 'column',
              fxFlexOrder: 2,
              options: this.allLanguageArray,
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },
            },
          },
          {
            key: 'product',
            type: 'inno-input',

            templateOptions: {
              placeholder: "Enter Name",
              label: "Enter Name",
              fxFlex: "32%",
              fxLayout: 'column',
              fxFlexOrder: 4,
             
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },
            },
          },
          {
            key: 'product',
            type: 'inno-textarea',

            templateOptions: {
              placeholder: "Enter Description",
              label: "Enter Description",
              fxFlex: "32%",
              fxLayout: 'column',
              fxFlexOrder: 5,
             
              valueChange: (field, value) => {
                // console.log(value,'value')
                //  this.bind_values(field)
              },
            },
          },
        ],
      },
    ];

  }

}
