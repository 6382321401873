import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { map, startWith, tap } from 'rxjs/operators';
import { DataService } from '../data.service';
import { MatSort } from '@angular/material/sort';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
export interface PeriodicElement {
  product_id: string;
  product_type: string;
  name: string;
  product_url: string;




}
@Component({
  selector: 'app-enable-meditation',
  templateUrl: './enable-meditation.component.html',
  styleUrls: ['./enable-meditation.component.css']
})

export class EnableMeditationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  fieldgroup = [];
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  resultsLength = 0;
  pageSize = 20;
  productValues = [];
  productDetails = [];
  dataArray = [];
  dialogData: any = {}
  isDisabled = false;
  dataSource = new MatTableDataSource<PeriodicElement>([]);
  displayedColumns: string[] = ['select', 'product_id', 'product_type', 'name', 'product_url'];
  product_type=[];

  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort) sort: MatSort;
  constructor(public dataService: DataService,
    public dialogRef: MatDialogRef<EnableMeditationComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogData = data;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  public getPaginatorData(event: PageEvent): PageEvent {
    return event;
  }
  ngOnInit(): void {
    console.log(this.dialogData)
   
    if(this.dialogData['source'] === "payment")
    {
      this.isDisabled = true
    }
    else{
      this.isDisabled = false
    }
    var form_elements = [
      {
        key: 'category',
        type: 'inno-select',
        label: 'Choose Category',
        childkey: '',
        hideExpression: '',
        fxLayout: 'row',
        fxFlex: '30%',
        fxFlexOrder: 1,
        required:true,
        defaultValue:this.dialogData['category'],
        options: [
          { value: 'Classes', label: 'Classes' },
          { value: 'Online Class', label: 'Online Class' },
          { value: 'Meditation', label: 'Meditation' },
          { value: 'E-Store', label: 'E-Store' },

        ]
      },
      {
        key: 'language',
        type: 'inno-select',
        label: 'Choose Language',
        childkey: '',
        hideExpression: '',
        fxLayout: 'row',
        fxFlexOrder: 2,
        fxFlex: '30%',
        required:true,
        defaultValue:this.dialogData['sub_category'],
        options: [
          { value: 'English', label: 'English' },
          { value: 'Tamil', label: 'Tamil' },
        ]

      },
      {
        key: 'product',
        type: 'inno-select',
        label: 'Choose Product',
        childkey: '',
        hideExpression: '',
        fxLayout: 'row',
        fxFlex: '30%',
        fxFlexOrder: 3,
        defaultValue:this.dialogData['product_id'],
        options: [],
        required:false

      },
      {
        key: 'plan',
        type: 'inno-select',
        label: 'Choose Plan',
        childkey: '',
        hideExpression:'!model.category || model.category !== "Meditation"',
        fxLayout: 'row',
        fxFlex: '30%',
        fxFlexOrder: 3,
        defaultValue:'Monthly',
        options: [
          { value: '1 Month', label: '1 Month' },
          { value: '6 Month', label: '6 Month' },
          { value: '1 Year', label: '1 Year' },
        ],
        required:false

      },


    ]
    this.fieldgroup = [];
    // this.product_type = this.product_type.reverse();
    
    for (var i = 0; i < form_elements.length; i++) {

      var field_json =
      {
        key: form_elements[i].key,
        type: form_elements[i].type,
        class: "element-width",
        hideExpression: form_elements[i].hideExpression,
        // wrappers: ['inno-modal'],
        templateOptions: {
          label: form_elements[i].label,
          // wrappertext: form_elements[i].wrappertext,
          // placeholder: form_elements[i].placeholder,
          description: form_elements[i].label,
          childkey: form_elements[i].childkey,
          fxFlex: form_elements[i].fxFlex,
          fxFlexOrder: form_elements[i].fxFlexOrder,
          fxLayout: 'row',
          required: form_elements[i].required,
           disabled:this.isDisabled,
          options: form_elements[i].options,
          defaultSelected: form_elements[i].defaultValue,
          valueChange: (field, $event) => {
            this.selection.clear();
            if (field.key === 'product') {
              this.getProductValues($event);

            }
            else {
              this.dataSource = new MatTableDataSource<PeriodicElement>([]);
            }
            //  this.change_values(field, $event)
          },
        },

        hooks: {


          onInit: (field) => {


            if (field.key === 'product') {
              var catValues = field.form.get('category').value;
              var lanvalues = field.form.get('language').value;
              // field.templateOptions.options = [];
              field.form
                .get('category')
                .valueChanges.pipe(
                   startWith(catValues),
                  map(category => this.bindClasses(category, 'category')), tap(() => {
                    field.formControl.setValue([])
                  }),

                ).subscribe(res => {

                  console.log(res, 'res');
                  field.templateOptions.options = this.productValues;
                  
                });

              field.form
                .get('language')
                .valueChanges.pipe(
                   startWith(lanvalues),
                  map(language => this.bindClasses(language, 'language')), tap(() => {
                    field.formControl.setValue([])
                  }),

                ).subscribe(res => {
                  console.log(res, 'res1');
                  field.templateOptions.options = this.productValues;
                  console.log(this.productValues,'pvalues')
                
                });
            }








          }

        }
      };
    
      this.fieldgroup.push(field_json);
    }
    this.formFields = [
      {
        type: 'flex-layout-formly',
        templateOptions: {
          fxLayout: 'column',

        },
        fieldGroup: this.fieldgroup
      },

    ];
    

  }
  closeDialog() {
    this.dialogRef.close(this.form.value);
  }
  //Get the particular product values
  getProductValues(productId) {
    this.product_type  = [];
    if (this.productDetails.length > 0) {
      var product_details = this.productDetails.filter(e => e.product_id == productId);
     this.product_type = product_details[0]['list'];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.product_type );

    }



  }
  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }
  generateHash(hash_value) {
    var hashedValue = this.sha512(hash_value).then(x => {
      return x;
    });
    return hashedValue;
    // crypto.createHash('sha512').update('my string for hashing').digest('hex');
    // return crypto.subtle.digest("SHA-512", new TextEncoder().encode(hash_value)).then(buf => {
    //   return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    // });
  }
  sha512(str) {
    return crypto.subtle.digest("SHA-512", new TextEncoder().encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    });
  }
  async bindClasses(category, changetype) {
    this.productValues = [];
    var lan = '';
    var cat = '';
    if (changetype == 'language') {
      lan = category;
    }
    else {
      lan = this.model.language;
    }
    if (changetype == 'category') {
      cat = category;
    }
    else {
      cat = this.model.category;
    }
    if(this.model.category === "Meditation")
    {
      var postObject1 = {};
      postObject1["category"] = 'Meditation';
      postObject1["type"] = 'subscription';
      postObject1["hash_value"] = await this.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);
  
      // postObject1["hash_value"] = '9d08b287ceed3b48133342aaec4dec97668be08e05855a3b708210a2e2f6fc2a0301ae2c9a42ca0e99822a7b1279559ee9aa68962e818247d090a17a9145a656'
      var productArray = [];
      this.productDetails =[];
      this.dataService.getSubscription(postObject1).subscribe((response: any) => {
  
        this.resultsLength = response['data'].length;
        if (this.resultsLength > 0) {
          var tempDataArray = response['data'];
          for (var i = 0; i < tempDataArray.length; i++) {
         
              if (tempDataArray[i].language == lan) {
                // console.log(tempDataArray[i].products[0].product_master_id.list[0].list)
                var products = tempDataArray[i].products[0].product_master_id.list[0].list;
                for(var j=0;j<products.length;j++)
                {
                  this.productDetails.push(products[j]);
                  var productJson = { label: products[j].name, value: products[j].product_id };
                  this.productValues.push(productJson)
                }
           
              }
  
  
            
          
          }
  // console.log( response['data'] ,'data')
         
        }
        if(this.dialogData['source'] === "payment")
          this.getProductValues(this.dialogData['product_id']);
  
      }, (error) => {
        console.log('error is ', error)
      })
  

    }
    else{
      var postObject1 = {};
      postObject1["category"] = cat;
      postObject1["type"] = 'master';
      postObject1["hash_value"] = await this.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);
  
      // postObject1["hash_value"] = '9d08b287ceed3b48133342aaec4dec97668be08e05855a3b708210a2e2f6fc2a0301ae2c9a42ca0e99822a7b1279559ee9aa68962e818247d090a17a9145a656'
      var productArray = [];
  
      this.dataService.getClasses(postObject1).subscribe((response: any) => {
  
        this.resultsLength = response['data'].length;
        if (this.resultsLength > 0) {
          var tempDataArray = response['data'][0]['list'];
          for (var i = 0; i < tempDataArray.length; i++) {
            var language_wise_classes = tempDataArray[i]['list'];
            for (var j = 0; j < language_wise_classes.length; j++) {
  
              if (language_wise_classes[j].language == lan) {
                this.productDetails.push(language_wise_classes[j]);
                var productJson = { label: language_wise_classes[j].name, value: language_wise_classes[j].product_id };
                this.productValues.push(productJson)
                this.productValues = this.productValues.reverse();
              console.log(this.productValues)
              }
  
  
            }
            // console.log(tempDataArray[i]['list']);
            // this.dataArray.push(tempDataArray[i]['list']);
          }
  
          //  this.bind_values(this.dataArray);
        }
        if(this.dialogData['source'] === "payment")
          this.getProductValues(this.dialogData['product_id']);
  
      }, (error) => {
        console.log('error is ', error)
      })
  
    }
   
  
  }
  async enableMeditation() {

    console.log(this.model.product, 'this.model.product');

    if(this.model.product !== null && this.model.product !== undefined && this.model.product.length > 0)
    { 
      
    var postObject1 = {};
    postObject1["category"] = this.model.category;
    postObject1["type"] = 'master';
    postObject1['product_id'] = this.model.product;
    postObject1["hash_value"] = await this.dataService.generateHash("ALPHA" + "|" + postObject1["category"] + "|" + postObject1["type"]);    
      this.dataService.getClasses(postObject1).subscribe((response: any) => {

      var resultsLength = response['data'].length;
      if (resultsLength > 0) {
        var classObj = response['data'][0];
        if (this.selection['_selected'] !== null && this.selection['_selected'] !== undefined)
          classObj['list'] = this.selection['_selected'];
        else
          classObj['list'] = [];
      }

      if(this.model.category == "Classes") {
        classObj["category"] = "Online Class"; 
      } 
      console.log(classObj, 'classObj');
      var postObj = {
        product: classObj,
        user: this.dialogData['source'] !== "payment" ? [this.dialogData.id] : [],
        header: this.dialogData['source'] === "payment" ? [this.dialogData.id] : []
      }
      console.log(postObj);
      console.log(this.dialogData['source'])
      if (this.dialogData['source'] === "payment") {
        this.dataService.enablePaymentProduct(postObj).subscribe((response: any) => {
          this.dataService.showSuccess("Saved successfully","");
          this.closeDialog();
        });
      } else {
        this.dataService.enableCustomerProduct(postObj).subscribe((response: any) => {
          this.dataService.showSuccess("Saved successfully","");
          this.closeDialog();
        });
      }

    }, (error) => {
      console.log('error is ', error)
    })

    }
    else{
      console.log(this.model.plan)
      console.log(this.productDetails,'productdetails')
      var postObj = {
        product: this.productDetails,
        plan:this.model.plan,
        category:this.model.category,
        language:this.model.language,
        user: this.dialogData['source'] !== "payment" ? [this.dialogData.id] : [],
        header: this.dialogData['source'] === "payment" ? [this.dialogData.id] : []
      }
      if (this.dialogData['source'] === "payment") {
        this.dataService.enableMeditation(postObj).subscribe((response: any) => {
          this.dataService.showSuccess("Saved successfully","");
          this.closeDialog();
        });
      } else {
        this.dataService.enableMeditation(postObj).subscribe((response: any) => {
          this.dataService.showSuccess("Saved successfully","");
          this.closeDialog();
        });
      }

    }

    console.log(this.dialogData.id);
    // console.log("this.selection");
    // console.log(this.selection);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
