import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-file',
  template: `
  <label style="
  color: blue;">Logo</label> <br>
  <input type="file"
  
  [formControl]="formControl"
  [formlyAttributes]="field"
  [multiple]="to.multiple"
  (change)="fileChanged($event)">
  `,
})

export class FormlyFieldCustomFile extends FieldType {
  fileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          console.log(event.target.result);
          debugger;
          console.log(this.formControl);
          console.log(this.field.templateOptions.nextFields);
          // this.formControl.patchValue(event.target.result)
        }
        reader.readAsDataURL(event.target.files[i]);
      }

      //reader.readAsText(event.target.files[0]);
    }
  }
}