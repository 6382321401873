<h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  <div mat-dialog-actions style="justify-content: flex-end;">
    <button mat-raised-button color="accent" (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" class="confirmok" (click)="onConfirm()">Ok</button>
  </div>