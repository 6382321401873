import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// material component
import {
  MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component'
import { pairwise, startWith, map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
//import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { GlobalService } from '../global.service';
import { OrderHistoryComponent } from '../order-history/order-history.component';
import { PaymentHistoryComponent } from '../payment-history/payment-history.component';
import { EnableMeditationComponent } from '../enable-meditation/enable-meditation.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

export interface PeriodicElement {
  id:string,
  user_name: string;
  email_id: string;
  mobile_number: string;
  adress_line_1: string;
  // date: string;
  city: string;
  state: string;
  country: string;
  creation_timestamp: Date;
  action: any;
 
  
}
@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.css']
})
export class ListCustomerComponent implements OnInit {
  showMainMenu: any;
  colorMode = 'dark';
  currentColor = 'bgcolor-black';
  menuNavigation = 'vertical'

  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
      - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
      ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  public lastEnd: Date = this.today;
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  public yearEnd: Date = this.today;

  fieldgroup = [];
  daysfieldgroup = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  options: FormlyFormOptions = {};
  form = new FormGroup({});
  formModel: any = {}
  formFields: Array<FormlyFieldConfig>
  model: any = {};
  pageSize=10;
  resultsLength=0;
  dataArray=[];
  var_readonly: any;
  var_value: any;
  search="";
  orderData = {};
  paymentData = {};
  meditationValue = {};
  dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
  productValues=[];
  productDetails=[];
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [ 'first_name', 'email_id','mobile_number','adress_line_1','city','state','country','creation_timestamp','action'];
constructor(public dataService: DataService, 
  private dialog: MatDialog,private router: Router, private globalService: GlobalService, private _formBuilder: FormBuilder, public rootService: DataService, private snackBar: MatSnackBar
  ) {
    this.globalService.getObservable().subscribe((data) => {
      // console.log('globalService Data received: ', data);
      if (data.showMainMenu === true || data.showMainMenu === false) {
        this.showMainMenu = data.showMainMenu;
        // console.log('this.showMainMenu: ', this.showMainMenu);/
      } else if (data.colorMode) {
        this.colorMode = data.colorMode;
        // console.log('this.colorMode: ', this.colorMode);
      } else if (data.currentColor) {
        this.currentColor = data.currentColor;
        // console.log('this.currentColor: ', this.currentColor);
      } else if (data.menuNavigation) {
        this.menuNavigation = data.menuNavigation;
        // console.log('this.menuNavigation: ', this.menuNavigation);
      }
    });
  }


  ngOnInit(): void {

  
    var form_elements = [
      {

        key: 'from_date',
        type: 'inno-daterangepicker',
        label: 'Select Date',
        childkey: '',
        placeholder: 'Select Date',
        hideExpression: '',
        fxFlex: '30%',
        wrappertext: "Period",

      },
    ];
    this.formFields = [];

    for (var i = 0; i < form_elements.length; i++) {
   //   this.exp = this.bind_elements(form_elements[i].key)

      var field_json =
      {
        key: form_elements[i].key,
        type: form_elements[i].type,
     
        class: "element-width",

        // wrappers: ['inno-modal'],
        templateOptions: {
          label: form_elements[i].label,
          wrappertext: form_elements[i].wrappertext,
          placeholder: form_elements[i].placeholder,
          description: form_elements[i].label,
          childkey: form_elements[i].childkey,
          fxFlex: form_elements[i].fxFlex,
          fxLayout: 'row',
          required: true,
         
        },
      };

      this.formFields.push(field_json);
    }
    this.bindCustomers();
  }
  async bindCustomers()
  {

    
   
    // var category="online class"
    // var status="failure"
    // var product="alpha meditation"
    // var date1="2018-02-14"
    // var date2="2018-09-06"

    
    this.dataService.getUser({}).subscribe((response: any) => {
      
      this.resultsLength = response['data'].length;
      if(this.resultsLength > 0)
      {
        var tempDataArray = response['data'];
        for(var i=0;i<tempDataArray.length;i++)
        {
         
            this.dataArray.push(tempDataArray[i])
          
          // console.log(tempDataArray[i]['list']);
          // this.dataArray.push(tempDataArray[i]['list']);
        }
      console.log(this.dataArray,'dataArray')
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //  this.bind_values(this.dataArray);
      }
    }, (error) => {
      console.log('error is ', error)
    })

   
  }

  openOrderDialog(element) {
    console.log(element,'element')
    var postObject;
    // if (this.model.orderhistory)
    // orderHistoryData = this.model.orderhistory;
    // else
    // postObject["merchantKey"] = 'ALPHA';
    // postObject["loginId"] = element.id;
    // postObject["custPhone"] =element.mobile_number;
    
   postObject = {
    "merchantKey":"ALPHA",
    "loginId":element.id,
    "custPhone":element.mobile_number
   }
  // postObject = {"merchantKey":"ALPHA","loginId":"610","custPhone":"8668171141"};
    this.dialog
      .open(OrderHistoryComponent,
        {
          width: '800px ',
          data: postObject,
        })
      .afterClosed()
      .subscribe(result => {
        this.model.orderhistory =  result.data;
        this.orderData = result.data;
      });
  }
  async openPaymentDialog(element) {
    var paymentHistoryData;
    var tDate = new Date();
    if (this.model.paymenthistory)
    paymentHistoryData = this.model.paymenthistory;
    else
    paymentHistoryData = {};
    paymentHistoryData = {
      "merchantKey":"ALPHA",
      "countryCode":element.country_code,
      "fromDate":'2000-01-01',
      "toDate":tDate.getFullYear()+"-"+(tDate.getMonth()+1)+"-"+tDate.getDate(),
      "hash":await this.dataService.generateHash(element.mobile_number +"|"+ "ALPHA"),
      "custPhone":element.mobile_number
     }
    //  paymentHistoryData =  {"merchantKey":"ALPHA","countryCode":"+91","custPhone":"8668171141","fromDate":"2000-01-01","toDate":"2021-9-17","hash":"2b366b73bd02b57ff2e1e7bf25e9e15189dd463cdfcd29b6c253f69d564a13294e9fdcc8667ea32bf7cbe2e182ade77d9e851541ccec5bf2d9771b97585f5894"}

   
   
    this.dialog
      .open(PaymentHistoryComponent,
        {
          width: '800px ',
          data: paymentHistoryData,
        })
      .afterClosed()
      .subscribe(result => {
        this.model.paymenthistory =  result.data;
        this.paymentData = result.data;
      });
  }
  openMeditationDialog(element){
    var meditationData;
    if (this.model.paymenthistory)
    meditationData = this.model.meditationdata;
    else
    meditationData = {};
   
   var customerData = {
     "id":element.id,
     "category":"",
     "sub_category":"",
     "product_id":"",
     "source":"customers"
   }
 
    this.dialog
      .open(EnableMeditationComponent,
        {
          width: 'auto ',
          height:"500px",
          data: customerData,
        })
      .afterClosed()
      .subscribe(result => {
        this.model.meditationdata =  result.data;
        this.meditationValue = result.data;
      });
  }

  resetPaging(): void {
    this.paginator.pageIndex = 0;
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    console.log(event);
    return event;
  }
  menuClassDeatails(): string {
    if (this.menuNavigation === 'horizontal') {
      return 'full-width';
    } else {
      if (this.showMainMenu === false) {
        return 'd-none2';
      } else {
        return '';
      }
    }
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
