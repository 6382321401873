<div>
  <app-progress *ngIf="progressFlag"></app-progress>

<div class="main_login">
  <div class="main_login_bx" style="background-color: white;">
    <div class="login_logo">
      <img src="../../assets/images/login-logo.png" style="cursor: pointer;" (click)="goPage('login')">
      <h3>Verification</h3>
    </div>
    <br>
          <br>
    <div class="main_login_form">
      <ul>
        <li>
          <form>
            <input #codeBox1 id="codeBox1" name="otp1" [(ngModel)]="otp1" type="number" onKeyPress="if(this.value.length==1) return false;" (ngModelChange)="modelChanged('1',$event)"/>
            <input #codeBox2 id="codeBox2" name="otp2" [(ngModel)]="otp2" type="number" onKeyPress="if(this.value.length==1) return false;" (ngModelChange)="modelChanged('2',$event)"/>
            <input #codeBox3 id="codeBox3" name="otp3" [(ngModel)]="otp3" type="number" onKeyPress="if(this.value.length==1) return false;" (ngModelChange)="modelChanged('3',$event)"/>
            <input #codeBox4 id="codeBox4" name="otp4" [(ngModel)]="otp4" type="number" onKeyPress="if(this.value.length==1) return false;" (ngModelChange)="modelChanged('4',$event)"/>
            <input #codeBox5 id="codeBox5" name="otp5" [(ngModel)]="otp5" type="number" onKeyPress="if(this.value.length==1) return false;" (ngModelChange)="modelChanged('5',$event)"/>
            <input #codeBox6 id="codeBox6" name="otp5" [(ngModel)]="otp6" type="number" onKeyPress="if(this.value.length==1) return false;" (ngModelChange)="modelChanged('6',$event)"/>
        </form>
        </li>
        <li *ngIf="otpFlag">
          <div style="float: left;"><span style="color: red;text-align: left;font-size: 16px;top: 0px;left: 0px;background-color: unset;">Please enter valid otp.</span></div>
        </li>
          
          <li style="margin-top: 60px;">
            
            <span style="color: black;position: unset;" >Please enter the One Time Password sent to your Mobile number
            </span>
          </li>
          <li style="margin-top: 50px;">
            
            <span style="color: black;position: unset;" >{{resendTime}}
            </span>
            <span *ngIf="resendFlag" style="color: blue;position: unset;cursor: pointer;" (click)="resendOTP()" >Resend SMS
            </span>
          </li>
          <br>
          <br>
        <li>
          <input style="cursor: pointer;" type="submit" value="submit" (click)="otpSubmit()">
        </li>
      </ul>	
    </div>	
  </div>
  <div style="height: 250px;">
  </div>
</div>
</div>