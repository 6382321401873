import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as _ from "lodash";
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-fetch-otp',
  templateUrl: './fetch-otp.component.html',
  styleUrls: ['./fetch-otp.component.css']
})
export class fetchotpComponent implements OnInit {
  searchForm: FormGroup;
  otp: string;
  formattedDate: string;
  formattedTime: string;
  countryData: any[] = [];
  searchCode: string;
  countryList: any[] = [];
  filteredCountries: any[];

  constructor(public rootService: DataService, private snackBar: MatSnackBar) {
    this.searchForm = new FormGroup({
      countryCode: new FormControl(''),
      phoneNumber: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.rootService.getData(this.rootService.javaUrl + '/api/public/getAllCountries').subscribe(
      (response: any) => {
        if (response.code === 200) {
          this.countryData = response.data;
          this.filteredCountries = this.countryData;
        } else {
          console.error('Failed to fetch country data');
        }
      },
      (error) => {
        console.error('Error fetching country data:', error);
      }
    );
  }

  selectCountry(event: MatSelectChange): void {
    const countryCode = event.value;
    // Your logic here
  }

  applyFilter(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredCountries = this.countryData.filter(country =>
      country.countryCode.toLowerCase().includes(filterValue) || country.countryName.toLowerCase().includes(filterValue)
    );
  }

  fetchOtp(): void {
    const formData = this.searchForm.value;
    const countryCode = formData.countryCode;
    const phoneNumber = formData.phoneNumber;

    if (!countryCode || !phoneNumber) {
      this.rootService.showError("","The country code or phone number field is empty. ")
      console.error('Country code or phone number is empty');
      return;
    }

    this.rootService.getOtp(countryCode, phoneNumber).subscribe(
      (response: any) => {
        if (response.code === 200 && response.data && response.data.otp) {
          this.otp = response.data.otp;
          const expiryTimestamp = response.data.expiry_timestamp;
          const expiryDate = new Date(expiryTimestamp);

          this.formattedDate = expiryDate.toLocaleDateString();
          this.formattedTime = expiryDate.toLocaleTimeString();
        } else {
          console.error('OTP not found in response');
        }
      },
      (error) => {
        console.error('Error fetching OTP:', error);
        this.rootService.showError("","The country code or phone number provided is incorrect. ")
      }
    );
  }
}
