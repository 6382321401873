import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-multiselect',
  template: `
  <mat-form-field appearance="outline" fxFlex="100" class="margin-space">
  <mat-label>{{to.label}}</mat-label>
  <mat-select multiple [formControl]="formControl"  (valueChange)="to.valueChange(field,$event)" [formlyAttributes]="field">

  <mat-option  *ngFor="let item of to.options"  [value]="item.value">{{item.label}}</mat-option>

  </mat-select>
  <div class="error_msg"  *ngIf="showError"><formly-validation-message [field]="field"></formly-validation-message></div>
</mat-form-field>

  `,
})
export class FormlyFieldCustomMultiSelect extends FieldType {
  //  [fxFlex]="to.fxFlex"
  // toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
}