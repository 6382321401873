
<div [ngClass]="colorMode === 'dark' ? 'dark-theme' : 'light-theme'" style="height: 100vh !important;">
    <!-- <div class="top-header">
        <div class="container-fluid">
            <div class="row"> -->
                    <nav class="cls-toolbar navbar navbar-expand-sm navbar-dark">
 <mat-toolbar class="cls-toolbar">
  <button mat-icon-button matTooltip="Application Menu" (click)="sidenav.toggle()">
      <mat-icon>apps</mat-icon>
  </button>
  <a class="navbar-brand user">
    <img src="../../assets/images/home-logo.png" alt="" style="width: 150px;">
</a>
  <span style="flex: 1 1 auto;"></span>
  <!-- <div>
      <button mat-icon-button matTooltip="Switch Apps">
          <mat-icon>apps</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Notifications">
          <mat-icon>notifications</mat-icon>
      </button>
      <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="accountMenu">
          <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
          <button mat-menu-item routerLink="#">
              <mat-icon>person</mat-icon><span>My Account</span>
          </button>
          <button mat-menu-item>
              <mat-icon>help</mat-icon><span>Help</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item>
              <mat-icon>exit_to_app</mat-icon>Logout
          </button>
      </mat-menu>
  </div> -->
</mat-toolbar>

</nav>
<!-- </div>
</div>
</div> -->



<mat-sidenav-container style="height: calc(100% - 64px); margin: 0;">
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" style="width: 240px">
      <mat-nav-list dense class="white-text">
         
          <mat-expansion-panel [class.mat-elevation-z0]="true" dense class="white-text">
              <mat-expansion-panel-header class="white-text">
                  Products
              </mat-expansion-panel-header>
              <mat-nav-list dense class="white-text">
                  <a style="color: white;" mat-list-item routerLink="/list-classes">
                    <img style="margin-right: 10px" width="35" src="../../assets/images/classes.png"/> <span class="white-text">Classes</span></a>
                  <a style="color: white;" mat-list-item routerLink="/list-onlineclass">
                    <img style="margin-right: 10px" width="35" src="../../assets/images/classes.png"/><span class="white-text">Online Classes</span></a>
                  <a style="color: white;" mat-list-item routerLink="/list-meditations">
                    <img style="margin-right: 10px" width="35" src="../../assets/images/meditations.png"/><span class="white-text">Meditations</span></a>
                  <a style="color: white;" mat-list-item routerLink="/list-estore">
                    <img style="margin-right: 10px" width="35" src="../../assets/images/estore.png"/> <span class="white-text">E-Store</span></a>
              </mat-nav-list>
          </mat-expansion-panel>
          <mat-list-item routerLink="/list-payment"><mat-icon class="icon">attach_money</mat-icon><span class="white-text-new">Payments</span></mat-list-item>
          <mat-list-item  routerLink="/list-customers"><mat-icon class="icon">people</mat-icon><span class="white-text-new">Customers</span></mat-list-item>
          <mat-list-item  routerLink="/registration"><mat-icon class="icon">person_add</mat-icon><span class="white-text-new">Registration</span></mat-list-item>
          <mat-list-item  routerLink="/testimonials"><mat-icon class="icon">assignment</mat-icon><span class="white-text-new">Testimonials</span></mat-list-item>
          <mat-list-item  routerLink="/class-contents"><mat-icon class="icon">library_books</mat-icon><span class="white-text-new">Class Contents</span></mat-list-item>
          <mat-list-item  routerLink="/fetch-otp"><mat-icon class="icon">vpn_key</mat-icon><span class="white-text-new">OTP</span></mat-list-item>
          <mat-list-item  routerLink="/user-contents"><mat-icon class="icon">person</mat-icon><span class="white-text-new">User Details</span></mat-list-item>
          <mat-list-item style="color: white" routerLink="/login">
            <mat-icon class="icon">lock_open</mat-icon>
            <!-- <img style="margin-right: 10px" width="35" src="../../assets/images/m5.png"/> -->
            <span class="white-text-new">Logout</span></mat-list-item>
            
      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
      <router-outlet>
          <div class="container mat-body-1">
             
          </div>
      </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>