<section class="dashboard-body" [ngClass]="menuClassDeatails()">
  <div class="container-fluid">
      <div fxLayout="column" fxFlex="100%" class="">
          <div class="top-col">
              <h5 class="head-text color-green">Payments View</h5>
             
          </div>
          
    <div fxLayout="row" fxFlex="100%">
  
      <div fxFlex="30%" >
        <!-- <mat-form-field appearance="outline" class="margin-space">
          <mat-label>Search</mat-label>
          <input type="input" matInput [(ngModel)]="search" 
                 placeholder="Search">
        </mat-form-field> -->
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
      </div>
      <div fxFlex="70%" fxLayoutAlign="end">
        <div class="example-button-row" >
          <button (click)="exportTable()" mat-stroked-button>Export</button>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxFlex="100%" >
        <form fxFlex="85%" [formGroup]="form">
          <formly-form [form]="form" [options]="options" [model]="model" [fields]="formFields">
          </formly-form>
        </form>
        <div class="example-button-row" fxFlex="10%" >
            <button mat-stroked-button style="cursor: pointer;"  (click)="filterPayment()">Apply</button>
        </div>
     
      </div>
      <div fxLayout="row">
        <table  fxFlex="100%" id="paymentTable" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
        (matSortChange)="resetPaging()">
          <!-- id Column -->
          <!-- <ng-container  matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> S.No </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
       -->
          <!-- category Column -->
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
            <td mat-cell *matCellDef="let element"> {{element.category}} </td>
          </ng-container>
      
          <!-- product Column -->
          <ng-container matColumnDef="payment_info">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product </th>
            <td mat-cell *matCellDef="let element"> {{element.payment_info}} </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="cust_name_pt_txn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.cust_name_pt_txn}} </td>
          </ng-container>

           <!-- Email Column -->
           <ng-container matColumnDef="cust_email_pt_txn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
            <td mat-cell *matCellDef="let element"> {{element.cust_email_pt_txn}} </td>
          </ng-container>

            <!-- Phone Column -->
            <ng-container matColumnDef="cust_phone_pt_txn">
                <th mat-header-cell *matHeaderCellDef> Phone</th>
                <td mat-cell *matCellDef="let element"> {{element.cust_phone_pt_txn}} </td>
              </ng-container>

                <!-- Payment Ref  Column -->
           <!-- <ng-container matColumnDef="payment_ref">
            <th mat-header-cell *matHeaderCellDef> Payment Ref</th>
            <td mat-cell *matCellDef="let element"> {{element.payment_ref}} </td>
          </ng-container> -->

             <!-- Payment Ref  Column -->
             <ng-container matColumnDef="txn_status_pt_txn">
              <th mat-header-cell *matHeaderCellDef> Payment Status</th>
              <td mat-cell *matCellDef="let element"> {{element.txn_status_pt_txn}} </td>
            </ng-container>

              <!-- Payment Method  Column -->
              <ng-container matColumnDef="payment_through_pt_txn">
                <th mat-header-cell *matHeaderCellDef> Payment Method</th>
                <td mat-cell *matCellDef="let element"> {{element.payment_through_pt_txn}} </td>
              </ng-container>

           <!-- Amount Ref  Column -->
           <ng-container matColumnDef="amount_pt_txn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount</th>
            <td mat-cell *matCellDef="let element"> {{element.amount_pt_txn}} </td>
          </ng-container>

           <!-- Date Time Column -->
           <ng-container matColumnDef="txn_date_pt_txn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Time</th>
            <td mat-cell *matCellDef="let element"> {{element.txn_date_pt_txn  | date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
            
              <button mat-icon-button class="iconbutton" (click)="openMeditationDialog(element)">
                <i class="fas fa-eye"></i>
              </button>
          </td>
          </ng-container>
     
   
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
     
    </div>
      <div  fxLayout="row">
        <mat-paginator  fxFlex="100%"  (page)="getPaginatorData($event)" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]" [length]="resultsLength" [pageSize]="pageSize">
        </mat-paginator>
    </div>
     
</div>
</div>
</section>
