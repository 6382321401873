<div class="card-body">
    <div><span class="repeat-header-txt">Order History</span></div><br>

    <div fxLayoutGap="15px" fxLayout="column">
        <div fxLayout="row">
        <table fxFlex="100%" mat-table [dataSource]="dataSource" matSort matSortActive="created"
            matSortDisableClear matSortDirection="desc" (matSortChange)="resetPaging()">


         
            <ng-container matColumnDef="trackId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Track ID </th>
                <td mat-cell *matCellDef="let element"> {{element.trackId}} </td>
            </ng-container>
            <ng-container matColumnDef="paymentInfo">
                <th mat-header-cell *matHeaderCellDef> Product </th>
                <td mat-cell *matCellDef="let element"> {{element.paymentInfo}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>
            
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
            </ng-container>



            <ng-container matColumnDef="creationTimeStamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Time </th>
                <td mat-cell *matCellDef="let element"> {{element.creationTimeStamp | date:'medium' }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        </div>
    
    <div fxLayout="row"  fxLayoutGap="15px" fxLayout.xs="column" style="justify-content: flex-end;">
        <button type="button" (click)="closeDialog()" mat-stroked-button class="cls-custom-btn" color="danger">Cancel</button><div>  </div>
        <!-- <button type="submit"  mat-stroked-button class="cls-custom-btn" color="success">Apply</button> -->

    </div>
    <div fxLayout="row">
        <mat-paginator fxFlex="93%" (page)="getPaginatorData($event)" [length]="resultsLength" [pageSize]="pageSize"
            [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator>
    </div>
</div>
</div>