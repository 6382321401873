<!-- <button (click)="manageAllRows(true)">Expand All</button>
<button (click)="manageAllRows(false)">Collpase All</button> -->
<div fxLayout="column" fxFlex="100%">
  <div fxLayout="row" fxFlex="100%" fxLayoutAlign="end">
    <div fxFlex="5%">
      <i (click)="closeDialog()" class="fas fa-times-circle"style="cursor: pointer;font-size: 18px;"></i>
    </div>
  </div>
  <div fxLayout="row" fxFlex="100%">
    <form [formGroup]="form" fxFlex="30%">
      <formly-form [form]="form" [model]="model" [fields]="formFields">
      </formly-form>
    </form>
    <button mat-raised-button (click)="apply()" fxFlex="10%"
      style="    height: 50px;    width: 100px;    margin-top: 7px;    margin-right: 100px;cursor: pointer;">
      Apply
    </button>
  </div>
  <article>
    <table mat-table [dataSource]="dataSource" fxFlex="100%" matSort matSortActive="created" matSortDisableClear
      matSortDirection="desc" (matSortChange)="resetPaging()">
      <ng-container *ngFor="let col of columns" [matColumnDef]="col.value">
        <th mat-header-cell *matHeaderCellDef>
          <span>
            {{col.label}}
          </span>

        </th>
        <td mat-cell *matCellDef="let element">

          <span *ngIf="col.value !='action' && col.value !='meeting_status'">
            {{element[col.value]}}
          </span>
          <span *ngIf="col.value =='meeting_status'">
            {{element[col.value]| titlecase}}
          </span>
          <div *ngIf="col.value =='action'">
            <button mat-icon-button class="iconbutton" [cdkCopyToClipboard]="element.zoom_url">
              <input type="hidden" [(ngModel)]="element.zoom_url">
              <i class="fas fa-copy" title="Copy URL"></i>
            </button>
          </div>


        </td>


      </ng-container>








      <tr mat-header-row *matHeaderRowDef="DisplayColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: DisplayColumns;" class="example-element-row">
      </tr>

    </table>



  </article>
  <div fxLayout="row">
    <mat-paginator fxFlex="100%" #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" (page)="getPaginatorData($event)" aria-label="Select page">
    </mat-paginator>
    <!-- <mat-paginator  (page)="getPaginatorData($event)" [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="[2,5, 10, 25, 50, 100, 1000]"></mat-paginator> -->
  </div>
</div>